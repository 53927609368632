import * as React from 'react';

const NokiaLogoHeader = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={81}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55.992.547v16.906h2.684V.547h-2.684ZM27.312.26c-5.23 0-9.287 3.594-9.287 8.741 0 5.378 4.058 8.741 9.287 8.741 5.23 0 9.296-3.363 9.288-8.741-.008-4.876-4.058-8.741-9.288-8.741ZM33.92 9c0 3.81-2.958 6.335-6.608 6.335-3.649 0-6.607-2.525-6.607-6.335 0-3.74 2.958-6.335 6.607-6.335 3.65 0 6.608 2.594 6.608 6.335ZM0 0v17.453h2.74V5.919L16.977 18v-3.618L0 0Zm38.619 9 9.962 8.453h3.995L42.598 9 52.576.547H48.58L38.62 9ZM81 17.453h-2.953l-2.16-3.744h-9.78l-2.16 3.744h-2.953l3.576-6.24h9.913L69.54 2.542 70.997 0 81 17.453Z"
      fill={fill || '#fff'}
    />
  </svg>
);

export default NokiaLogoHeader;
