import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import NokiaLogoBlue from '@app/assets/nokiaLogo';
import { useAuthHomeRedirect, useKeywords } from '@app/hooks';
import { useStores } from '@stores';
import { Container, LogoWrap } from './styles';
import MarketoSignUpForm from '@components/MarketoSignUpForm';
import SignUpIntro from '@components/SignUpIntro';
import BasicAuthForm from '@components/BasicAuthForm';

export interface FormProps {
  email: string;
  firstName: string;
  lastName: string;
}

const SignUpPage = () => {
  const {
    authStore: { isAuthenticated },
    contentStore: { eventData },
  } = useStores();

  useAuthHomeRedirect(isAuthenticated);

  const keywords = useKeywords(eventData?.signUpIntro?.keywords?.data || []);

  return (
    <Container min>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <LogoWrap>
        <NokiaLogoBlue />
      </LogoWrap>
      {eventData && (
        <>
          <SignUpIntro authType={eventData?.authType} />
          {eventData?.authType === 'Basic_password' && <BasicAuthForm />}
          {eventData?.authType === 'Marketo_form' && <MarketoSignUpForm />}
        </>
      )}
    </Container>
  );
};

export default observer(SignUpPage);
