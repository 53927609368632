import { FilterItemProps } from '@app/utils/types';
import { useMemo } from 'react';
import { useKeywords } from '@app/hooks';

function useFiltersPage(eventData: any) {
  const filtersDataGroups = eventData?.homePage?.filter(
    (el: any) => el.__typename === 'ComponentFilterFiltersGroup'
  );

  const keywords = useKeywords(
    filtersDataGroups?.[0]?.filter_groups?.data?.[0]?.attributes?.keywords
      ?.data || []
  );

  const { filtersContent } = useMemo(() => {
    return {
      filtersContent:
        filtersDataGroups?.map(
          (filtersGroup: any) =>
            (filtersGroup?.filter_groups?.data?.[0]?.attributes?.filterItems ||
              []) as FilterItemProps[]
        ) || [],
    };
  }, [filtersDataGroups]);

  return { filtersContent, keywords };
}

export default useFiltersPage;
