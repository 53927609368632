import StarIcon from '@app/assets/starIcon';
import { useTheme } from '@emotion/react';
import React from 'react';
import { StarsContainer } from '@components/Interactions/styles';

type Props = {
  nOfStars: number;
  value: number;
};

const StarsAverageRating = ({ nOfStars, value }: Props) => {
  const theme = useTheme();

  return (
    <StarsContainer>
      {Array(nOfStars)
        .fill(0)
        .map((_, i) => {
          const isFilled = i + 1 < value;
          let partialFill;
          if (value > i + 1 && value < i + 2) {
            partialFill = `${((value * 10) % 10) * 10}%`;
          }
          return (
            <StarIcon
              key={i}
              width="32px"
              height="32px"
              fill={isFilled ? theme.colors.orange500 : undefined}
              stroke={isFilled ? theme.colors.orange600 : undefined}
              gradientOffset={partialFill ? partialFill : undefined}
            />
          );
        })}
    </StarsContainer>
  );
};

export default StarsAverageRating;
