import { throttle } from 'lodash-es';

const supportsDvh = CSS.supports('height: 1dvh');

export const getVhProperty = () => {
  const vh = document.documentElement.style.getPropertyValue('--vh');

  return vh;
};

export const setVhProperty = () => {
  if (supportsDvh) return;
  const vh =
    Math.round((window.innerHeight * 0.01 + Number.EPSILON) * 1000) / 1000;

  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const throttledSet = throttle(setVhProperty, 75);

if (!supportsDvh) {
  window.addEventListener('resize', setVhProperty, { passive: true });
  window.addEventListener('scroll', throttledSet, { passive: true });
}
