import { ExperienceItem } from '@app/utils/types';
import { useKeywords } from '@app/hooks';
import { useMemo } from 'react';

function useExperiences({
  eventData,
  experiencesData,
}: any): { experiencesContent: ExperienceItem[]; keywords: string } {
  let foundExperienceData: any;

  if (eventData) {
    foundExperienceData = eventData?.homePage?.find(
      (el: any) => el.__typename === 'ComponentExperienceExperienceGroup'
    );
  } else {
    foundExperienceData = experiencesData;
  }

  const keywords = useKeywords(
    foundExperienceData?.experience?.data?.attributes?.keywords?.data || []
  );

  const experiencesContent = useMemo(() => {
    const items =
      foundExperienceData?.experience?.data?.attributes?.experience_items
        .data || [];

    const itemsFiltered = items.filter(
      (item: any) => item.attributes.hidden !== true
    );

    return (itemsFiltered.map((it: any) => ({
      id: it.id,
      ...it.attributes,
    })) as ExperienceItem[]).sort(
      (a, b) =>
        a.order - b.order ||
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
    );
  }, [foundExperienceData]);

  return { experiencesContent, keywords };
}

export default useExperiences;
