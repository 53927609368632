import styled from '@emotion/styled';

export const IconWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const NumberBox = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colors.primaryBlue};
  border-radius: 8px;
  color: #fff;
  font-size: 8px;
  padding-left: 1px;
  padding-top: 1px;
`;
