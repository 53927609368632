import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

export const ExperiencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
