import axios from 'axios';
import { USER_STORAGE_KEY } from '@constants';
import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SignUpData } from '@app/utils/types';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const apolloClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});

export type User = {
  email: string;
  firstName: string;
  lastName: string;
};

const STORAGE_VERSION = '0.6';

type StorageType = {
  users: (User & { eventSlug: string; marketoInputEmail?: string })[];
  ver?: string;
};

const getStorage = () => {
  const str = localStorage.getItem(USER_STORAGE_KEY);
  const storage = str ? (JSON.parse(str) as StorageType) : undefined;

  return storage;
};

const getUserFromStorage = (eventSlug: string) => {
  const storage = getStorage();

  if (storage && storage.ver === STORAGE_VERSION) {
    return storage.users.find((u) => u.eventSlug === eventSlug);
  }

  localStorage.removeItem(USER_STORAGE_KEY);
  return undefined;
};

const setUserStorage = ({
  email,
  firstName,
  lastName,
  eventSlug,
  marketoInputEmail,
}: SignUpData) => {
  let storage = getStorage();

  if (!storage) {
    storage = {
      users: [{ email, firstName, lastName, eventSlug, marketoInputEmail }],
      ver: STORAGE_VERSION,
    };
  } else {
    storage.users = storage.users.filter(
      (u) => u.email !== email || u.eventSlug !== eventSlug
    );
    storage.users.push({
      email,
      firstName,
      lastName,
      eventSlug,
      marketoInputEmail,
    });
  }

  localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(storage));
};

const apiRoutes = {
  BASIC_AUTH: '/api/event/basicAuth',
  SIGN_IN: '/api/auth/login-dummy-user',
  RATING_VOTE: '/api/rating-answers/vote',
  POLL_VOTE: '/api/poll-answers/vote',
  POLL_RESULTS: (pollId: string) => `/api/polls/${pollId}/results`,
  RATING_RESULTS: (ratingId: string) => `/api/ratings/${ratingId}/results`,
  BOOKMARKS_EMAIL: '/api/bookmark/notify',
};

export { getUserFromStorage, setUserStorage, apiRoutes, apolloClient };
export default api;
