import routes from '@routes';
import { useStores } from '@stores';
import { matchPath, useLocation } from 'react-router-dom';
import { useEventSlug } from './useEventSlug';

function useIsEventLoading() {
  const slug = useEventSlug(true);
  const location = useLocation();
  const {
    contentStore: { eventData },
    authStore: { isUserChecked, isAuthenticated },
  } = useStores();

  if (
    !matchPath(location.pathname, {
      path: routes.EVENT.SOMETHING_WRONG(),
      exact: true,
      strict: false,
    }) &&
    ((slug && eventData === null) || (!isUserChecked && !isAuthenticated))
  )
    return true;
  return false;
}

export default useIsEventLoading;
