import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Title = styled.div`
  font-family: 'NokiaPureHeadline-Bold';
  font-size: 16px;
  color: #000;
`;

export const Link = styled.a`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
