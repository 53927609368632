import React from 'react';
import Button from '@components/Button';
import ButtonOutlined from '@components/ButtonOutlined';
import DefaultAvatarIcon from '@app/assets/defaultContactAvatarIcon';
import CloseIcon from '@app/assets/contactCloseIcon';
import { ContactProps } from '@app/utils/types';
import { useContact } from '@app/hooks';
import { useTrackMarketoLinkClick } from '@app/hooks';
import {
  Container,
  ContentBox,
  CloseBox,
  Avatar,
  DefaultAvatar,
  Name,
  JobTitle,
  Description,
  Email,
  Phone,
  BtnsBox,
  ContentWrapper,
} from './styles';

const ContactOverlay = (props: ContactProps) => {
  const { handleClose, avatar, name } = useContact(props);
  const trackLink = useTrackMarketoLinkClick();

  const { email, jobTitle, description, phoneNumber } = props;

  const clickFn = () => {
    trackLink();
    handleClose();
  };

  return (
    <Container>
      <ContentBox>
        <CloseBox onClick={clickFn}>
          <CloseIcon />
        </CloseBox>
        {avatar ? (
          <Avatar image={avatar} />
        ) : (
          <DefaultAvatar>
            <DefaultAvatarIcon />
          </DefaultAvatar>
        )}

        <ContentWrapper>
          <Name>{name}</Name>
          {jobTitle && <JobTitle>{jobTitle}</JobTitle>}
          {description && <Description>{description}</Description>}
          <Email href={`mailto:${email}`}>{email}</Email>
          {phoneNumber && <Phone>{phoneNumber}</Phone>}
        </ContentWrapper>

        <BtnsBox>
          {phoneNumber && (
            <a href={`tel:${phoneNumber}`}>
              <Button title="Add to contacts" fontSize={14} />
            </a>
          )}
          <ButtonOutlined title="Close" onClick={clickFn} />
        </BtnsBox>
      </ContentBox>
    </Container>
  );
};

export default ContactOverlay;
