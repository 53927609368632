import styled from '@emotion/styled';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;
  height: 340px;
  width: 100%;
  padding: 20px;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  justify-content: space-between;
  border-radius: 4px;
  gap: 5px;
`;

export const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blueBlack};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const DescWrap = styled.div`
  display: flex;
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey700};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
