import { gql } from '@apollo/client';

export type PollAnswerCheck = {
  pollAnswers: { data: { id: string }[] };
};

export const getPollAnswersForUser = gql`
  query GetPollAnswersForUser($email: String!, $pollId: ID!) {
    pollAnswers(
      filters: {
        user: { email: { eq: $email } }
        and: { pollOption: { poll: { id: { eq: $pollId } } } }
      }
    ) {
      data {
        id
      }
    }
  }
`;
