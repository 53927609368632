import React from 'react';
import { BookmarkLink } from '@app/utils/types';
import { Wrapper, Title, Link } from './styles';

interface BookmarkLinksProps {
  bookmarkLinks?: BookmarkLink[];
}

const BookmarkLinks = ({ bookmarkLinks }: BookmarkLinksProps) => {
  if (bookmarkLinks && bookmarkLinks.length === 0) return null;

  return (
    <Wrapper>
      <Title>Read more</Title>
      {bookmarkLinks?.map((item, index) => (
        <Link href={item.externalUrl} target="_blank" key={index}>
          {item.label}
        </Link>
      ))}
    </Wrapper>
  );
};

export default BookmarkLinks;
