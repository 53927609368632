import styled from '@emotion/styled';

interface HeaderContainerProps {
  transparent?: boolean | number;
  logoOnly?: boolean;
  isVisible?: boolean;
}

const getColor = (transparent?: boolean | number, isVisible?: boolean) => {
  if (!isVisible) {
    return 'rgba(249, 249, 249, 1)';
  }
  if (typeof transparent === 'number') {
    return `rgba(249, 249, 249, ${transparent})`;
  }

  return transparent ? 'transparent' : 'rgba(249, 249, 249, 0.96)';
};

export const Container = styled.div<HeaderContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px;
  height: 75px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${({ transparent, isVisible }) =>
    getColor(transparent, isVisible)};
  transition: background-color 0.3s ease-in-out;
`;

export const FlexWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  justify-content: ${({ justifyType }: { justifyType?: string }) =>
    justifyType || 'center'};
`;
