import CloseIcon from '@app/assets/closeIcon';
import React from 'react';
import { Card as CardComponent, CloseButtonContainer } from './styles';

type Props = {
  showPlaceholder?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  onClose?: () => void;
  border?: boolean;
  lessPadding?: boolean;
  enableMinHeight?: boolean;
  justifyValue?: string;
};

const Card = ({
  showPlaceholder,
  children,
  onClose,
  border,
  lessPadding,
  enableMinHeight,
  justifyValue,
}: Props) => {
  return (
    <CardComponent
      pulse={showPlaceholder}
      border={border}
      lessPadding={lessPadding}
      enableMinHeight={enableMinHeight}
      justifyValue={justifyValue}
    >
      {onClose && !showPlaceholder && (
        <CloseButtonContainer onClick={onClose} lessPadding={lessPadding}>
          <CloseIcon />
        </CloseButtonContainer>
      )}
      {!showPlaceholder && children}
    </CardComponent>
  );
};

export default Card;
