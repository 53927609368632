import styled from '@emotion/styled';

interface BaseContainerProps {
  bgImage?: string;
}

export const Wrapper = styled.div<BaseContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: ${({ bgImage }) =>
    bgImage ? `url('${bgImage}')` : 'unset'};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const TopRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const CloseBox = styled.div`
  display: flex;
  align-items: center;
`;
