import React from 'react';
import { Title, Message } from './styles';

const EmailSendSuccess = () => {
  return (
    <>
      <Title>E-mail sent!</Title>
      <Message>Thank you for attending our event.</Message>
    </>
  );
};

export default EmailSendSuccess;
