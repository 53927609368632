import { gql } from '@apollo/client';
import { Keyword } from '@app/utils/types';

export type PollResult = {
  id: string;
  option: string;
  votes: number;
  percentage: number;
};

export type PollOption = {
  id: string;
  attributes: {
    option: string;
  };
};

export type Poll = {
  poll: {
    data: {
      id: string;
      attributes: {
        question: string;
        title: string;
        pollOptions: {
          data: PollOption[];
        };
        keywords: {
          data: Keyword[];
        };
      };
    };
  };
};

export const getPoll = gql`
  query GetPoll($pollId: ID!) {
    poll(id: $pollId) {
      data {
        id
        attributes {
          question
          title
          pollOptions {
            data {
              id
              attributes {
                option
              }
            }
          }
          keywords {
            data {
              attributes {
                keyword
              }
            }
          }
        }
      }
    }
  }
`;
