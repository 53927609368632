import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { BaseContainer } from '../BaseContainer';

export const Container = styled(BaseContainer)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 1;
  background-color: #fff;
`;

export const ScrollableContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: calc(var(--vh) * 100);
`;

export const LogoRow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px;
  height: 75px;
  background-color: #fff;
  z-index: 1;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${({ justifyType }: { justifyType?: string }) =>
    justifyType || 'center'};
`;

const MenuItemStyle = (theme: Theme) => css`
  width: 100%;
  padding: 33px 0;
  color: ${theme.colors.grey900};
  border-bottom: 1px solid ${theme.colors.grey300};
  text-decoration: none;
  font-size: 24px;
`;

export const MenuItem = styled(Link)`
  ${({ theme }) => MenuItemStyle(theme)}
`;

export const MenuItemExternal = styled.a`
  ${({ theme }) => MenuItemStyle(theme)}
`;

export const MenuItemsWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  margin-bottom: 50px;
`;

export const SocialsWrapper = styled.div`
  display: flex;
  margin-top: auto;
  padding: 0 28px;
  gap: 30px;
`;

export const BottomBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
  margin-bottom: 50px;
`;

export const TextLink = styled.a`
  border-bottom: 2px solid black;
  font-size: 21px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.grey900};
`;

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
`;

export const CookiesLink = styled(Link)`
  display: flex;
  margin-left: 28px;
  text-decoration: none;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.grey700};
`;
