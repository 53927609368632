import React from 'react';

const ContactCloseIcon = ({
  fill,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={26}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.25 3.217 22.782.75 13 10.533 3.217.75.75 3.217 10.533 13 .75 22.782l2.467 2.468L13 15.467l9.782 9.783 2.468-2.468L15.467 13l9.783-9.783Z"
        fill={fill || '#001135'}
      />
    </svg>
  );
};

export default ContactCloseIcon;
