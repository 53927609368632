import { BaseContainer } from '@components/BaseContainer';
import styled from '@emotion/styled';

export const Container = styled(BaseContainer)`
  padding: 75px 0 22px;
  gap: 15px;
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 0 22px;
`;

export const EventTitle = styled.div`
  width: 100%;
  font-size: 28px;
  font-family: NokiaPureText-Bold;
  color: ${({ theme }) => theme.colors.blueBlack};
  padding-bottom: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
`;
