import React, { useMemo, useEffect } from 'react';
import { useStores } from '@stores';
import 'add-to-calendar-button/assets/css/atcb.css';
import { atcb_action } from 'add-to-calendar-button';
import { CalendarButton } from './styles';

interface CalendarConfigProps {
  title: string;
  eventStartDate: string;
  eventEndDate: string;
  location: string;
}

const AddToCalendarButton = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { title, eventStartDate, eventEndDate, location }: CalendarConfigProps =
    eventData || {};

  const config = useMemo(
    () => ({
      name: title,
      description: '',
      startDate: eventStartDate,
      endDate: eventEndDate,
      trigger: 'click',
      listStyle: 'overlay',
      options: [
        'Apple',
        'Google',
        'iCal',
        'Microsoft365',
        'MicrosoftTeams',
        'Outlook.com',
        'Yahoo',
      ],
      location,
    }),
    [eventData]
  );

  useEffect(() => {
    const button = document.getElementById('calendar-btn');
    // @ts-ignore
    button?.addEventListener('click', () => atcb_action(config, button));

    () => {
      // @ts-ignore
      button?.removeEventListener('click', () => atcb_action(config, button));
    };
  }, []);

  return (
    <CalendarButton id="calendar-btn">
      {eventData?.landingPageContent?.calendarBtnText || 'Add to calendar'}
    </CalendarButton>
  );
};

export default AddToCalendarButton;
