import { useMemo } from 'react';
import { useKeywords } from '@app/hooks';
import { FlatExperience } from '@app/utils/types';

function useFlatExperiencesPage(eventData: any, groupSlug?: string) {
  const flatExperienceDataGroups = eventData?.homePage?.filter(
    (el: any) => el.__typename === 'ComponentFlexpsFlExpGr'
  );

  const keywords = useKeywords(
    flatExperienceDataGroups?.[0]?.flat_experience_groups?.data?.[0]?.attributes
      ?.keywords?.data || []
  );

  const { flatExperiencesListingData } = useMemo(() => {
    const flatExperiencesListingData: Array<{
      item: { attributes: FlatExperience };
      groupId: string;
    }> = [];

    flatExperienceDataGroups.forEach((group: any) => {
      (
        group?.flat_experience_groups?.data?.[0]?.attributes?.flat_experiences
          ?.data || []
      ).forEach((item: { attributes: FlatExperience }) => {
        const groupId =
          group?.flat_experience_groups?.data?.[0]?.attributes?.slug;

        if (groupSlug && groupSlug !== groupId) return;

        flatExperiencesListingData.push({
          item,
          groupId,
        });
      });
    });

    const finalData = flatExperiencesListingData.filter(
      (currentObject, index, self) => {
        return (
          index ===
          self.findIndex(
            (obj) =>
              obj.item.attributes.slug === currentObject.item.attributes.slug
          )
        );
      }
    );
    return { flatExperiencesListingData: finalData };
  }, [flatExperienceDataGroups]);

  return {
    flatExperiencesListingData,
    keywords,
  };
}

export default useFlatExperiencesPage;
