import { MappedShortInteraction } from '@app/hooks/useUserInteraction';
import { createContext, useContext } from 'react';

type TInteractionContext = {
  currentInteraction: MappedShortInteraction | null;
  setCurrentInteraction: React.Dispatch<
    React.SetStateAction<MappedShortInteraction | null>
  >;

  isLastInteraction: boolean;
  setIsLastInteraction: React.Dispatch<React.SetStateAction<boolean>>;

  interactionState: 'open' | 'closed';
  setInteractionState: React.Dispatch<React.SetStateAction<'open' | 'closed'>>;
};

export const InteractionContext = createContext<TInteractionContext | null>(
  null
);

export const useInteractionContext = () => {
  const context = useContext(InteractionContext);

  return context;
};

export const useSafeInteractionContext = () => {
  const context = useContext(InteractionContext);

  if (!context) {
    throw new Error(
      'useSafeInteractionContext must be used within a InteractionContextProvider'
    );
  }

  return context;
};
