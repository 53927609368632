import React from 'react';
import Carousel from 'react-elastic-carousel';
import { useFlatExperiences } from '@app/hooks';
import FlatExperienceItem from '@components/FlatExperiencesCarousel/components/FlatExperience';
import { Container, Title } from './styles';

export interface FlatExperiencesCarouselProps {
  outerSpacing?: number;
  flatExperiencesData: any;
}

const breakPoints = [{ width: 1, itemsToShow: 1.5 }];

const FlatExperiencesCarousel = ({
  outerSpacing,
  flatExperiencesData,
}: FlatExperiencesCarouselProps) => {
  const {
    flatExperiencesContent,
    groupId,
    flatExperiencesCarouselTitle,
  } = useFlatExperiences({ flatExperiencesData });

  if ((flatExperiencesContent || []).length <= 0) return null;

  return (
    <Container>
      <Title>
        {flatExperiencesCarouselTitle
          ? flatExperiencesCarouselTitle
          : 'Supporting Content'}
      </Title>
      {/*
      // @ts-ignore */}
      <Carousel
        breakPoints={breakPoints}
        itemsToShow={1.5}
        pagination={false}
        showArrows={false}
        itemPadding={[0, 6, 0, 6]}
        outerSpacing={outerSpacing ?? 16}
      >
        {flatExperiencesContent?.map((flatExperience) => (
          <React.Fragment key={flatExperience.attributes.slug}>
            <FlatExperienceItem
              props={flatExperience}
              groupId={groupId}
              isCarousel
            />
          </React.Fragment>
        ))}
      </Carousel>
    </Container>
  );
};

export default FlatExperiencesCarousel;
