import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import Button from '@components/Button';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useStores } from '@stores';
import CustomInput from '@components/Input';
import { Form, ButtonContainer, Error, FieldWrapper } from './styles';
import routes from '@routes';
import { REDIRECT_URL_STORAGE_KEY } from '@constants';

const BasicAuthForm = () => {
  const history = useHistory();
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const eventSlug = useEventSlug();
  const {
    authStore: { basicSignIn },
  } = useStores();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    await basicSignIn({
      password: pwd,
      eventSlug,
      onError: () => {
        setError('Incorrect password');
      },
      onSuccess: () => {
        const redirectUrl = localStorage.getItem(REDIRECT_URL_STORAGE_KEY);
        if (redirectUrl) {
          localStorage.removeItem(REDIRECT_URL_STORAGE_KEY);
          history.push(redirectUrl);
          return;
        }

        history.push(routes.EVENT.HOME(eventSlug));
      },
    });
    setLoading(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FieldWrapper>
        <CustomInput
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          placeholder="Enter password..."
          type="password"
        />
        {error && <Error>{error}</Error>}
      </FieldWrapper>

      <ButtonContainer>
        <Button
          type="submit"
          disabled={loading || pwd?.length === 0}
          title="Submit"
        />
      </ButtonContainer>
    </Form>
  );
};

export default observer(BasicAuthForm);
