import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import NokiaLogoHeader from '@app/assets/nokiaLogoHeader';
import BurgerIcon from '@app/assets/burgerIcon';
import BurgerCloseIcon from '@app/assets/burgerCloseIcon';
import { FlexWrapper, LogoRow } from '@components/BurgerMenu/styles';
import { IconButton } from '@components/IconButton';
import { useEventSlug } from '@app/hooks/useEventSlug';
import routes from '@routes';

interface LogoRowProps {
  isOpen: boolean;
  handleMenu: () => void;
}

const LogoRowContent = ({ isOpen, handleMenu }: LogoRowProps) => {
  const theme = useTheme();
  const history = useHistory();
  const eventSlug = useEventSlug();

  const Icon = isOpen ? BurgerCloseIcon : BurgerIcon;

  const handleLogoNavigate = () => {
    handleMenu();
    history.push(routes.EVENT.HOME(eventSlug));
  };

  return (
    <LogoRow>
      <FlexWrapper justifyType="flex-start" onClick={handleLogoNavigate}>
        <NokiaLogoHeader fill={theme.colors.primaryBlue} />
      </FlexWrapper>
      <FlexWrapper justifyType="flex-end">
        <IconButton Icon={Icon} onClick={handleMenu} />
      </FlexWrapper>
    </LogoRow>
  );
};

export default LogoRowContent;
