import { Theme } from '@emotion/react';

const theme: Theme = {
  colors: {
    primaryBlue: '#005AFF',
    disabled: '#CCCCCC',
    grey900: '#333333',
    grey700: '#757575',
    grey300: '#EBEBEB',
    grey100: '#F9F9F9',
    blueBlack: '#001135',
    orange500: '#F7B737',
    orange600: '#F47F31',
  },
};

export default theme;
