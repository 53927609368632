import React from 'react';
import { observer } from 'mobx-react-lite';
import { useBookmarkMarketo } from '@app/hooks';
import { useStores } from '@stores';
import FormSkeleton from '@components/MarketoForm/MarketoFormSkeleton';
import './marketoFormStyle.css';

interface MarketoSendEmailFormProps {
  setEmailSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setSendMailOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MarketoSendEmailForm = ({
  setEmailSuccess,
  setSendMailOverlayOpen,
}: MarketoSendEmailFormProps) => {
  const {
    bookmarksStore: { sendBookmarksEmail },
  } = useStores();

  const formId = '4798';
  const munchkinId = '937-WRZ-618';
  const baseUrl = '//pages.nokia.com';

  const { formReady } = useBookmarkMarketo({
    baseUrl,
    munchkinId,
    formId,
    callback: () => {},
    setEmailSuccess,
    sendBookmarksEmail,
    setSendMailOverlayOpen,
  });

  return (
    <>
      {!formReady && <FormSkeleton />}
      <form id={`mktoForm_${formId}`} />
    </>
  );
};

export default observer(MarketoSendEmailForm);
