import React from 'react';
import { observer } from 'mobx-react-lite';
import Carousel from 'react-elastic-carousel';
import { useArticles } from '@app/hooks';
import ArticleItem from '@components/Articles/Article';
import { Container, Title } from './styles';

export interface ArticleCarouselProps {
  outerSpacing?: number;
  articleData?: any;
}

const breakPoints = [{ width: 1, itemsToShow: 1.5 }];

const ArticlesCarousel = ({ outerSpacing, articleData }: any) => {
  const { articleContent, groupId, articlesCarouselTitle } = useArticles({
    articleData,
  });

  if ((articleContent || []).length <= 0) return null;

  return (
    <Container>
      <Title>{articlesCarouselTitle ? articlesCarouselTitle : 'News'}</Title>
      {/*
      // @ts-ignore */}
      <Carousel
        breakPoints={breakPoints}
        itemsToShow={1.5}
        pagination={false}
        showArrows={false}
        itemPadding={[0, 6, 0, 6]}
        outerSpacing={outerSpacing ?? 16}
      >
        {articleContent?.map((article) => (
          <React.Fragment key={article.attributes.slug}>
            <ArticleItem props={article} groupId={groupId} isCarousel />
          </React.Fragment>
        ))}
      </Carousel>
    </Container>
  );
};

export default observer(ArticlesCarousel);
