import React, { useState, useRef } from 'react';
import { resolveStrapiImgUrl } from '@app/utils';
import DefaultExperienceBg from '@app/assets/experienceDefaultBg.png';
import ButtonOutlined from '@components/ButtonOutlined';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useWindowUnload } from '@app/hooks';
import CloseIcon from '@app/assets/closeIcon';
import BookmarkIcon from '@app/assets/bookmarkIcon';
import BookmarkFilledIcon from '@app/assets/bookmarkFilledIcon';
import ExperienceIntro from '@components/Experience/ExperienceIntro';
import { useStores } from '@stores';
import { Wrapper, TopRow, CloseBox } from './styles';

const ExperienceOverlayContent = ({
  selectedBookmark,
  setSelectedBookmark,
}: any) => {
  const [isBookmarked, setIsBookmarked] = useState(true);
  const isBookmarkedRef = useRef(true);

  const {
    bookmarksStore: { removeBookmark },
  } = useStores();

  const eventSlug = useEventSlug();

  const {
    type,
    data: {
      attributes: { slug, preExperienceBackground },
    },
  } = selectedBookmark;
  useWindowUnload(() => {
    if (!isBookmarkedRef.current) removeBookmark(slug, eventSlug, type);
  }, true);

  const handleBookmarkBtn = () => {
    const bookmarkVal = isBookmarked;
    setIsBookmarked(!bookmarkVal);
    isBookmarkedRef.current = !bookmarkVal;
  };

  const bgImage = resolveStrapiImgUrl(preExperienceBackground);

  return (
    <Wrapper
      bgImage={bgImage || DefaultExperienceBg}
      style={{ width: '100%', justifyContent: 'space-between' }}
    >
      <TopRow>
        <ButtonOutlined
          title={isBookmarked ? 'Remove' : 'Add to bookmarks'}
          onClick={handleBookmarkBtn}
          fontSize={12}
          bg="#fff"
          icon={isBookmarked ? <BookmarkFilledIcon /> : <BookmarkIcon />}
          padding="10px 16px"
        />
        <CloseBox onClick={() => setSelectedBookmark(undefined)}>
          <CloseIcon fill="#fff" />
        </CloseBox>
      </TopRow>
      <ExperienceIntro experience={selectedBookmark.data.attributes} />
    </Wrapper>
  );
};

export default ExperienceOverlayContent;
