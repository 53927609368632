import styled from '@emotion/styled';
import { BaseContainer } from '../BaseContainer';

export const Container = styled(BaseContainer)`
  align-items: center;
  padding: 75px 22px 22px;
`;

export const CarouselWrapper = styled.div`
  margin-inline: -22px;
  width: calc(100% + 44px);
`;

export const BottomSection = styled.div`
  display: flex;
  width: 100%;
  padding-top: 20px;
  margin-top: 28px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  flex-direction: column;
`;

export const BottomSectionTitle = styled.h2`
  font-family: NokiaPureText-Bold;
  font-size: 20px;
  color: #ffffff;
  margin: 0 0 20px 0;
  line-height: 20px;
`;
