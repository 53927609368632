import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useArticlesPage } from '@app/hooks';
import NewsItem from '@components/Articles/Article';
import NewsItemSecondary from '@components/Articles/ArticleSecondary';
import { Title, NewsWrapper } from './styles';
import Wrapper from '@components/Wrapper';
import theme from '@app/theme';

const ArticlesPage = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { articleContent, keywords } = useArticlesPage(eventData);

  const articlesCopy = [...articleContent];
  const secondaryArticles = articlesCopy ? articlesCopy.splice(1) : [];

  if (!articleContent) return null;

  return (
    <Wrapper padding="75px 20px 20px" bgColor={theme.colors.grey100}>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Title>Articles</Title>
      <NewsWrapper>
        <NewsItem
          props={articleContent?.[0]?.item}
          groupId={articleContent?.[0]?.groupId}
          fontSize={22}
        />
      </NewsWrapper>
      {secondaryArticles.map((article, idx: number) => (
        <NewsWrapper
          key={article.item.attributes.slug}
          isLast={secondaryArticles.length === idx + 1}
        >
          <NewsItemSecondary props={article.item} groupId={article.groupId} />
        </NewsWrapper>
      ))}
    </Wrapper>
  );
};

export default observer(ArticlesPage);
