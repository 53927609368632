import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import GET_CONTACTS from '@queries/getContacts';
import { ContactProps } from '@app/utils/types';

function useContacts() {
  const { loading, data } = useQuery(GET_CONTACTS);

  const contacts: ContactProps[] = useMemo(
    () => data?.contact?.data?.attributes?.Contacts || [],
    [data, loading]
  );

  return { loading, contacts };
}

export default useContacts;
