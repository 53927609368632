import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useHistory, useRouteMatch } from 'react-router-dom';
import NokiaLogo from '@app/assets/nokiaLogo';
import Button from '@components/Button';
import AddToCalendarButton from '@components/AddToCalendarButton';
import { useStores } from '@stores';
import { resolveStrapiImgUrl } from '@app/utils';
import defaultLpBackground from '@app/assets/landingPageDefaultBg.png';
import routes from '@routes';
import { useKeywords, useTrackMarketoLinkClick } from '@app/hooks';
import { LandingPageContentProps } from '@app/utils/types';
import {
  Container,
  Title,
  Date as DateComponent,
  ButtonsContainer,
} from './styles';
import { format } from 'date-fns';

const LandingPage = () => {
  const history = useHistory();
  const trackLink = useTrackMarketoLinkClick();
  const {
    params: { eventSlug },
  } = useRouteMatch<{ eventSlug: string }>();
  const {
    contentStore: { eventData },
  } = useStores();

  const {
    landingPageContent,
  }: { landingPageContent: LandingPageContentProps } = eventData || {};

  const keywords = useKeywords(landingPageContent?.keywords?.data || []);

  const bgImageUrl = useMemo(
    () => resolveStrapiImgUrl(landingPageContent?.backgroundImage),
    [landingPageContent?.backgroundImage]
  );

  return (
    <Container bgImage={bgImageUrl || defaultLpBackground}>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <NokiaLogo fill={'#ffffff'} />
      <Title>{eventData?.title}</Title>

      <DateComponent>
        {format(new Date(eventData?.eventStartDate), 'd MMM yyyy')}
      </DateComponent>
      <ButtonsContainer>
        <Button
          title={landingPageContent?.btnText}
          onClick={() => {
            trackLink();
            history.push(routes.EVENT.HOME(eventSlug));
          }}
        />
        {eventData && <AddToCalendarButton />}
      </ButtonsContainer>
    </Container>
  );
};

export default observer(LandingPage);
