import { createContext, useContext } from 'react';
import authStore from '@stores/authStore';
import contentStore from '@stores/contentStore';
import bookmarksStore from '@stores/bookmarksStore';

export const storesContext = createContext({
  authStore,
  contentStore,
  bookmarksStore,
});

export const useStores = () => useContext(storesContext);
