import * as React from 'react';

const BurgerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19 9H1m18-8H1h18Zm0 16H1h18Z"
      stroke="#333"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BurgerIcon;
