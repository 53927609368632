import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
  margin-bottom: 5px;
`;

export const H2Title = styled.p`
  font-size: 24px;
`;

export const CustomTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: NokiaPureHeadline-Light;

  thead {
    border: 1px solid #001135;
    background-color: #4d5766;
    color: #fff;
  }

  tbody {
    border-bottom: 1px solid #001135;
  }

  td {
    /* border: 1px solid #001135; */
    vertical-align: top;
    padding: 15px;
  }

  th {
    border: 1px solid #001135;
    padding: 15px;
    text-align: left;
  }

  tr {
    border-left: 1px solid #001135;
    border-right: 1px solid #001135;

    td:nth-of-type(2) {
      border-left: 1px solid #001135;
      border-right: 1px solid #001135;
    }
  }

  tr:nth-of-type(even) {
    background-color: #edf2f5;
  }
`;

export const ContentWrapper = styled.div`
  padding-bottom: 25px;
`;
