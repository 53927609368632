import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getUserFromStorage, setUserStorage } from '@app/api';
import { useEventSlug } from '@app/hooks/useEventSlug';
import {
  MarketoSuccessResp,
  MarketoBookmarksFormProps,
} from '@app/utils/types';
const useBookmarkMarketo = ({
  baseUrl,
  munchkinId,
  formId,
  callback,
  setEmailSuccess,
  sendBookmarksEmail,
  setSendMailOverlayOpen,
}: MarketoBookmarksFormProps) => {
  const eventSlug = useEventSlug();
  const user = getUserFromStorage(eventSlug);

  const [formReady, setFormReady] = useState(false);

  const [scriptAdded, setScriptAdded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitData, setSubmitData] = useState<MarketoSuccessResp | null>(null);
  const [emailActionCalled, setEmailActionCalled] = useState(false);

  const sendEmailToUser = async () => {
    try {
      await sendBookmarksEmail({ email: submitData?.Email, eventSlug });

      if (user && !user?.marketoInputEmail) {
        setUserStorage({ ...user, marketoInputEmail: submitData?.Email });
      }

      setEmailSuccess(true);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!', {
        toastId: 'send-bookmarks-failed',
      });
      setSendMailOverlayOpen(false);
    }
  };

  useEffect(() => {
    if (submitSuccess && submitData && !emailActionCalled) {
      setEmailActionCalled(true);
      sendEmailToUser();
    }
  }, [submitData, submitSuccess, emailActionCalled]);

  useEffect(() => {
    if (scriptAdded) {
      if (!formLoaded) {
        (window as any).MktoForms2.loadForm(
          baseUrl,
          munchkinId,
          formId,
          callback
        );
        setFormLoaded(true);
        (window as any)?.MktoForms2.whenReady(function (form: any) {
          const formEl = form.getFormElem()[0];

          const emailInput = formEl.querySelector("[id='Email']");
          if (emailInput) {
            emailInput.placeholder = 'Enter Email';
            if (user?.marketoInputEmail) {
              emailInput.value = user?.marketoInputEmail;
            }
          }

          setFormReady(true);

          form.onSuccess((data: MarketoSuccessResp, url: string) => {
            setSubmitSuccess(true);
            setSubmitData(data);
            return false;
          });
        });
      }
    } else {
      if ((window as any).MktoForms2) {
        setScriptAdded(true);
      } else {
        const script = document.createElement('script');
        script.defer = true;
        script.onload = () =>
          (window as any)?.MktoForms2 ? setScriptAdded(true) : null;
        script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
        document.head.appendChild(script);
      }
    }
  }, [scriptAdded]);

  return { formReady };
};

export default useBookmarkMarketo;
