import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ContactProps, ContactItemProps } from '@app/utils/types';
import { resolveStrapiImgUrl } from '@app/utils';
import routes from '@routes';
import { useSearchParams } from '@app/hooks';

function useContacts(contact: ContactProps | ContactItemProps) {
  const history = useHistory();
  const location = useLocation();
  const params = useSearchParams();
  const eventSlug = params.get('ev');

  const { firstName, lastName, image, urlSlug } = contact || {};

  const handleClose = useCallback(() => {
    if (location.key) {
      history.goBack();
    } else {
      history.push({
        pathname: routes.CONTACTS(''),
        search: eventSlug ? `?ev=${eventSlug}` : '',
      });
    }
  }, [history]);

  const handleClick = useCallback(() => {
    history.push({
      pathname: routes.CONTACTS(urlSlug),
      search: eventSlug ? `?ev=${eventSlug}` : '',
    });
  }, [urlSlug, history]);

  const { name, avatar } = useMemo(
    () => ({
      name: `${firstName} ${lastName}`,
      avatar: resolveStrapiImgUrl(image),
    }),
    [contact]
  );

  return { handleClick, handleClose, avatar, name };
}

export default useContacts;
