import React, { useEffect, useState } from 'react';
import { useInterval } from 'usehooks-ts';
import { useQuery } from '@apollo/client';
import API, { apiRoutes } from '@app/api';
import { getRating, Rating as TRating } from '@queries/getRating';
import Card from '@components/Interactions/Card';
import { Description, Title } from '@components/Interactions/styles';
import { randomTime } from '@app/utils';
import StarsAverageRating from './StarsAverageRating';

const randomInterval = randomTime(7000, 10000);

type Props = {
  ratingId: string;
  context?: boolean;
};

const RatingResult = ({ ratingId, context = true }: Props) => {
  const [stars, setStars] = useState(0);
  const [hidden, setHidden] = useState(false);

  const getResults = async () => {
    const results = await API.get(apiRoutes.RATING_RESULTS(ratingId));

    setStars(results.data?.average || 0);
  };

  useEffect(() => {
    getResults();
  }, []);

  useInterval(getResults, randomInterval);

  const { data: rating, loading } = useQuery<TRating>(getRating, {
    variables: { ratingId },
  });

  useEffect(() => {
    if (!rating && !loading) {
      setHidden(true);
    }
  }, [rating, loading]);

  if (hidden) return null;

  return (
    <>
      <Card
        border={context}
        lessPadding={context}
        showPlaceholder={loading || !rating}
        enableMinHeight={context}
      >
        <div>
          <Title>{rating?.rating.data.attributes.title}</Title>
          <Description>
            {rating?.rating.data.attributes.description}
          </Description>
        </div>
        <StarsAverageRating nOfStars={5} value={stars} />
      </Card>
    </>
  );
};

export default RatingResult;
