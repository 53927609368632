import * as React from 'react';

const BookmarkFilledIcon = ({
  fill,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.997 16.996 15.004 21V3.002c0-1.101-.9-2.002-2.002-2.002h-10C1.901 1 1 1.9 1 3.002L.99 21l7.007-4.004Z"
      fill={fill || '#005AFF'}
      stroke={fill || '#005AFF'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BookmarkFilledIcon;
