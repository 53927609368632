import * as React from 'react';
import TwitterImageLogo from './logo-black.png';

const TwitterIcon = ({ height, width }: any) => (
  <img
    src={TwitterImageLogo}
    style={{ height: height || '20px', width: width || '20px' }}
    alt="twittericon"
  />
);

export default TwitterIcon;
