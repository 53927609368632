import * as React from 'react';

const BurgerCloseIcon = ({
  stroke,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.001 36.67c9.206 0 16.668-7.463 16.668-16.669 0-9.205-7.462-16.668-16.668-16.668-9.205 0-16.668 7.463-16.668 16.668 0 9.206 7.463 16.668 16.668 16.668ZM14.107 25.893l11.786-11.786M14.107 14.107l11.786 11.786"
      stroke={stroke || '#333'}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BurgerCloseIcon;
