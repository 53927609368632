import * as React from 'react';

const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={8}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 0 .59 1.41 5.17 6 .59 10.59 2 12l6-6-6-6Z" fill="#49535F" />
  </svg>
);

export default ChevronRightIcon;
