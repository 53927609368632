import { useEffect, useRef } from 'react';

// https://stackoverflow.com/a/39085062
const useWindowUnloadEffect = (handler: any, callOnCleanup: boolean) => {
  const cb = useRef();

  cb.current = handler;

  useEffect(() => {
    //@ts-ignore
    const handler = () => cb.current();

    window.addEventListener('beforeunload', handler);
    window.addEventListener('pagehide', handler);

    return () => {
      if (callOnCleanup) handler();

      window.removeEventListener('beforeunload', handler);
      window.removeEventListener('pagehide', handler);
    };
  }, [callOnCleanup]);
};

export default useWindowUnloadEffect;
