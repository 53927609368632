import React from 'react';
import { HeaderRow, Title } from './styles';

type Props = {
  dayPicker?: React.ReactNode;
};

const ScheduleHeader = ({ dayPicker }: Props) => {
  return (
    <HeaderRow>
      <Title>Schedule</Title>
      {dayPicker}
    </HeaderRow>
  );
};

export default ScheduleHeader;
