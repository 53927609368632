import { FilterItemProps } from '@app/utils/types';
import { useMemo } from 'react';
import { useKeywords } from '@app/hooks';

function useFilters({ eventData, filtersData }: any) {
  let foundFiltersData: any;

  if (eventData) {
    foundFiltersData = eventData?.homePage?.find(
      (el: any) => el.__typename === 'ComponentFilterFiltersGroup'
    );
  } else {
    foundFiltersData = filtersData;
  }

  const keywords = useKeywords(
    foundFiltersData?.filter_groups?.data?.[0]?.attributes?.keywords?.data || []
  );

  const { filtersContent, filtersCarouselTitle } = useMemo(() => {
    return {
      filtersContent: (foundFiltersData?.filter_groups?.data?.[0]?.attributes
        ?.filterItems || []) as FilterItemProps[],
      filtersCarouselTitle:
        foundFiltersData?.filter_groups?.data?.[0]?.attributes
          .homeCarouselTitle,
    };
  }, [foundFiltersData]);

  return { filtersContent, keywords, filtersCarouselTitle };
}

export default useFilters;
