import styled from '@emotion/styled';
import ChevronRight from '@app/assets/chevronRightIcon';

export const Container = styled.div`
  width: 100%;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const PaginationText = styled.p`
  font-family: NokiaPureText-Regular;
  font-size: 13px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey700};
`;

export const PaginationArrow = styled(ChevronRight)<{ leftArrow?: boolean }>`
  transform: ${(props) => (props.leftArrow ? `rotate(180deg)` : '')};
`;
