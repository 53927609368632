import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useInterval } from 'usehooks-ts';
import { useQuery } from '@apollo/client';
import Button from '@components/Button';
import ButtonOutlined from '@components/ButtonOutlined';
import { ButtonsContainer, Description, Error, Title } from './styles';
import { getPoll, Poll as TPoll, PollResult } from '@queries/getPoll';
import PollOptionsRenderer from './PollOptionsRenderer';
import API, { apiRoutes } from '@app/api';
import Card from './Card';
import PollResultsRenderer from './PollResultsRenderer';
import { randomTime, REFRESH_INTERVALS } from '@app/utils';
import { useStores } from '@stores';
import { useKeywords } from '@app/hooks';

const randomInterval = randomTime(7000, 10000);

type Props = {
  pollId: string;
  isLast?: boolean;
  interactWithUser: () => void;
  onClose?: () => void;
  onNotFound?: () => void;
  context?: boolean;
};

const Poll = ({
  pollId,
  isLast,
  interactWithUser,
  onClose,
  onNotFound,
  context,
}: Props) => {
  const [choosenOption, setChoosenOption] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState<PollResult[]>([]);
  const [error, setError] = useState<string | null>(null);

  const {
    authStore: { user },
  } = useStores();

  const getResults = async () => {
    const results = await API.get<PollResult[]>(apiRoutes.POLL_RESULTS(pollId));
    setResults(results.data);
  };

  useInterval(getResults, showResults ? randomInterval : null);

  const { data: poll, loading, startPolling } = useQuery<TPoll>(getPoll, {
    variables: { pollId },
  });

  const keywords = useKeywords(
    poll?.poll?.data?.attributes?.keywords?.data || []
  );

  const handlePollSubmission = async () => {
    try {
      setIsSubmitting(true);
      setError(null);
      const { email } = user || {};
      await API.post(apiRoutes.POLL_VOTE, {
        data: { email, pollOptionId: choosenOption },
      });

      await getResults();
      setShowResults(true);
    } catch (err) {
      console.error(err);
      setError("We couldn't submit your vote. Please try again or skip.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setChoosenOption(null);
    setIsSubmitting(false);
    setError(null);
    setResults([]);
    if (context) {
      startPolling(REFRESH_INTERVALS.INTERACTIONS);
    }
  }, [pollId]);

  useEffect(() => {
    if (!poll && !loading) {
      // someone accessed this by url and there is no such a poll
      onNotFound?.();
    }
  }, [poll, loading]);

  return (
    <>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Card
        lessPadding={context}
        border={context}
        showPlaceholder={loading || !poll}
        onClose={!isLast ? onClose : undefined}
        enableMinHeight={context}
      >
        <div>
          <Title>{poll?.poll.data.attributes.title}</Title>
          <Description>{poll?.poll.data.attributes.question}</Description>
        </div>

        {showResults && <PollResultsRenderer pollResults={results} />}
        {!showResults && (
          <PollOptionsRenderer
            pollOptions={poll?.poll.data.attributes.pollOptions.data || []}
            onAnswerClick={setChoosenOption}
            activeOption={choosenOption}
          />
        )}

        <ButtonsContainer>
          {!showResults && (
            <>
              <Button
                disabled={isSubmitting || !choosenOption}
                title="Submit"
                fontSize={14}
                onClick={handlePollSubmission}
              />
              <ButtonOutlined
                title={isLast ? 'Skip and Close' : 'Skip'}
                onClick={interactWithUser}
              />
            </>
          )}

          {showResults && (
            <ButtonOutlined
              title={isLast ? 'Close' : 'Continue'}
              onClick={interactWithUser}
            />
          )}

          {error && <Error>{error}</Error>}
        </ButtonsContainer>
      </Card>
    </>
  );
};

export default observer(Poll);
