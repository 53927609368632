import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useNavigation } from '@app/hooks';
import { useSearchParams } from '@app/hooks';
import routes from '@routes';
import LogoRow from './components/LogoRow';
import SocialsRow from './components/SocialsRow';
import {
  Container,
  MenuItem,
  MenuItemsWrapper,
  ScrollableContainer,
  MenuItemExternal,
  BottomBoxWrapper,
  CookiesLink,
} from './styles';
import useBlockScroll from '@app/hooks/useBlockScroll';

interface MenuProps {
  isOpen: boolean;
  handleMenu: () => void;
}

interface NavItem {
  title: string;
  target: string;
  url: string;
}

const BurgerMenu = ({ isOpen, handleMenu }: MenuProps) => {
  const {
    contentStore: { eventData },
  } = useStores();

  const params = useSearchParams();
  const eventSlug = params.get('ev');

  const handleInternalUrl = (navItem: NavItem) => {
    if (navItem.url === '/contacts') {
      return `${navItem.url}?ev=${eventData.slug || eventSlug}`;
    } else {
      return `/${eventData?.slug || eventSlug}${navItem.url}`;
    }
  };

  const navigationData = useNavigation({ navigation: eventData?.navigation });
  useBlockScroll(isOpen);
  if (!isOpen) return null;

  return (
    <Container>
      <LogoRow handleMenu={handleMenu} isOpen={isOpen} />
      <ScrollableContainer>
        <MenuItemsWrapper>
          {((navigationData as any[]) || ([] as any[])).map(
            (navItem: NavItem) => {
              if (navItem.url.startsWith('/')) {
                return (
                  <MenuItem
                    to={() => handleInternalUrl(navItem)}
                    key={navItem.title}
                  >
                    {navItem.title}
                  </MenuItem>
                );
              }

              return (
                <MenuItemExternal
                  key={navItem.title}
                  href={navItem.url}
                  target={`_${navItem.target}`}
                >
                  {navItem.title}
                </MenuItemExternal>
              );
            }
          )}
        </MenuItemsWrapper>
        <BottomBoxWrapper>
          <SocialsRow />
          <CookiesLink to={routes.COOKIES}>Cookie Policy</CookiesLink>
        </BottomBoxWrapper>
      </ScrollableContainer>
    </Container>
  );
};

export default observer(BurgerMenu);
