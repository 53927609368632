import React from 'react';
import { ContentWrapper, H2Title, CustomList } from '../styles';

const CookiePolicy = () => {
  return (
    <ContentWrapper>
      <p>Last revised: 18 June 2021</p>
      <p>
        This Cookies & Similar Technologies Notice (<b>"CST Notice"</b>)
        describes the different types of cookies and similar technologies that
        are used on the website from which you are accessing this CST Notice (
        <b>"Site"</b>). We may change this CST Notice at any time. Please take a
        look at the “LAST REVISED” legend at the top of this page to see when
        this CST Notice was last revised. Any changes in this CST Notice will
        become effective when we make the revised CST Notice available on or
        through the Site.
      </p>
      <p>
        If you have any questions, please contact us through our{' '}
        <a
          href="https://www.nokia.com/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          contact us
        </a>{' '}
        page.
      </p>
      <div>This notice is also available in:</div>
      <CustomList>
        <li>
          <a
            href="https://www.nokia.com/fi_fi/evasteet/"
            target="_blank"
            rel="noreferrer"
          >
            Finnish
          </a>
        </li>
        <li>
          <a
            href="https://www.nokia.com/zh_int/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            Chinese
          </a>
        </li>
        <li>
          <a
            href="https://www.nokia.com/fr_int/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            French
          </a>
        </li>
        <li>
          <a
            href="https://www.nokia.com/de_int/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            German
          </a>
        </li>
        <li>
          <a
            href="https://www.nokia.com/ja_jp/%E3%82%AF%E3%83%83%E3%82%AD%E3%83%BC/"
            target="_blank"
            rel="noreferrer"
          >
            Japanese
          </a>
        </li>
        <li>
          <a
            href="https://www.nokia.com/es_int/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            Spanish
          </a>
        </li>
      </CustomList>
      <H2Title>1. WHAT ARE COOKIES AND SIMILAR TECHNOLOGIES?</H2Title>
      <p>
        Cookies are a standard feature of websites that allow us to store small
        amounts of data on your computer about your visit to the Site. Cookies
        help us learn which areas of the Site are useful and which areas need
        improvement. For this purpose, we also use technologies similar to
        cookies, such as Flash Local Shared Objects (also known as Flash
        cookies) or pixel tags.
      </p>
      <p>
        You can choose whether to accept the use of cookies and similar
        technologies in general by changing the settings on your browser, or by
        changing specific settings for the Site (as set out in Section 3 of this
        CST Notice). However, if you disable cookies and similar technologies,
        your experience on the Site may be diminished and some features may not
        work as intended.
      </p>
      <H2Title>2. WHAT COOKIES AND SIMILAR TECHNOLOGIES DO WE USE?</H2Title>
      <p>
        For specific cookie information, please review our current{' '}
        <a
          href={`${window.location.origin}/cookies-list`}
          target="_self"
          rel="noreferrer"
        >
          cookie list
        </a>
        .
      </p>
      <p>
        To the extent any personal information is collected through cookies, our{' '}
        <a
          href="https://www.nokia.com/notices/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Notice
        </a>{' '}
        applies and complements this CST Notice.
      </p>
      <p>
        <b>Essential Cookies</b>. Essential cookies enable you to navigate the
        Site and to use its services and features. Without these absolutely
        necessary cookies, the Site will not perform as smoothly for you as we
        would like it to and we may not be able to provide the Site or certain
        services or features.
      </p>
      <p>
        <b>Preference Cookies</b>. Preference cookies collect information about
        your choices and preferences, and allow us to remember language or other
        local settings and customize the Site accordingly.
      </p>
      <p>
        <b>Analytics Cookies</b>. Analytics cookies collect information about
        your use of the Site and enable us to improve the way it works. For
        example, analytics cookies show us which are the most frequently visited
        pages on the Site, help us record any difficulties you have with the
        Site, and show us whether our advertising is effective or not. This
        allows us to see the overall patterns of usage on the Site, rather than
        the usage of a single person. We use the information to analyze Site
        traffic, but we do not examine this information for individually
        identifying information.
      </p>
      <p>
        <b>Advertising Cookies</b>. Advertising cookies are set to display
        targeted promotions or advertisements based upon your interests on the
        Site or to manage our advertising. These cookies collect information
        about your activities on this Site and other sites to provide you with
        targeted advertising.
      </p>
      <p>
        <b>Google Analytics</b>. We use Google Analytics, which uses cookies and
        similar technologies to collect and analyze information about use of the
        Services and report on activities and trends. This service may also
        collect information regarding the use of other websites, apps and online
        resources. You can learn about Google’s practices by going to{' '}
        <a
          href="www.google.com/policies/privacy/partners/"
          target="_blank"
          rel="noreferrer"
        >
          www.google.com/policies/privacy/partners/
        </a>
        , and opt out of them by downloading the Google Analytics opt-out
        browser add-on, available at{' '}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        .
      </p>
      <p>
        <b>Pixel tags</b>. Pixel tags (also known as web beacons and clear GIFs)
        are used to, among other things, track the actions of users of the Site,
        measure the success of our marketing campaigns, and compile statistics
        about usage of the Site and response rates.
      </p>
      <p>
        <b>Adobe Flash</b>. We use Flash Local Shared Objects (
        <b>"Flash LSOs"</b>) and other technologies to, among other things,
        collect and store information about your use of the Site. If you do not
        want Flash LSOs stored on your computer, you can adjust the settings of
        your Flash player to block Flash LSO storage using the tools contained
        in the{' '}
        <a
          href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
          target="_blank"
          rel="noreferrer"
        >
          Website Storage Settings Panel
        </a>
        . You can also go to the{' '}
        <a
          href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
          target="_blank"
          rel="noreferrer"
        >
          Global Storage Settings Panel
        </a>{' '}
        and follow the instructions (which explain, for example, how to delete
        existing Flash LSOs (referred to as <b>"information"</b>), how to
        prevent Flash LSOs from being placed on your computer without your
        consent, and how to block Flash LSOs that are not being delivered by the
        operator of the page you are on at the time). Please note that setting
        the Flash player to restrict or limit acceptance of Flash LSOs may
        reduce or impede the functionality of some Flash applications.
      </p>
      <p>
        <b>Browser or Device Information</b>. Certain information is collected
        by most browsers or automatically through your device, such as your
        Media Access Control (MAC) address, computer type (Windows or Mac),
        screen resolution, operating system name and version, device
        manufacturer and model, language, Internet browser type and version, and
        the name and version of the Site you are using. We use this information
        to ensure that the Site functions properly.
      </p>
      <p>
        <b>Physical Location</b>. We collect the physical location of your
        device by, for example, using satellite, cell phone tower, or WiFi
        signals. We use your device’s physical location to provide you with
        personalized location-based services and content. We also share your
        device’s physical location, combined with information about what
        advertisements you viewed and other information we collect, with our
        marketing partners to enable them to provide you with more personalized
        content and to study the effectiveness of advertising campaigns. In some
        instances, you can allow or deny such uses and sharing of your device’s
        location, but if you do, we and our marketing partners may not be able
        to provide you with the applicable personalized services and content.
      </p>
      <p>
        <b>Device Fingerprinting</b>. In order to be able to offer you content
        that we believe may interest you based on your activity on the Site, and
        for fraud prevention and authentication purposes, we may also use other
        technologies that are similar to cookies. For example, we may gather
        device attributes and combine information collected by most browsers or
        automatically through your device to create a unique ID that identifies
        your browser or device to us with reasonable certainty. In this context
        we may collect, use, and combine the following information:
      </p>
      <CustomList>
        <li>Number of visits</li>
        <li>Last visit</li>
        <li>First visit</li>
        <li>External IP address</li>
        <li>Internal IP address</li>
        <li>Screen resolution</li>
        <li>Geolocation (latitude/longitude based on IP address)</li>
        <li>Device performance benchmark</li>
        <li>Device bandwidth benchmark</li>
        <li>Device user-agent (browser type, version)</li>
        <li>
          Device capabilities (Modernizer string for browser capabilities)
        </li>
        <li>Device operating system</li>
        <li>Device plugins installed</li>
        <li>Browser language</li>
        <li>Flash enabled or disabled</li>
        <li>Device time on file</li>
        <li>Keywords used if coming from search engine</li>
        <li>Pages visited</li>
        <li>Length of visit</li>
        <li>Referral page</li>
        <li>Mouse cursor position</li>
        <li>Mouse clicks</li>
        <li>Page scroll position</li>
      </CustomList>
      <H2Title>3. HOW DO I MANAGE COOKIES AND SIMILAR TECHNOLOGIES?</H2Title>
      <p>
        You can withdraw your consent at any time through our cookie tool.
        Please note that this will not fully remove the cookies that have
        already been set on your device. You can delete cookies that have
        already been placed on your device by following these instructions:
      </p>
      <div>
        <a
          href="http://support.microsoft.com/kb/260971"
          target="_blank"
          rel="noreferrer"
        >
          Deleting cookies in Internet Explorer
        </a>
      </div>
      <div>
        <a
          href="http://support.mozilla.org/en-US/kb/Cookies"
          target="_blank"
          rel="noreferrer"
        >
          Deleting cookies in Forefox
        </a>
      </div>
      <div>
        <a
          href="https://support.google.com/chrome/bin/answer.py?hl=en-GB&answer=95647&p=cpn_cookies"
          target="_blank"
          rel="noreferrer"
        >
          Deleting cookies in Chrome
        </a>
      </div>
      <div>
        <a
          href="http://www.opera.com/browser/tutorials/security/privacy/"
          target="_blank"
          rel="noreferrer"
        >
          Deleting cookies in Opera
        </a>
      </div>
      <div>
        <a
          href="https://support.apple.com/kb/index?page=search&q=manage%20cookies%20and%20website%20data&product=&doctype=&currentPage=1&includeArchived=false&locale=en_US&src=globalnav_support&type=organic"
          target="_blank"
          rel="noreferrer"
        >
          Deleting cookies in Safari
        </a>
      </div>
      <p>
        To find information relating to other browsers, visit the browser
        developer's website. Please be aware that if cookies are disabled, not
        all features of the Site may operate as intended. Please contact us
        through our{' '}
        <a
          href="https://www.nokia.com/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          contact us
        </a>{' '}
        page if you have any questions or concerns.
      </p>
      <p>
        If you want to clear all cookies left behind by the websites you have
        visited, here are links where you can download three programs that clean
        out tracking cookies:
      </p>
      <CustomList>
        <li>
          <a
            href="http://www.lavasoftusa.com/products/ad-aware_se_personal.php"
            target="_blank"
            rel="noreferrer"
          >
            http://www.lavasoftusa.com/products/ad-aware_se_personal.php
          </a>
        </li>
        <li>
          <a
            href="http://www.spybot.info/en/download/index.html"
            target="_blank"
            rel="noreferrer"
          >
            http://www.spybot.info/en/download/index.html
          </a>
        </li>
        <li>
          <a
            href="http://www.webroot.com/consumer/products/spysweeper/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.webroot.com/consumer/products/spysweeper/
          </a>
        </li>
      </CustomList>
    </ContentWrapper>
  );
};

export default CookiePolicy;
