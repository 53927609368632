import React from 'react';
import { StyledButton } from './styles';

interface ButtonProps {
  title?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  fontSize?: number;
  type?: 'button' | 'reset' | 'submit' | undefined;
  styles?: string;
}

const Button = ({ title, disabled, ...props }: ButtonProps) => {
  return (
    <StyledButton disabled={disabled} {...props}>
      {title || 'Go'}
    </StyledButton>
  );
};

export default Button;
