import React from 'react';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  max-width: 100vw;
  min-width: 0;
`;

interface TitleProps extends React.HTMLAttributes<HTMLDivElement> {
  fontSize?: number;
}

export const Title = styled.div<TitleProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '17px')};
  font-family: NokiaPureHeadline-Bold;
  color: ${({ theme }) => theme.colors.blueBlack};
  margin-top: -3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 46px;
`;

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

// should be 16:9 images
export const ImageBox = styled.div<ImageBoxProps>`
  border-radius: 4px;
  width: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /*
  // @ts-ignore */
  aspect-ratio: 16/9;
`;
