import styled from '@emotion/styled';
import { isSameHour } from 'date-fns';

export const ScrollableContainer = styled.div`
  overflow-y: auto;
  flex: 1;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
`;

export const CustomToolbar = () => {
  return null;
};

export const TimeSlotWrapper = (data: any) => {
  const isSameHourRes = isSameHour(new Date(), data.value);

  return (
    <div {...(isSameHourRes && { className: 'calendar-current-hour' })}>
      {data.children}
    </div>
  );
};

export const SkeletonWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 35px;
  animation: pulse 1.5s ease-in-out infinite;

  &:last-child {
    margin-bottom: 0;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const TimeSkeleton = styled.div`
  width: 56px;
  height: 25px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.grey300};
`;

export const LineSkeleton = styled.div`
  flex: 1;
  height: 1px;
  background: ${({ theme }) => theme.colors.grey300};
`;
