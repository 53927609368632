import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { resolveStrapiImgUrl } from '@app/utils';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';
import { FlatExperience } from '@app/utils/types';
import ButtonOutlined from '@components/ButtonOutlined';
import routes from '@routes';
import { Container, ImageBox, InfoWrapper, Title } from './styles';

interface FlatExperienceListingItemProps {
  groupId: string;
  props: FlatExperience;
}

const ListingItem = ({ groupId, props }: FlatExperienceListingItemProps) => {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();

  const { title, image, slug, buttonText } = props;

  const imgUrl = resolveStrapiImgUrl(image);

  const goToFlatExperience = () => {
    trackLink();
    history.push(routes.EVENT.FLAT_EXPERIENCE(eventSlug, slug, groupId));
  };

  return (
    <Container>
      <ImageBox image={imgUrl} />
      <InfoWrapper>
        <Title>{title}</Title>
        <div>
          <ButtonOutlined
            title={buttonText ? buttonText : 'Read Content'}
            onClick={goToFlatExperience}
            styles="width: 145px;"
          />
        </div>
      </InfoWrapper>
    </Container>
  );
};

export default observer(ListingItem);
