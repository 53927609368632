import styled from '@emotion/styled';

export const Title = styled.div`
  text-align: center;
  font-size: 24px;
  font-family: NokiaPureHeadline-Regular;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 35px;
  margin-top: 20px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Message = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-family: NokiaPureHeadline-Regular;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
