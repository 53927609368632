import styled from '@emotion/styled';

interface FlatExperienceWrapperProps
  extends React.HTMLAttributes<HTMLDivElement> {
  theme?: any;
  isLast?: boolean;
}

export const BookmarkWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: ${({ isLast }: FlatExperienceWrapperProps) =>
    isLast ? '0' : '20px'};
`;

export const NoBookmarksText = styled.div`
  font-family: NokiaPureText-Regular;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blueBlack};
  text-align: center;
  margin-top: 16px;
`;
