import { createContext, useState, useMemo, useContext } from 'react';

interface INavContext {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

const NavContext = createContext<INavContext | null>(null);

export const NavProvider = ({ children }: { children: React.ReactNode }) => {
  const [isVisible, setIsVisible] = useState(true);

  const value = useMemo(() => ({ isVisible, setIsVisible }), [isVisible]);

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export const useNavContext = () => {
  const context = useContext(NavContext);

  if (!context) {
    throw new Error('useNavContext must be used within a NavProvider');
  }

  return context;
};
