import styled from '@emotion/styled';

export const StyledInput = styled.input`
  font-family: 'NokiaPureHeadline-Regular';
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.grey300};
  color: ${({ theme }) => theme.colors.blueBlack};
  border-radius: 4px;
  border: none;
  width: 100%;
  max-width: 325px;
  outline: none;
  padding: 15px 10px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  }
`;
