import React from 'react';

const CloseIcon = ({
  fill,
  stroke,
  width,
  height,
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m19.333 2.546-1.88-1.88L10 8.12 2.547.667l-1.88 1.88L8.12 10 .667 17.453l1.88 1.88L10 11.88l7.453 7.453 1.88-1.88L11.88 10l7.453-7.454Z"
        fill={fill || '#333'}
      />
    </svg>
  );
};

export default CloseIcon;
