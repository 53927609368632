import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
`;

interface NewsWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  theme?: any;
  isLast?: boolean;
}

export const NewsWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: ${({ isLast }) => (isLast ? '0' : '20px')};
  border-bottom: ${({ theme, isLast }: NewsWrapperProps) =>
    isLast ? 'none' : `1px solid ${theme.colors.disabled}`};
`;
