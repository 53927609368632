import React from 'react';
import { StyledButton, Row } from './styles';

interface ButtonProps {
  title?: string | React.ReactNode;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  fontSize?: number;
  customColor?: string;
  padding?: string;
  bg?: string;
  icon?: any;
  styles?: string;
}

const ButtonOutlined = ({
  title,
  disabled,
  customColor,
  icon,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton disabled={disabled} customColor={customColor} {...props}>
      {icon ? (
        <Row>
          {icon}
          <span>{title || 'Go'}</span>
        </Row>
      ) : (
        <>{title || 'Go'}</>
      )}
    </StyledButton>
  );
};

export default ButtonOutlined;
