import React, { useEffect, useState, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import NokiaLogoHeader from '@app/assets/nokiaLogoHeader';
import BurgerIcon from '@app/assets/burgerIcon';
import routes from '@routes';
import { useStores } from '@stores';
import { useHeader } from '@app/hooks';
import BurgerMenu from '@components/BurgerMenu';
import { CustomHeaderConfig } from '@app/utils/types';
import { useSearchParams } from '@app/hooks';
import { Container, FlexWrapper } from './styles';
import { observer } from 'mobx-react-lite';
import { useNavContext } from '@app/contexts/NavContext';
import { merge } from 'lodash-es';
import { IconButton } from '@components/IconButton';
import CloseIcon from '@app/assets/burgerCloseIcon';
import HeaderBookmarks from '@components/HeaderBookmarks';

const Header = ({ customCfg }: { customCfg?: CustomHeaderConfig }) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const params = useSearchParams();
  const eventSlug = params.get('ev');
  const { isVisible } = useNavContext();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const {
    contentStore: { eventData, isEventPage },
  } = useStores();

  const headerConfig = useHeader(isEventPage);

  // @ts-ignore
  const { logoColor, hideBurger, visible, transparent, logoOnly } = merge(
    headerConfig,
    customCfg
  );

  const eventSlugResolved = eventData?.slug || eventSlug;

  useEffect(() => {
    setMenuOpen(false);
  }, [location?.pathname]);

  const handleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const isPollOrRatingPage = useMemo(
    () =>
      location.pathname.includes('/poll/') ||
      location.pathname.includes('/rating/'),
    [location.pathname]
  );

  const logoColorFinal = useMemo(() => {
    const usablePaths = [
      '/article/',
      '/supporting-content/',
      '/poll/',
      '/rating/',
    ];
    const isUsablePath = usablePaths.some(function (v) {
      return location.pathname.indexOf(v) >= 0;
    });

    if (!isUsablePath) return logoColor;

    return isVisible === false ? theme.colors.primaryBlue : logoColor;
  }, [isVisible, location.pathname, logoColor]);

  if (!visible) return null;

  return (
    <>
      <Container
        transparent={transparent && !menuOpen ? transparent : false}
        logoOnly={logoOnly}
        isVisible={isVisible}
      >
        <FlexWrapper justifyType="flex-start">
          {eventSlugResolved ? (
            <Link
              to={routes.EVENT.HOME(eventSlugResolved)}
              style={{ display: 'flex' }}
            >
              <NokiaLogoHeader fill={logoColorFinal} />
            </Link>
          ) : (
            <NokiaLogoHeader fill={logoColorFinal} />
          )}
        </FlexWrapper>
        {!logoOnly && !hideBurger && (
          <FlexWrapper justifyType="flex-end">
            <Link to={routes.EVENT.BOOKMARKS(eventSlugResolved)}>
              <IconButton component={<HeaderBookmarks />} />
            </Link>
            <IconButton Icon={BurgerIcon} onClick={handleMenu} />
          </FlexWrapper>
        )}
        {isPollOrRatingPage && (
          <FlexWrapper
            justifyType="flex-end"
            onClick={() =>
              history.replace(routes.EVENT.HOME(eventSlugResolved))
            }
          >
            <CloseIcon stroke={isVisible ? '#fff' : '#000'} />
          </FlexWrapper>
        )}
        <BurgerMenu isOpen={menuOpen} handleMenu={handleMenu} />
      </Container>
    </>
  );
};

export default observer(Header);
