import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '@routes';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';
import { getMarketoUserIdFromCookie } from '@app/utils';
import { MarketoSuccessResp, MarketoSignUpFormProps } from '@app/utils/types';
const useBookmarkMarketo = ({
  baseUrl,
  munchkinId,
  formId,
  callback,
  signUp,
}: MarketoSignUpFormProps) => {
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();
  const history = useHistory();

  const [formReady, setFormReady] = useState(false);

  const [scriptAdded, setScriptAdded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitData, setSubmitData] = useState<MarketoSuccessResp | null>(null);
  const [signUpActionCalled, setSignUpActionCalled] = useState(false);

  const handleSuccess = () => {
    trackLink();

    history.push(routes.EVENT.HOME(eventSlug));
  };

  const strapiSignUp = async () => {
    try {
      await signUp(
        {
          email: `${getMarketoUserIdFromCookie()}@anonymous.com` || '',
          firstName: '',
          lastName: '',
          eventSlug,
          marketoInputEmail: submitData?.Email,
        },
        handleSuccess
      );
    } catch (error) {
      console.log(error);
      history.push(routes.EVENT.SOMETHING_WRONG(eventSlug));
    }
  };

  useEffect(() => {
    if (submitSuccess && submitData && !signUpActionCalled) {
      setSignUpActionCalled(true);
      strapiSignUp();
    }
  }, [submitData, submitSuccess, signUpActionCalled]);

  useEffect(() => {
    if (!baseUrl || !munchkinId || !formId) {
      return;
    }
    if (scriptAdded) {
      if (!formLoaded) {
        (window as any).MktoForms2.loadForm(
          baseUrl,
          munchkinId,
          formId,
          callback
        );
        setFormLoaded(true);
        (window as any)?.MktoForms2.whenReady(function (form: any) {
          const formEl = form.getFormElem()[0];

          const emailInput = formEl.querySelector("[id='Email']");
          if (emailInput) {
            emailInput.placeholder = 'Enter Email';
          }

          setFormReady(true);

          form.onSuccess((data: MarketoSuccessResp, url: string) => {
            setSubmitSuccess(true);
            setSubmitData(data);
            return false;
          });
        });
      }
    } else {
      if ((window as any).MktoForms2) {
        setScriptAdded(true);
      } else {
        const script = document.createElement('script');
        script.defer = true;
        script.onload = () =>
          (window as any)?.MktoForms2 ? setScriptAdded(true) : null;
        script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
        document.head.appendChild(script);
      }
    }
  }, [scriptAdded]);

  return { formReady };
};

export default useBookmarkMarketo;
