import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 20px 10px;
  flex-wrap: wrap;
`;

export const FilterItemWrapper = styled.div`
  flex-basis: calc(50% - 5px);
`;
