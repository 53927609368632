import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
`;

export const Description = styled.div`
  overflow-wrap: break-word;
  flex: 1;
  color: #757575;
  font-size: 14px;
  font-family: NokiaPureText-Regular;
  text-align: center;
`;

export const IconBox = styled.div`
  position: absolute;
  top: 15px;
  right: 13px;
  display: flex;
  cursor: pointer;
`;

export const Title = styled.div`
  color: #001135;
  font-size: 20px;
  font-family: NokiaPureText-Regular;
  text-align: center;
`;

export const BtnWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
