import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

type Variants = 'nav' | 'outlined';

const navCss = css`
  background: rgba(249, 249, 249, 0.96);
  border: none;
`;

const outlinedCss = (theme: Theme, bg?: string) => css`
  border: 1px solid ${theme.colors.primaryBlue};
  color: ${theme.colors.primaryBlue};
  background-color: ${bg || 'transparent'};
`;

const IconWrapper = styled.div<{ variant: Variants; bg?: string }>`
  height: 43px;
  width: 43px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;

  ${({ variant, theme, bg }) =>
    variant === 'nav' ? navCss : outlinedCss(theme, bg)}
`;

type IconButtonProps = {
  Icon?: React.FunctionComponent;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  variant?: Variants;
  bg?: string;
  component?: any;
};

export const IconButton = ({
  Icon,
  component,
  onClick,
  variant = 'nav',
  bg,
}: IconButtonProps) => (
  <IconWrapper onClick={onClick} variant={variant} bg={bg}>
    {Icon && <Icon />}
    {component && component}
  </IconWrapper>
);
