import { gql } from '@apollo/client';

export type RatingAnswerCheck = {
  ratingAnswers: { data: { id: string }[] };
};

export const getRatesForUser = gql`
  query GetRatesForUser($email: String!, $ratingId: ID!) {
    ratingAnswers(
      filters: {
        user: { email: { eq: $email } }
        and: [{ rating: { id: { eq: $ratingId } } }]
      }
    ) {
      data {
        id
      }
    }
  }
`;
