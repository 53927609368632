import { MappedShortInteraction } from '@app/hooks/useUserInteraction';
import { Interactions } from '@app/utils/types';
import { useStores } from '@stores';
import React, { useEffect, useState } from 'react';
import { InteractionContext } from './InteractionContext';
import UserInteractionSection from './UserInteractionSection';

export type InitialState = {
  interaction: MappedShortInteraction | null;
  isLast: boolean;
};

type Props = {
  interactions: Interactions | null;
  initialInteraction: InitialState;
  groupId: string;
};

const UserInteractionsGroup = ({
  interactions,
  initialInteraction,
  groupId,
}: Props) => {
  const { contentStore } = useStores();
  const [currentInteraction, setCurrentInteraction] =
    useState<MappedShortInteraction | null>(initialInteraction.interaction);
  const [interactionState, setInteractionState] = useState<'closed' | 'open'>(
    'open'
  );
  const [isLastInteraction, setIsLastInteraction] = useState(
    initialInteraction.isLast
  );

  useEffect(() => {
    if (!interactions) return;
    const resolved = contentStore.resolveInitialInteractionForGroup(groupId);
    setCurrentInteraction(resolved.interaction);
    setIsLastInteraction(resolved.isLast);
    if (resolved.interaction) setInteractionState('open');
  }, [interactions]);

  if (!interactions || interactionState === 'closed') return null;

  return (
    <InteractionContext.Provider
      value={{
        setCurrentInteraction,
        setIsLastInteraction,
        interactionState,
        setInteractionState,
        currentInteraction,
        isLastInteraction,
      }}
    >
      <UserInteractionSection interactions={interactions} />
    </InteractionContext.Provider>
  );
};

export default UserInteractionsGroup;
