import React from 'react';
import UserInteractionLayout from '@components/UserInteractionLayout';
import { useHistory, useParams } from 'react-router-dom';
import RatingComponent from '@components/Interactions/Rating';
import { useStores } from '@stores';
import { useExperience, useSearchParams } from '@app/hooks';
import {
  MappedShortInteraction,
  Mode,
  useUserInteraction,
} from '@app/hooks/useUserInteraction';
import routes from '@routes';
import { observer } from 'mobx-react-lite';
import { EventSlugParams } from '@app/hooks/useEventSlug';
import { useHidableNavOffset } from '@app/hooks/useHidableNav';

const RatingPage = () => {
  const { ratingId, eventSlug } = useParams<
    {
      ratingId: string;
    } & EventSlugParams
  >();
  const params = useSearchParams();
  const history = useHistory();

  const {
    contentStore: { eventData },
  } = useStores();

  const { experienceData: experience } = useExperience(eventData);

  const mode: Mode = {
    mode: 'routing',
    experienceSlug: experience.slug,
    routingType: 'replace',
  };

  const currentInteraction: MappedShortInteraction = {
    __typename: 'ComponentIntersRatingInteraction',
    id: ratingId,
  };

  useHidableNavOffset(35);

  const { interactWithUser } = useUserInteraction();

  return (
    <UserInteractionLayout>
      <RatingComponent
        ratingId={ratingId}
        isLast={params.has('lastInteraction')}
        interactWithUser={() =>
          interactWithUser(mode, experience.interactions, currentInteraction, {
            noInteractionFallback: () =>
              history.push(routes.EVENT.HOME(eventSlug)),
          })
        }
        onNotFound={() => history.replace(routes.EVENT.HOME(eventSlug))}
      />
    </UserInteractionLayout>
  );
};

export default observer(RatingPage);
