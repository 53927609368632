import React from 'react';
import styled from '@emotion/styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fontSize?: number;
  styles?: string;
}

export const StyledButton = styled.button<ButtonProps>`
  font-family: 'NokiaPureHeadline-Regular';
  border-radius: 4px;
  border: none;
  padding: 10px 30px;
  background-color: ${({
    disabled,
    theme,
  }: {
    disabled?: boolean;
    theme: any;
  }) => (disabled ? theme.colors.disabled : theme.colors.primaryBlue)};
  color: #fff;
  outline: none;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '20px')};
  ${({ styles }) => styles};

  &:hover {
    cursor: pointer;
  }
`;
