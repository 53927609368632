import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  gap: 11px;
`;

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const ImageBox = styled.div<ImageBoxProps>`
  flex-basis: 35%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  border-radius: 4px;
`;

export const InfoWrapper = styled.div`
  flex-basis: 65%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  font-size: 17px;
  font-family: NokiaPureHeadline-Bold;
  color: ${({ theme }) => theme.colors.blueBlack};
  display: -webkit-box;
  margin-top: -3px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const BtnsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
