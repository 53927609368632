import React from 'react';
import { H2Title, CustomTable, ContentWrapper } from '../styles';

const CookieList = () => {
  return (
    <ContentWrapper>
      <p>
        <b>
          Nokia will periodically update this cookie list to reflect changes in
          cookie usage.
        </b>
      </p>
      <H2Title>
        <b>Essential</b>
      </H2Title>
      <CustomTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Purpose</th>
            <th>Lifespan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>_ga</td>
            <td>
              Used to distinguish unique users by assigning a randomly generated
              number as a client identifier. It is included in each page request
              in a site and used to calculate visitor, session and campaign data
              for the sites analytics reports.
            </td>
            <td>2 years</td>
          </tr>
          <tr>
            <td>_gat_UA-*</td>
            <td>Google Analytics cookie to throttle the request rate.</td>
            <td>1 minute</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>Used to distinguish users, 24 hour expiration time.</td>
            <td>1 day</td>
          </tr>
          <tr>
            <td>gravitoData</td>
            <td>
              Used by consent management tool store consent settings and
              metadata.
            </td>
            <td>1 year</td>
          </tr>
          <tr>
            <td>NCString</td>
            <td>Used by consent management tool to store consent settings.</td>
            <td>1 year</td>
          </tr>
        </tbody>
      </CustomTable>

      <H2Title>
        <b>Analytics</b>
      </H2Title>
      <CustomTable>
        <thead>
          <tr>
            <th>Name</th>
            <th>Purpose</th>
            <th>Lifespan</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>_mkto_trk</td>
            <td>
              This cookie may contain information (such as a unique user ID)
              that is used to track your usage of our site. The cookies placed
              by Marketo are readable only by Marketo.
            </td>
            <td>3 years</td>
          </tr>
        </tbody>
      </CustomTable>
    </ContentWrapper>
  );
};

export default CookieList;
