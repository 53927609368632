import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import FbIcon from '@app/assets/fbIcon';
import IgIcon from '@app/assets/igIcon';
import TwitterIcon from '@app/assets/twitterIcon';
import {
  SocialsWrapper,
  TextLink,
  StyledLink,
} from '@components/BurgerMenu/styles';

const renderSocial = (Icon: any, url: string) => {
  if (!url) return null;

  return (
    <StyledLink href={url} target="_blank">
      <Icon width={20} height={20} />
    </StyledLink>
  );
};

const SocialsRow = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { facebookUrl, instagramUrl, twitterUrl, nokiaUrl } = useMemo(
    () => eventData?.socials || {},
    [eventData?.socials]
  );

  if (!facebookUrl && !instagramUrl && !twitterUrl && !nokiaUrl) return null;

  return (
    <SocialsWrapper>
      {renderSocial(FbIcon, facebookUrl)}
      {renderSocial(IgIcon, instagramUrl)}
      {renderSocial(TwitterIcon, twitterUrl)}
      {nokiaUrl && (
        <TextLink href={nokiaUrl} target="_blank">
          Nokia.com
        </TextLink>
      )}
    </SocialsWrapper>
  );
};

export default observer(SocialsRow);
