import { gql } from '@apollo/client';

export default gql`
  query GetContacts {
    contact {
      data {
        attributes {
          Contacts {
            firstName
            lastName
            description
            phoneNumber
            jobTitle
            email
            urlSlug
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
