import { useLocation } from 'react-router-dom';

const useTrackMarketoLinkClick = () => {
  const location = useLocation();

  if (process.env.NODE_ENV === 'development') return () => {};

  return () => {
    (window as any)?.Munchkin?.munchkinFunction(
      (window as any)?.Munchkin?.CLICK_LINK,
      { href: location.pathname }
    );
  };
};

export default useTrackMarketoLinkClick;
