import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.div`
  font-size: 20px;
  font-family: NokiaPureText-Bold;
  color: ${({ theme }) => theme.colors.blueBlack};
  margin-bottom: 16px;
  padding-left: 22px;
`;
