import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Iframe, IframeContainer } from '@components/IframeComponents/styles';
import { useDetectIOSVersion } from '@app/hooks';
import { useEventSlug } from '@app/hooks/useEventSlug';
import useFilter from '@app/hooks/useFilter';
import routes from '@routes';
import { useStores } from '@stores';

const FilterPage = () => {
  const history = useHistory();
  const eventSlug = useEventSlug();

  const {
    contentStore: { eventData },
  } = useStores();

  const version = useDetectIOSVersion();
  const isUsableVersion = !version || version >= 15;

  const { filter, keywords } = useFilter(eventData);

  useEffect(() => {
    let mounted = true;
    if (!isUsableVersion) {
      filter && window.open(filter.iframeLink, '_blank');
      setTimeout(() => {
        mounted && history.push(routes.EVENT.FILTERS(eventSlug));
      }, 2000);
    }

    return () => {
      mounted = false;
    };
  }, [isUsableVersion]);

  if (!filter) return null;

  const { iframeLink, title } = filter;

  return (
    <>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <IframeContainer>
        {isUsableVersion && (
          <Iframe
            title={title}
            allow="accelerometer;ambient-light-sensor;autoplay;battery;camera;display-capture;document-domain;encrypted-media;fullscreen;geolocation;gyroscope;layout-animations;legacy-image-formats;magnetometer;microphone;midi;navigation-override;oversized-images;payment;picture-in-picture;publickey-credentials-get;sync-script;sync-xhr;usb;screen-wake-lock;web-share;unoptimized-images;unsized-media;xr-spatial-tracking;"
            src={iframeLink}
          />
        )}
      </IframeContainer>
    </>
  );
};

export default observer(FilterPage);
