import { InitialState } from '@components/UserInteractionsGroup';
import {
  getInteractionsWithStatus,
  mapInteractions,
  MappedShortInteraction,
  MappedShortInteractionExtended,
} from '@app/hooks/useUserInteraction';
import { makeAutoObservable, runInAction } from 'mobx';
import authStore from './authStore';

type InteractionGroup = {
  groupId: string;
  interactions: MappedShortInteractionExtended[];
};

export class ContentStore {
  eventData: any | null = null;
  contentAlreadyFetched: boolean = false;
  isLoading: boolean = false;
  errorMsg: any = null;
  interactionGroups: InteractionGroup[] = [];
  isEventPage: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  resolveInitialInteractions = async (data: any) => {
    const intersGroup =
      data?.homePage?.filter(
        (hp: any) => hp.__typename === 'ComponentIntersIntersGrpHome'
      ) || [];

    const mapped = await Promise.all<(null | InteractionGroup[])[]>(
      intersGroup.map(async (inte: any) => {
        const isArr = Array.isArray(
          inte?.user_interactions?.data?.[0]?.attributes?.interactions
        );

        if (!isArr) return null;

        const mappedInteractions = mapInteractions(inte);
        const interactions = await getInteractionsWithStatus(
          mappedInteractions
        );

        return { groupId: inte.id, interactions };
      })
    );

    runInAction(() => {
      this.interactionGroups = mapped.filter(
        Boolean
      ) as unknown as InteractionGroup[];
    });
  };

  markInteractionAsAnswered = (interaction: MappedShortInteraction | null) => {
    if (!interaction) return;

    this.interactionGroups.forEach((group) => {
      group.interactions.forEach((inte) => {
        if (
          inte.id === interaction.id &&
          inte.__typename === interaction.__typename
        ) {
          inte.isAnswered = true;
        }
      });
    });
  };

  resolveInitialInteractionForGroup = (groupId: string): InitialState => {
    const group = this.interactionGroups.find((g) => g.groupId === groupId);

    if (!group)
      return {
        isLast: false,
        interaction: null,
      };

    const interactionIdx = group.interactions.findIndex(
      (inte) => inte.isAnswered === false
    );

    if (interactionIdx === -1)
      return {
        isLast: false,
        interaction: null,
      };

    const isLast = group.interactions
      .slice(interactionIdx + 1)
      .reduce((acc, curr) => acc && !!curr.isAnswered, true);

    return {
      isLast,
      interaction: group.interactions[interactionIdx],
    };
  };

  setEventData = async (data: any) => {
    const { email } = authStore.user || {};

    if (email) {
      await this.resolveInitialInteractions(data);
    }

    runInAction(() => {
      this.eventData = data;

      if (!this.contentAlreadyFetched) {
        this.contentAlreadyFetched = true;
      }
    });
  };

  resolveInteractionsAfterLogin = async () => {
    await this.resolveInitialInteractions(this.eventData);
  };

  setIsEventPage = (val: boolean) => {
    this.isEventPage = val;
  };

  clearStore = () => {
    this.eventData = null;
    this.contentAlreadyFetched = false;
    this.isLoading = false;
    this.errorMsg = null;
    this.interactionGroups = [];
    this.isEventPage = false;
  };
}

export default new ContentStore();
