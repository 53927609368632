import React from 'react';
import styled from '@emotion/styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  fontSize?: number;
  customColor?: string;
  bg?: string;
  padding?: string;
  styles?: string;
}

export const StyledButton = styled.button<ButtonProps>`
  font-family: 'NokiaPureHeadline-Regular';
  border-radius: 4px;
  border: 1px solid
    ${({ customColor, theme }) => customColor || theme.colors.primaryBlue};
  padding: ${({ padding }) => (padding ? padding : '10px 30px')};
  background-color: ${({ disabled, theme, bg }) =>
    disabled ? theme.colors.disabled : bg || 'transparent'};
  color: ${({ customColor, theme }) => customColor || theme.colors.primaryBlue};
  outline: none;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  ${({ styles }) => styles};

  &:hover {
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
