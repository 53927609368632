import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { usePollResults } from '@app/hooks';
import PollResultItem from './components/Poll';
import RatingResultItem from './components/Rating';
import { Container, Title } from './styles';

const PollResultsListing = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { polls, ratings } = usePollResults(eventData);

  if (polls.length === 0 && ratings.length === 0) return null;

  return (
    <>
      {polls.length > 0 && (
        <>
          <Title>Polls</Title>
          <Container>
            {polls.map((item) => (
              <React.Fragment key={item}>
                <PollResultItem pollId={item} />
              </React.Fragment>
            ))}
          </Container>
        </>
      )}
      {ratings.length > 0 && (
        <>
          <Title>Ratings</Title>
          <Container>
            {ratings.map((item) => (
              <React.Fragment key={item}>
                <RatingResultItem ratingId={item} />
              </React.Fragment>
            ))}
          </Container>
        </>
      )}
    </>
  );
};

export default observer(PollResultsListing);
