import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { BookmarkDataProps } from '@app/utils/types';
import ButtonOutlined from '@components/ButtonOutlined';
import { resolveStrapiImgUrl } from '@app/utils';
import { useEventSlug } from '@app/hooks/useEventSlug';
import BookmarkFilledIcon from '@app/assets/bookmarkFilledIcon';
import { Container, ImageBox, InfoWrapper, Title, BtnsWrapper } from './styles';

interface BookmarkListingItemProps {
  item: BookmarkDataProps;
  setSelectedBookmark: React.Dispatch<React.SetStateAction<any>>;
}

const BookmarkListingItem = ({
  item,
  setSelectedBookmark,
}: BookmarkListingItemProps) => {
  const {
    bookmarksStore: { removeBookmark },
  } = useStores();

  const eventSlug = useEventSlug();

  const {
    groupId,
    type,
    data: {
      // @ts-ignore
      attributes: {
        title,
        // @ts-ignore
        image,
        slug,
        // @ts-ignore
        buttonText,
        // @ts-ignore
        carouselBtnText,
        // @ts-ignore
        preExperienceBackground,
      },
    },
  } = item;

  const imgUrl = resolveStrapiImgUrl(image || preExperienceBackground);

  const handleRead = () => {
    setSelectedBookmark(item);
  };

  const handleRemoveBookmark = () => {
    removeBookmark(slug, eventSlug, type, groupId!);
  };

  const handleBtnTitle = () => {
    if (type === 'experience') {
      return carouselBtnText || 'View';
    } else {
      return buttonText || 'Read Article';
    }
  };

  const btnTitle = handleBtnTitle();

  return (
    <Container>
      <ImageBox image={imgUrl} />
      <InfoWrapper>
        <Title>{title}</Title>
        <BtnsWrapper>
          <ButtonOutlined
            title={btnTitle}
            onClick={handleRead}
            fontSize={12}
            padding="10px 16px"
            styles="display: flex; width: 100%; justify-content: center; align-items: center;"
          />
          <ButtonOutlined
            title="Remove"
            onClick={handleRemoveBookmark}
            fontSize={12}
            icon={<BookmarkFilledIcon />}
            padding="10px 16px"
            styles="display: flex; width: 100%; justify-content: center; align-items: center;"
          />
        </BtnsWrapper>
      </InfoWrapper>
    </Container>
  );
};

export default observer(BookmarkListingItem);
