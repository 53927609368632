const EVENT_NAMESPACE = '/:eventSlug';

const routes = {
  EVENT: {
    HOME: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}` : EVENT_NAMESPACE,
    SIGN_UP: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/sign-up` : EVENT_NAMESPACE + '/sign-up',
    LANDING_PAGE: (eventSlug: string | null = null) =>
      eventSlug
        ? `/${eventSlug}/landing-page`
        : EVENT_NAMESPACE + '/landing-page',
    EXPERIENCE: (
      experienceId: string | null = null,
      eventSlug: string | null = null
    ) =>
      experienceId && eventSlug
        ? `/${eventSlug}/experience/${experienceId}`
        : EVENT_NAMESPACE + '/experience/:id',
    EXPERIENCE_VISIT: (
      experienceId: string | null = null,
      eventSlug: string | null = null
    ) =>
      experienceId && eventSlug
        ? `/${eventSlug}/experience/${experienceId}/visit`
        : EVENT_NAMESPACE + '/experience/:id/visit',
    EXPERIENCES: (eventSlug: string | null = null) =>
      eventSlug
        ? `/${eventSlug}/experiences`
        : EVENT_NAMESPACE + '/experiences',
    ARTICLE: (
      groupId: string | null = null,
      slug: string | null = null,
      eventSlug: string | null = null
    ) =>
      groupId && eventSlug
        ? `/${eventSlug}/article/${groupId}/${slug}`
        : EVENT_NAMESPACE + '/article/:groupId/:slug',
    ARTICLES: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/articles` : EVENT_NAMESPACE + '/articles',
    FLAT_EXPERIENCES: (eventSlug: string | null = null) =>
      eventSlug
        ? `/${eventSlug}/supporting-content`
        : EVENT_NAMESPACE + '/supporting-content',
    FLAT_EXPERIENCE: (
      eventSlug: string | null = null,
      slug: string | null = null,
      groupId: string | null = null
    ) =>
      eventSlug && slug && groupId
        ? `/${eventSlug}/supporting-content/${groupId}/${slug}`
        : EVENT_NAMESPACE + '/supporting-content/:groupId/:slug',
    FLAT_EXPERIENCE_GROUP: (
      eventSlug: string | null = null,
      groupId: string | null = null
    ) =>
      eventSlug && groupId
        ? `/${eventSlug}/supporting-content/${groupId}`
        : EVENT_NAMESPACE + '/supporting-content/:groupId',
    BOOKMARKS: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/bookmarks` : EVENT_NAMESPACE + '/bookmarks',
    RATING: (
      eventSlug: string | null = null,
      experienceId: string | null = null,
      ratingId: string | null = null,
      lastInteraction?: boolean
    ) =>
      eventSlug && experienceId && ratingId
        ? `/${eventSlug}/experience/${experienceId}/rating/${ratingId}${
            lastInteraction ? '?lastInteraction=1' : ''
          }`
        : EVENT_NAMESPACE + '/experience/:id/rating/:ratingId',
    POLL: (
      eventSlug: string | null = null,
      experienceId: string | null = null,
      pollId: string | null = null,
      lastInteraction?: boolean
    ) =>
      eventSlug && experienceId && pollId
        ? `/${eventSlug}/experience/${experienceId}/poll/${pollId}${
            lastInteraction ? '?lastInteraction=1' : ''
          }`
        : EVENT_NAMESPACE + '/experience/:id/poll/:pollId',
    FILTERS: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/filters` : EVENT_NAMESPACE + '/filters',
    FILTER: (eventSlug: string | null = null, filterId: string | null = null) =>
      eventSlug && filterId
        ? `/${eventSlug}/filter/${filterId}`
        : EVENT_NAMESPACE + '/filter/:id',
    SCHEDULE: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/schedule` : EVENT_NAMESPACE + '/schedule',
    QR_CODE: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/qr-code` : EVENT_NAMESPACE + '/qr-code',
    SOMETHING_WRONG: (eventSlug: string | null = null) =>
      eventSlug ? `/${eventSlug}/422` : EVENT_NAMESPACE + '/422',
    POLL_RESULTS: (eventSlug: string | null = null) =>
      eventSlug
        ? `/${eventSlug}/poll-results`
        : EVENT_NAMESPACE + '/poll-results',
  },
  CONTACTS: (contactSlug: string | null = null) =>
    contactSlug ? `/contacts/${contactSlug}` : '/contacts/:contactSlug?',
  COOKIES: '/cookies',
  COOKIES_LIST: '/cookies-list',
  NOT_FOUND: () => '/404',
};

export default routes;
