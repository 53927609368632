import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import routes from '@routes';
import { ExperienceItem, PreSpreadExperienceItem } from '@app/utils/types';
import { useKeywords } from '@app/hooks';

interface ParamsProps {
  id: string;
  eventSlug: string;
}

function useExperience(
  eventData: any
): { experienceData: ExperienceItem; keywords: string } {
  const history = useHistory();
  const { id: experienceSlug, eventSlug }: ParamsProps = useParams();

  const experienceData = useMemo(() => {
    const experiencesContent = eventData?.homePage?.find(
      (el: any) =>
        el.__typename === 'ComponentExperienceExperienceGroup' &&
        el.experience?.data?.attributes?.experience_items?.data.find(
          (item: PreSpreadExperienceItem) =>
            item.attributes.slug === experienceSlug
        )
    );

    const item = experiencesContent?.experience?.data?.attributes?.experience_items?.data.find(
      (item: PreSpreadExperienceItem) => item.attributes.slug === experienceSlug
    );

    return item;
  }, [eventData, experienceSlug]);

  const keywords = useKeywords(
    experienceData?.attributes?.keywords?.data || []
  );

  useEffect(() => {
    if (!experienceSlug || !experienceData) {
      history.push(routes.EVENT.EXPERIENCES(eventSlug));
      toast.error('Experience not found!', { toastId: 'experience-not-found' });
    }
  }, [experienceSlug, experienceData]);

  return {
    experienceData: { id: experienceData?.id, ...experienceData?.attributes },
    keywords,
  };
}

export default useExperience;
