import routes from '@routes';
import { FilterItemProps } from '@app/utils/types';
import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useKeywords } from '@app/hooks';

interface ParamsProps {
  id: string;
  eventSlug: string;
}

function useFilter(eventData: any) {
  const { id: filterSlug, eventSlug }: ParamsProps = useParams();
  const history = useHistory();

  const data = useMemo(() => {
    const filtersData = eventData?.homePage?.find(
      (el: any) =>
        el.__typename === 'ComponentFilterFiltersGroup' &&
        el.filter_groups?.data.find((item: any) =>
          item.attributes.filterItems.find(
            (filterItem: FilterItemProps) => filterItem.slug === filterSlug
          )
        )
    );

    const filters =
      filtersData?.filter_groups?.data?.[0]?.attributes?.filterItems || [];

    const item = filters.find((item: any) => item.slug === filterSlug);

    return item as FilterItemProps | null;
  }, [eventData]);

  const keywords = useKeywords(data?.keywords?.data || []);

  useEffect(() => {
    if (!filterSlug || !data) {
      history.push(routes.EVENT.FILTERS(eventSlug));
      toast.error('Filter not found!', { toastId: 'filter-not-found' });
    }
  }, [filterSlug, data]);

  return { filter: data, keywords };
}

export default useFilter;
