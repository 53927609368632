import styled from '@emotion/styled';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  isFirst: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 15px;
  width: 100%;
  padding: 21px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  align-items: center;
  ${({ theme, isFirst }) =>
    isFirst && `border-top: 1px solid ${theme.colors.grey300};`}
`;

export const TextsCol = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.blueBlack};
  font-family: NokiaPureText-Bold;
  font-size: 16px;
`;

export const Email = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey700};
  text-transform: lowercase;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const Avatar = styled.div<AvatarProps>`
  border-radius: 4px;
  width: 49px;
  height: 49px;
  background: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const DefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 49px;
  height: 49px;
  background: #ebebeb;
`;
