import { useCallback } from 'react';
import CloseIconSmall from '@app/assets/closeIconSmall';
import GET_EVENT_BY_SLUG from '@queries/getEventBySlug';
import Button from '@components/Button';
import { useStores } from '@stores';
import {
  Title,
  Wrapper,
  Description,
  IconBox,
  BtnWrapper,
} from '@components/Modal/styles';
import routes from '@routes';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

export type Notification = {
  title: string;
  description: string;
  slug: string;
  btnText: string;
  externalUrl: string;
  headerText: string;
};

interface ModalProps {
  event: Partial<Notification>;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEvent: React.Dispatch<React.SetStateAction<Partial<Notification>>>;
}

const ModalContent = ({ event, setModalIsOpen, setEvent }: ModalProps) => {
  const history = useHistory();

  const {
    contentStore: { eventData, setEventData },
  } = useStores();

  const [loadEvent, { loading }] = useLazyQuery(GET_EVENT_BY_SLUG, {
    variables: { slug: event.slug },
    onError: () => {
      if (eventData) return;
      history.push(routes.EVENT.SOMETHING_WRONG(event.slug));
    },
  });

  const onClick = useCallback(async () => {
    if (event.externalUrl === '') {
      const { data } = await loadEvent();
      // @ts-ignore
      setEventData(data?.events?.data?.[0]?.attributes);
      history.push(routes.EVENT.SCHEDULE(event?.slug));
    } else {
      if (!event.externalUrl?.startsWith('https://')) {
        history.push(`/${event?.slug}${event.externalUrl}`);
      } else {
        window.open(event.externalUrl, '_blank');
      }
    }

    setEvent({});
    setModalIsOpen(false);
  }, []);

  return (
    <Wrapper>
      <IconBox>
        <CloseIconSmall onClick={() => setModalIsOpen(false)} />
      </IconBox>
      <Title>{event.headerText || 'Notification'}</Title>
      <Description>{event.description}</Description>
      <BtnWrapper>
        <Button
          onClick={onClick}
          title={event.btnText || 'View schedule'}
          fontSize={15}
          disabled={loading}
        />
      </BtnWrapper>
    </Wrapper>
  );
};

export default ModalContent;
