import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
`;

interface ImageWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  marginBottom?: number | undefined;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : '30px'};
`;

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
  imgHeight?: number | undefined;
  circleSize?: number | undefined;
}

export const ImageBox = styled.div<ImageBoxProps>`
  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight}px` : '181px')};
  border-radius: 4px;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const CircleImg = styled.div<ImageBoxProps>`
  position: absolute;
  top: ${({ imgHeight, circleSize }) =>
    imgHeight && circleSize
      ? `calc(${imgHeight}px - ${circleSize / 2 + 3}px)`
      : '155px'};
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: ${({ circleSize }) => (circleSize ? `${circleSize}px` : '49px')};
  width: ${({ circleSize }) => (circleSize ? `${circleSize}px` : '49px')};
`;

export const Title = styled.div`
  font-family: NokiaPureHeadline-Bold;
  font-size: 17px;
  color: #000;
  text-align: center;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
`;
