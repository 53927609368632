import { useMemo } from 'react';

interface NavItemProps {
  title: string;
  url: string;
  target: string;
}

interface UseNavigationProps {
  navigation?: {
    data?: {
      attributes?: {
        navItems: NavItemProps[];
      };
    };
  };
}

function useNavigation({ navigation }: UseNavigationProps) {
  const navigationData = useMemo(() => {
    return navigation?.data?.attributes?.navItems;
  }, [navigation]);

  return navigationData || [];
}

export default useNavigation;
