import React, { useState, useRef, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import CkContent from '@components/CkContent';
import CloseIcon from '@app/assets/closeIcon';
import ImageCarousel from '@components/ImageCarousel';
import BookmarkLinks from '@components/BookmarkLinks';
import DescriptionSkeleton from '@components/DescriptionSkeleton';
import ButtonOutlined from '@components/ButtonOutlined';
import BookmarkIcon from '@app/assets/bookmarkIcon';
import LinkedinIcon from '@app/assets/linkedinIcon';
import TwitterIcon from '@app/assets/twitterIcon';
import EmailIcon from '@app/assets/emailIcon';
import BookmarkFilledIcon from '@app/assets/bookmarkFilledIcon';
import { useWindowUnload, useBookmark } from '@app/hooks';
import { useEventSlug } from '@app/hooks/useEventSlug';
import {
  resolveStrapiImgUrl,
  convertOembedToIframe,
  formatDateTo0Timezone,
} from '@app/utils';
import {
  AuthorLine,
  Author,
  Date as DateComponent,
} from '@app/pages/articlePage/styles';
import {
  CloseBox,
  ImageContainer,
  Title,
  ContentWrapper,
  Description,
  BtnWrapper,
  AuthorCompanyDetail,
  AuthorDetailsBox,
  SocialsRow,
  SocialItem,
  ReachMeText,
} from './styles';

const ArticleOverlayContent = ({
  selectedBookmark,
  setSelectedBookmark,
}: any) => {
  const [isBookmarked, setIsBookmarked] = useState(true);
  const isBookmarkedRef = useRef(true);
  const {
    bookmarksStore: { removeBookmark },
  } = useStores();

  const eventSlug = useEventSlug();

  const {
    type,
    groupId,
    data: {
      attributes: { title, image, slug },
    },
  } = selectedBookmark;

  const { bookmarkData } = useBookmark(
    groupId,
    slug,
    type,
    setSelectedBookmark
  );

  const { carouselPosition, carouselItems } = useMemo(
    () => ({
      carouselPosition: bookmarkData?.carouselPosition,
      carouselItems:
        bookmarkData?.image_carousel?.data?.attributes?.carouselItems,
    }),
    [bookmarkData]
  );

  useWindowUnload(() => {
    if (!isBookmarkedRef.current)
      removeBookmark(slug, eventSlug, type, groupId);
  }, true);

  const imageUrl = resolveStrapiImgUrl(image);

  const handleBookmarkBtn = () => {
    const bookmarkVal = isBookmarked;
    setIsBookmarked(!bookmarkVal);
    isBookmarkedRef.current = !bookmarkVal;
  };

  const newsAuthor = bookmarkData?.newsAuthor?.data?.attributes;
  const anyNewsAuthorLinks =
    newsAuthor?.linkedinUrl || newsAuthor?.twitterUrl || newsAuthor?.email;
  const anyAuthorDetails =
    anyNewsAuthorLinks || newsAuthor?.role || newsAuthor?.company;

  return (
    <>
      <ImageContainer image={imageUrl}>
        <CloseBox onClick={() => setSelectedBookmark(undefined)}>
          <CloseIcon fill="#fff" />
        </CloseBox>
        <BtnWrapper>
          <ButtonOutlined
            title={isBookmarked ? 'Remove' : 'Add to bookmarks'}
            onClick={handleBookmarkBtn}
            fontSize={12}
            bg="#fff"
            icon={isBookmarked ? <BookmarkFilledIcon /> : <BookmarkIcon />}
            padding="10px 16px"
          />
        </BtnWrapper>
      </ImageContainer>
      <ContentWrapper>
        <Title>{title}</Title>
        {bookmarkData && type === 'article' && (
          <div>
            {bookmarkData?.date && (
              <DateComponent>
                {formatDateTo0Timezone(bookmarkData?.date, 'd MMM yyyy')}
              </DateComponent>
            )}
            <AuthorLine>
              by{' '}
              <Author style={{ fontFamily: 'NokiaPureHeadline-Bold' }}>
                {newsAuthor?.name || bookmarkData?.author}
              </Author>
            </AuthorLine>
            {anyAuthorDetails && (
              <AuthorDetailsBox>
                {newsAuthor?.company && (
                  <AuthorCompanyDetail>
                    {newsAuthor?.company}
                  </AuthorCompanyDetail>
                )}
                {newsAuthor?.role && (
                  <AuthorCompanyDetail>{newsAuthor?.role}</AuthorCompanyDetail>
                )}
                {anyNewsAuthorLinks && (
                  <>
                    <ReachMeText>Get in Touch</ReachMeText>
                    <SocialsRow>
                      {newsAuthor?.email && (
                        <SocialItem href={`mailto:${newsAuthor?.email}`}>
                          <EmailIcon />
                        </SocialItem>
                      )}
                      {newsAuthor?.linkedinUrl && (
                        <SocialItem
                          href={newsAuthor?.linkedinUrl}
                          target="_blank"
                        >
                          <LinkedinIcon />
                        </SocialItem>
                      )}
                      {newsAuthor?.twitterUrl && (
                        <SocialItem
                          href={newsAuthor?.twitterUrl}
                          target="_blank"
                        >
                          <TwitterIcon height="16px" width="16px" />
                        </SocialItem>
                      )}
                    </SocialsRow>
                  </>
                )}
              </AuthorDetailsBox>
            )}
          </div>
        )}
        {bookmarkData ? (
          <>
            {carouselPosition === 'top' && (
              <ImageCarousel images={carouselItems} />
            )}
            <Description>
              <CkContent
                text={convertOembedToIframe(
                  type === 'article'
                    ? bookmarkData?.newsContent_ck
                    : bookmarkData?.description_ck
                )}
              />
            </Description>
            {carouselPosition === 'bottom' && (
              <ImageCarousel images={carouselItems} />
            )}
            <BookmarkLinks bookmarkLinks={bookmarkData?.bookmarkLinks} />
          </>
        ) : (
          <DescriptionSkeleton />
        )}
      </ContentWrapper>
    </>
  );
};

export default observer(ArticleOverlayContent);
