import { apolloClient } from '@app/api';
import { useInteractionContext } from '@components/UserInteractionsGroup/InteractionContext';
import {
  getPollAnswersForUser,
  PollAnswerCheck,
} from '@queries/getPollAnswersForUser';
import { getRatesForUser, RatingAnswerCheck } from '@queries/getRatesForUser';
import routes from '@routes';
import {
  Interactions,
  ArrayInteractions,
  ShortPoll,
  ShortRating,
} from '@app/utils/types';
import { useStores } from '@stores';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useEventSlug } from './useEventSlug';
import authStore from '@stores/authStore';
import { useTrackMarketoLinkClick } from '@app/hooks';

export type MappedShortInteraction = {
  __typename: ShortPoll['__typename'] | ShortRating['__typename'];
  id: string;
};

export type MappedShortInteractionExtended = {
  __typename: ShortPoll['__typename'] | ShortRating['__typename'];
  id: string;
  isAnswered: boolean | null;
};

type ResolvedInteraction = {
  nextInteraction: MappedShortInteraction;
  isLastAnswered: boolean;
};

export const mapInteractions = (
  interactions: ArrayInteractions | Interactions
): MappedShortInteraction[] => {
  if (!interactions) return [];

  const returnPollOrRatingData = (i: ShortPoll | ShortRating) => {
    if (i.__typename === 'ComponentIntersPollInteraction') {
      return {
        __typename: i.__typename,
        id: i.poll.data.id,
      };
    }

    return {
      __typename: i.__typename,
      id: i.rating.data.id,
    };
  };

  if ('user_interactions' in interactions) {
    return (
      interactions.user_interactions.data[0]?.attributes.interactions
        // @ts-ignore
        .filter((item) => item.poll?.data?.id || item.rating?.data?.id)
        .map((i) => returnPollOrRatingData(i))
    );
  }

  return (
    interactions?.userInteraction?.data?.attributes?.interactions
      // @ts-ignore
      .filter((item) => item.poll?.data?.id || item.rating?.data?.id)
      .map((i) => returnPollOrRatingData(i)) || []
  );
};

const checkIfInteracted = async (interaction: MappedShortInteraction) => {
  try {
    const { email } = authStore.user || {};

    if (interaction.__typename === 'ComponentIntersPollInteraction') {
      const res = await apolloClient.query<PollAnswerCheck>({
        query: getPollAnswersForUser,
        variables: { email, pollId: interaction.id },
        fetchPolicy: 'no-cache',
      });

      return res.data.pollAnswers.data.length > 0;
    }

    if (interaction.__typename === 'ComponentIntersRatingInteraction') {
      const res = await apolloClient.query<RatingAnswerCheck>({
        query: getRatesForUser,
        variables: { email, ratingId: interaction.id },
        fetchPolicy: 'no-cache',
      });

      return res.data.ratingAnswers.data.length > 0;
    }

    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getInteractionsWithStatus = async (
  interactions: MappedShortInteraction[]
): Promise<MappedShortInteractionExtended[]> => {
  return Promise.all(
    interactions.map(async (interaction) => {
      const interacted = await checkIfInteracted(interaction);

      return {
        ...interaction,
        isAnswered: interacted,
      };
    })
  );
};

export const resolveNextInteraction = async (
  interactionsArr: MappedShortInteraction[],
  currentInteraction: MappedShortInteraction | null
): Promise<ResolvedInteraction | null> => {
  if (interactionsArr.length === 0) {
    return null;
  }

  const currentInteractionIdx = interactionsArr.findIndex(
    (inter) =>
      inter.id === currentInteraction?.id &&
      inter.__typename === currentInteraction?.__typename
  );

  if (currentInteractionIdx + 1 >= interactionsArr.length) {
    return null;
  }

  const interactionsWithStatus = await getInteractionsWithStatus(
    interactionsArr
  );

  for (let i = currentInteractionIdx + 1; i < interactionsArr.length; i++) {
    const nextInteraction = interactionsWithStatus[i];

    // checkif interacted
    // if not, go to that interaction
    // if yes, continue

    const interacted = nextInteraction.isAnswered;

    if (interacted === null || interacted === true) continue;

    const isLastAnswered = interactionsWithStatus
      .slice(i + 1)
      .reduce((acc, curr) => acc && !!curr.isAnswered, true);

    const returnPayload = {
      nextInteraction,
      isLastAnswered,
    };

    return returnPayload;
  }

  // if no interaction found
  return null;
};

type NoInteractionFn = () => void;
type RoutingMode = {
  mode: 'routing';
  experienceSlug: string;
  routingType: 'replace' | 'push';
};

type ContextMode = {
  mode: 'context';
};

export type Mode = RoutingMode | ContextMode;

type Options = {
  noInteractionFallback?: NoInteractionFn;
  doNotMarkAsDone?: boolean;
};

export function useUserInteraction() {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const { contentStore } = useStores();
  const intCtx = useInteractionContext();
  const trackLink = useTrackMarketoLinkClick();

  const interactWithUser = useCallback(
    async (
      mode: Mode,
      interactions: Interactions | ArrayInteractions | null,
      currentInteraction: MappedShortInteraction | null,
      { doNotMarkAsDone, noInteractionFallback }: Options = {}
    ) => {
      if (!doNotMarkAsDone) {
        contentStore.markInteractionAsAnswered(currentInteraction);
      }

      if (!interactions) {
        noInteractionFallback?.();
        return;
      }

      const resolvedInteraction = await resolveNextInteraction(
        mapInteractions(interactions),
        currentInteraction
      );

      if (!resolvedInteraction) {
        noInteractionFallback?.();
        return;
      }

      const { nextInteraction, isLastAnswered } = resolvedInteraction;
      trackLink();
      if (nextInteraction.__typename === 'ComponentIntersPollInteraction') {
        if (mode.mode === 'routing') {
          history[mode.routingType](
            routes.EVENT.POLL(
              eventSlug,
              mode.experienceSlug,
              nextInteraction.id,
              isLastAnswered
            )
          );
        }
        if (mode.mode === 'context') {
          intCtx?.setIsLastInteraction(isLastAnswered);
          intCtx?.setCurrentInteraction(nextInteraction);
        }

        return;
      }

      if (nextInteraction.__typename === 'ComponentIntersRatingInteraction') {
        if (mode.mode === 'routing') {
          history[mode.routingType](
            routes.EVENT.RATING(
              eventSlug,
              mode.experienceSlug,
              nextInteraction.id,
              isLastAnswered
            )
          );
        }
        if (mode.mode === 'context') {
          intCtx?.setIsLastInteraction(isLastAnswered);
          intCtx?.setCurrentInteraction(nextInteraction);
        }

        return;
      }
      // if no interaction found, fallback
      noInteractionFallback?.();
      return null;
    },
    []
  );

  return {
    interactWithUser,
  };
}
