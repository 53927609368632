import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ButtonOutlined from '@components/ButtonOutlined';
import {
  ButtonContainer,
  Iframe,
  IframeContainer,
  Warning,
} from '@components/IframeComponents/styles';
import { useDetectIOSVersion, useExperience } from '@app/hooks';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { Mode, useUserInteraction } from '@app/hooks/useUserInteraction';
import routes from '@routes';
import { useStores } from '@stores';
import Header from '@components/Header';
import { CustomHeaderConfig } from '@app/utils/types';
import { IconButton } from '@components/IconButton';
import CloseIcon from '@app/assets/closeIcon';
import { getUserFromStorage } from '@app/api';

const url8thwallRegex = /8thwall/;

const ExperienceVisitPage = () => {
  const version = useDetectIOSVersion();
  const history = useHistory();
  const eventSlug = useEventSlug();

  const {
    contentStore: { eventData },
  } = useStores();

  const { experienceData, keywords } = useExperience(eventData);

  const { experienceUrl, interactions, slug, title, hideExitButton } =
    experienceData;

  const user = getUserFromStorage(eventSlug);

  const { interactWithUser } = useUserInteraction();

  const mode: Mode = {
    mode: 'routing',
    experienceSlug: slug,
    routingType: 'push',
  };

  const handleExit = () => {
    interactWithUser(mode, interactions, null, {
      noInteractionFallback: () =>
        history.push(routes.EVENT.EXPERIENCES(eventSlug)),
    });
  };

  const is8thwallExperience = url8thwallRegex.test(experienceUrl);

  const isUsableVersion = is8thwallExperience
    ? !version || version >= 15
    : true;

  useEffect(() => {
    let mounted = true;
    if (!isUsableVersion && experienceUrl) {
      window.open(experienceUrl, '_blank');
      setTimeout(() => {
        mounted &&
          interactWithUser(mode, interactions, null, {
            noInteractionFallback: () =>
              history.push(routes.EVENT.EXPERIENCES(eventSlug)),
          });
      }, 2000);
    }

    return () => {
      mounted = false;
    };
  }, [isUsableVersion, experienceUrl]);

  const customCfg: CustomHeaderConfig = useMemo(
    () => ({
      visible: is8thwallExperience,
    }),
    [is8thwallExperience]
  );

  return (
    <>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Header customCfg={customCfg} />
      <IframeContainer>
        {!experienceUrl && <Warning>No experience URL</Warning>}

        {isUsableVersion && experienceUrl && (
          <Iframe
            title={title}
            allow="accelerometer;ambient-light-sensor;autoplay;battery;camera;display-capture;document-domain;encrypted-media;fullscreen;geolocation;gyroscope;layout-animations;legacy-image-formats;magnetometer;microphone;midi;navigation-override;oversized-images;payment;picture-in-picture;publickey-credentials-get;sync-script;sync-xhr;usb;screen-wake-lock;web-share;unoptimized-images;unsized-media;xr-spatial-tracking;"
            src={`${experienceUrl}?email=${user?.email}`}
          />
        )}
        {!hideExitButton && (
          <ButtonContainer bottom={is8thwallExperience ? '40px' : '80px'}>
            {is8thwallExperience ? (
              <ButtonOutlined
                title="Exit experience"
                onClick={handleExit}
                bg="#fff"
              />
            ) : (
              <IconButton
                Icon={CloseIcon}
                variant="outlined"
                onClick={handleExit}
                bg="#fff"
              />
            )}
          </ButtonContainer>
        )}
      </IframeContainer>
    </>
  );
};

export default observer(ExperienceVisitPage);
