import React from 'react';
import { useHistory } from 'react-router-dom';
import { resolveStrapiImgUrl } from '@app/utils';
import ButtonOutlined from '@components/ButtonOutlined';
import { ArticleSimple } from '@app/utils/types';
import routes from '@routes';
import { observer } from 'mobx-react-lite';
import {
  Container,
  ImageBox,
  InfoWrapper,
  DateAuthorRow,
  Title,
} from './styles';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';
import { formatDateTo0Timezone } from '@app/utils';

interface NewsItemSecondaryProps {
  groupId: string;
  props: { attributes: ArticleSimple };
}

const NewsItemSecondary = ({ groupId, props }: NewsItemSecondaryProps) => {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();

  const {
    attributes: { title, date, author, image, slug, buttonText },
  } = props;

  const parsedDate = formatDateTo0Timezone(date, 'd MMM');

  const imgUrl = resolveStrapiImgUrl(image);

  const goToArticle = () => {
    trackLink();
    history.push(routes.EVENT.ARTICLE(groupId, slug, eventSlug));
  };

  return (
    <Container>
      <ImageBox image={imgUrl} />
      <InfoWrapper>
        <DateAuthorRow>{`${parsedDate} • ${author}`}</DateAuthorRow>
        <Title>{title}</Title>
        <div>
          <ButtonOutlined
            title={buttonText ? buttonText : 'Read Article'}
            onClick={goToArticle}
          />
        </div>
      </InfoWrapper>
    </Container>
  );
};

export default observer(NewsItemSecondary);
