import { BaseContainer } from '@components/BaseContainer';
import styled from '@emotion/styled';

export const Container = styled(BaseContainer)`
  padding: 35px 45px 20px;
  gap: 50px;
  align-items: center;
`;

export const LogoWrap = styled.div`
  display: flex;
`;
