import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useSchedule } from '@app/hooks';
import { ScrollableContainer } from './styles';
import ScheduleHeader from './ScheduleHeader';
import ScheduleSkeleton from './ScheduleSkeleton';
import Wrapper from '@components/Wrapper';

const ScheduleRenderer = lazy(() => import('./ScheduleRenderer'));

const Schedule = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { schedule, keywords } = useSchedule(eventData);

  return (
    <Wrapper padding="75px 20px 20px">
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      {schedule ? (
        <Suspense
          fallback={
            <>
              <ScheduleHeader />
              <ScrollableContainer>
                <ScheduleSkeleton />
              </ScrollableContainer>
            </>
          }
        >
          <ScheduleRenderer schedule={schedule} />
        </Suspense>
      ) : (
        <>
          <ScheduleHeader />
          <ScrollableContainer>
            <p>No schedule data</p>
          </ScrollableContainer>
        </>
      )}
    </Wrapper>
  );
};

export default observer(Schedule);
