import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useExperiencesPage } from '@app/hooks';
import BigExperienceItem from '@components/Experience/BigExperienceItem';
import { Title, ExperiencesWrapper } from './styles';
import { ExperienceItem } from '@app/utils/types';
import theme from '@app/theme';
import Wrapper from '@components/Wrapper';

const ExperiencesPage = () => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { experiencesContent, keywords } = useExperiencesPage(eventData);

  return (
    <Wrapper bgColor={theme.colors.grey100} padding="75px 20px">
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Title>Experiences</Title>
      <ExperiencesWrapper>
        {experiencesContent.map((experience: ExperienceItem) => (
          <React.Fragment key={experience.title.replace(/\s/g, '')}>
            <BigExperienceItem item={experience} />
          </React.Fragment>
        ))}
      </ExperiencesWrapper>
    </Wrapper>
  );
};

export default observer(ExperiencesPage);
