import { useLayoutEffect } from 'react';

let count = 0;

const block = () => {
  count++;
  document.body.classList.add('block-scroll');
};

const unblock = () => {
  count--;
  if (count === 0) {
    document.body.classList.remove('block-scroll');
  }
};

function useBlockScroll(blockScroll?: boolean) {
  useLayoutEffect(() => {
    if (blockScroll) {
      block();
      return unblock;
    }
  }, [blockScroll]);
}

export default useBlockScroll;
