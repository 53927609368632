import routes from '@routes';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useStores } from '@stores';
import AuthCheck from '@components/AuthCheck';
import ContentHandler from '@components/ContentHandler';
import LandingPage from '@pages/landingPage';
import ExperiencePage from '@pages/experiencePage';
import ExperiencesPage from '@pages/experiencesPage';
import HomePage from '@pages/homePage';
import ArticlePage from '@pages/articlePage';
import ArticlesPage from '@pages/articlesPage';
import SomethingWentWrongPage from '@pages/somethingWrongPage';
import RatingPage from '@pages/ratingPage';
import PollPage from '@pages/pollPage';
import PollResultsPage from '@pages/pollResultsPage';
import FiltersPage from '@pages/filtersPage';
import FilterPage from '@pages/filterPage';
import FlatExperiencePage from '@pages/flatExperiencePage';
import FlatExperiencesPage from '@pages/flatExperiencesPage';
import ExperienceVisitPage from '@pages/experienceVisitPage';
import SchedulePage from '@pages/schedulePage';
import BookmarksPage from '@pages/bookmarksPage';
import EventLoaderPlaceholder from '@components/EventLoaderPlaceholder';
import useIsEventLoading from '@app/hooks/useIsEventLoading';
import Header from '@components/Header';
import { observer } from 'mobx-react-lite';
import { useEventSlug } from '@app/hooks/useEventSlug';
import SignUpPage from '@pages/signUpPage';
import { socket } from '../../../socket';
import ReactModal from 'react-modal';
import ModalContent from '@components/Modal';
import useBlockScroll from '@app/hooks/useBlockScroll';

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0, 0.3)',
    zIndex: 10000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '650px',
    maxHeight: '90vh',
    padding: '30px 50px 20px',
  },
};

const Event = () => {
  const [isConnected, setIsConnected] = useState(socket.connected); // eslint-disable-line
  const [event, setEvent] = useState<Partial<Notification>>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    contentStore: { eventData },
    bookmarksStore: { eventSlug: storeEventSlug, setEventSlug },
  } = useStores();

  useBlockScroll(modalIsOpen);

  const isLoading = useIsEventLoading();
  const eventSlug = useEventSlug();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleNotification = (value: any) => {
    if (value?.slug === eventSlug) {
      setEvent(value);
      openModal();
    }
  };

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function detectNotification(value: any) {
      handleNotification(value);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('events', detectNotification);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('events', detectNotification);
    };
  }, []);

  useEffect(() => {
    if (storeEventSlug !== eventSlug) {
      setEventSlug(eventSlug);
    }
  }, [eventSlug]);

  return (
    <>
      <ContentHandler />
      {eventData && <AuthCheck />}
      <ReactModal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        contentLabel="Event Notification"
        style={customStyles}
      >
        <ModalContent
          setModalIsOpen={setModalIsOpen}
          event={event}
          setEvent={setEvent}
        />
      </ReactModal>

      {!isLoading && <Header />}
      <Switch>
        {isLoading && <EventLoaderPlaceholder />}
        <Route exact path={routes.EVENT.HOME()} component={HomePage} />
        <Route
          exact
          path={routes.EVENT.LANDING_PAGE()}
          component={LandingPage}
        />
        <Route exact path={routes.EVENT.SIGN_UP()} component={SignUpPage} />
        <Route
          exact
          path={routes.EVENT.EXPERIENCES()}
          component={ExperiencesPage}
        />
        <Route
          exact
          path={routes.EVENT.EXPERIENCE()}
          component={ExperiencePage}
        />
        <Route
          exact
          path={routes.EVENT.EXPERIENCE_VISIT()}
          component={ExperienceVisitPage}
        />
        <Route exact path={routes.EVENT.ARTICLES()} component={ArticlesPage} />
        <Route exact path={routes.EVENT.ARTICLE()} component={ArticlePage} />
        <Route
          exact
          path={routes.EVENT.FLAT_EXPERIENCES()}
          component={FlatExperiencesPage}
        />
        <Route
          exact
          path={routes.EVENT.FLAT_EXPERIENCE()}
          component={FlatExperiencePage}
        />
        <Route
          exact
          path={routes.EVENT.FLAT_EXPERIENCE_GROUP()}
          component={FlatExperiencesPage}
        />
        <Route
          exact
          path={routes.EVENT.BOOKMARKS()}
          component={BookmarksPage}
        />
        <Route exact path={routes.EVENT.FILTERS()} component={FiltersPage} />
        <Route exact path={routes.EVENT.FILTER()} component={FilterPage} />
        <Route exact path={routes.EVENT.SCHEDULE()} component={SchedulePage} />
        <Route exact path={routes.EVENT.RATING()} component={RatingPage} />
        <Route exact path={routes.EVENT.POLL()} component={PollPage} />
        <Route
          exact
          path={routes.EVENT.POLL_RESULTS()}
          component={PollResultsPage}
        />
        <Route
          exact
          path={routes.EVENT.SOMETHING_WRONG()}
          component={SomethingWentWrongPage}
        />
        <Redirect to={`${routes.NOT_FOUND()}?ev=${eventSlug}`} push={false} />
      </Switch>
    </>
  );
};

export default observer(Event);
