import React from 'react';
import { observer } from 'mobx-react-lite';
import Carousel from 'react-elastic-carousel';
import { ArticleCarouselProps } from '@components/Articles/ArticlesCarousel';
import ArticleItemSmall from '@components/Articles/ArticleSmall';
import { useArticles } from '@app/hooks';
import { useStores } from '@stores';

const breakPoints = [{ width: 1, itemsToShow: 1 }];

const ArticlesCarouselSmall = ({ outerSpacing }: ArticleCarouselProps) => {
  const {
    contentStore: { eventData },
  } = useStores();

  const { articleContent, groupId } = useArticles({
    eventData,
  });

  if ((articleContent || []).length <= 0) return null;

  return (
    <div id="smallArticleCarousel">
      {/* 
      @ts-ignore */}
      <Carousel
        breakPoints={breakPoints}
        itemsToShow={1.5}
        pagination={true}
        showArrows={false}
        itemPadding={[0, 6, 0, 6]}
        outerSpacing={outerSpacing ?? 16}
      >
        {articleContent?.map((article) => (
          <React.Fragment key={article.attributes.slug}>
            <ArticleItemSmall props={article} groupId={groupId} isCarousel />
          </React.Fragment>
        ))}
      </Carousel>
    </div>
  );
};

export default observer(ArticlesCarouselSmall);
