import { ExperienceItem } from '@app/utils/types';
import { useKeywords } from '@app/hooks';
import { useMemo } from 'react';

function useExperiencesPage(
  eventData: any
): {
  experiencesContent: ExperienceItem[];
  keywords: string;
} {
  const experiencesDataGroups = eventData?.homePage?.filter(
    (el: any) => el.__typename === 'ComponentExperienceExperienceGroup'
  );

  const keywords = useKeywords(
    experiencesDataGroups?.[0]?.experience?.data?.attributes?.keywords?.data ||
      []
  );

  const experiencesContent = useMemo(() => {
    const data = experiencesDataGroups.map((experiencesGroup: any) => {
      const items =
        experiencesGroup?.experience?.data?.attributes?.experience_items.data ||
        [];

      const itemsFiltered = items.filter(
        (item: any) => item.attributes.hidden !== true
      );

      return (itemsFiltered.map((it: any) => ({
        id: it.id,
        ...it.attributes,
      })) as ExperienceItem[]).sort(
        (a, b) =>
          a.order - b.order ||
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      );
    });

    const experiencesListingData: ExperienceItem[] = [];
    data.forEach((item: ExperienceItem[]) => {
      experiencesListingData.push(...item);
    });
    const finalData = experiencesListingData.filter(
      (currentObject, index, self) => {
        return (
          index === self.findIndex((obj) => obj.slug === currentObject.slug)
        );
      }
    );
    return finalData;
  }, [experiencesDataGroups]);
  return { experiencesContent, keywords };
}

export default useExperiencesPage;
