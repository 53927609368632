import { Mode, useUserInteraction } from '@app/hooks/useUserInteraction';
import { Interactions } from '@app/utils/types';
import React from 'react';
import Poll from '@components/Interactions/Poll';
import Rating from '@components/Interactions/Rating';
import { useSafeInteractionContext } from './InteractionContext';
import { InteractionWrapper } from './styles';

type Props = {
  interactions: Interactions | null;
};

const UserInteractionSection = ({ interactions }: Props) => {
  const {
    currentInteraction,
    setInteractionState,
    setCurrentInteraction,
    isLastInteraction,
  } = useSafeInteractionContext();
  const { interactWithUser } = useUserInteraction();

  const fallback = () => {
    setInteractionState('closed');
    setCurrentInteraction(null);
  };

  const mode: Mode = {
    mode: 'context',
  };

  const interact = () =>
    interactWithUser(mode, interactions, currentInteraction, {
      noInteractionFallback: fallback,
    });

  if (!currentInteraction) return null;

  return (
    <InteractionWrapper>
      {currentInteraction.__typename === 'ComponentIntersPollInteraction' && (
        <Poll
          pollId={currentInteraction.id}
          isLast={isLastInteraction}
          interactWithUser={interact}
          onClose={fallback}
          onNotFound={interact}
          context
        />
      )}
      {currentInteraction.__typename === 'ComponentIntersRatingInteraction' && (
        <Rating
          ratingId={currentInteraction.id}
          isLast={isLastInteraction}
          interactWithUser={interact}
          onClose={fallback}
          onNotFound={interact}
          context
        />
      )}
    </InteractionWrapper>
  );
};

export default UserInteractionSection;
