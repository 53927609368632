import { BaseContainer } from '@components/BaseContainer';
import styled from '@emotion/styled';

export const IframeContainer = styled(BaseContainer)`
  align-items: center;
  justify-content: center;
  background-color: #000;
`;

export const Iframe = styled.iframe`
  width: 100%;
  flex: 1;
  height: 100%;
  border: none;
`;

export const Warning = styled.h2`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => theme.colors.blueBlack};
  font-family: NokiaPureHeadline-Bold;
  font-size: 32px;
`;

export const ButtonContainer = styled.div<{ bottom: string }>`
  display: flex;
  position: fixed;
  bottom: ${({ bottom }) => bottom};
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;
