function useDetectIOSVersion() {
  const agent = window.navigator.userAgent,
    start = agent.indexOf('OS ');
  if (
    (agent.indexOf('iPhone') > -1 || agent.indexOf('iPad') > -1) &&
    start > -1
  ) {
    return Number(agent.slice(start + 3, start + 3 + 3).replace('_', '.'));
  }

  return null;
}

export default useDetectIOSVersion;
