import React from 'react';
import { Title, Description } from './styles';

interface SignUpIntroProps {
  title?: string;
  description?: string;
  authType?: string;
}

const SignUpIntro = ({ title, description, authType }: SignUpIntroProps) => {
  const defaultDesc =
    authType === 'Basic_password'
      ? 'Please enter event password to get access to the experience'
      : 'Please enter your email to get access to the experience';

  return (
    <div>
      <Title>{title || 'Enter the Experience'}</Title>
      <Description>{description || defaultDesc}</Description>
    </div>
  );
};

export default SignUpIntro;
