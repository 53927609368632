import * as React from 'react';

const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#333"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M11.333 2.666H2.667c-.734 0-1.334.6-1.334 1.333v8c0 .734.6 1.334 1.334 1.334h10.666c.734 0 1.334-.6 1.334-1.334V4.333c0-.6-.734-.867-1.134-.467l-4.4 4.467a1.289 1.289 0 0 1-1.866 0L2.333 3.266"
    />
  </svg>
);

export default EmailIcon;
