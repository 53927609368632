import React from 'react';
import {
  SkeletonWrapper,
  InputSkeleton,
  Wrapper,
  ConsentSkeleton,
  SubmitSkeleton,
} from './styles';

const consentRows = Array.from(Array(6).keys());

const MarketoFormSkeleton = () => {
  return (
    <SkeletonWrapper>
      <InputSkeleton />
      <Wrapper>
        {consentRows.map((item: any) => (
          <ConsentSkeleton key={item} />
        ))}
      </Wrapper>
      <SubmitSkeleton />
    </SkeletonWrapper>
  );
};

export default MarketoFormSkeleton;
