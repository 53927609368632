import styled from '@emotion/styled';

interface ContentWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  bgColor?: string;
  padding?: string;
  bgImage?: string;
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ bgColor }) => `${bgColor}`};
  padding: ${({ padding }) => `${padding}`};
  background-image: ${({ bgImage }) =>
    bgImage ? `url('${bgImage}')` : 'unset'};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const Hug = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 100vh;
`;
