import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { REFRESH_INTERVALS } from '@app/utils';
import { toast } from 'react-toastify';
import GET_FLAT_EXPERIENCE from '@queries/getFlatExperience';
import GET_ARTICLE from '@queries/getArticle';
import { FlatExperience, BookmarkTypes } from '@app/utils/types';

function useBookmark(
  groupId: string,
  slug: string,
  type: BookmarkTypes,
  setSelectedBookmark: React.Dispatch<React.SetStateAction<any>>
) {
  let queryType;
  if (type === 'article') {
    queryType = GET_ARTICLE;
  } else {
    queryType = GET_FLAT_EXPERIENCE;
  }

  const { loading, data, startPolling } = useQuery(queryType, {
    variables: { id: groupId, slug },
  });

  useEffect(() => {
    const flatExperiencesTest =
      (
        data?.flatExperienceGroup?.data?.attributes?.flat_experiences?.data ||
        []
      ).length === 0;

    const articlesTest =
      (data?.newsGroup?.data?.attributes?.news_items?.data || []).length === 0;
    if (
      !groupId ||
      !slug ||
      (!loading && flatExperiencesTest && articlesTest)
    ) {
      setSelectedBookmark(null);
      toast.error('Bookmark content not found!', {
        toastId: 'bookmark-content-not-found',
      });
    } else {
      startPolling(REFRESH_INTERVALS.ARTICLE);
    }
  }, [groupId, slug, loading, data]);

  const bookmarkData = useMemo(() => {
    if (type === 'flatExperience') {
      return data?.flatExperienceGroup?.data?.attributes?.flat_experiences
        ?.data?.[0]?.attributes as FlatExperience;
    }
    if (type === 'article') {
      return data?.newsGroup?.data?.attributes?.news_items?.data?.[0]
        ?.attributes;
    }
  }, [loading, data]);

  return { loading, bookmarkData };
}

export default useBookmark;
