import { useMemo } from 'react';
import { useKeywords } from '@app/hooks';
import { FlatExperience } from '@app/utils/types';

function useFlatExperiences({ eventData, flatExperiencesData }: any) {
  let foundFlatExperiencesData: any;

  if (eventData) {
    foundFlatExperiencesData = eventData?.homePage?.find(
      (el: any) => el.__typename === 'ComponentFlexpsFlExpGr'
    );
  } else {
    foundFlatExperiencesData = flatExperiencesData;
  }

  const keywords = useKeywords(
    foundFlatExperiencesData?.flat_experience_groups?.data?.[0]?.attributes
      ?.keywords?.data || []
  );

  const {
    flatExperiencesContent,
    groupId,
    flatExperiencesCarouselTitle,
  } = useMemo(() => {
    return {
      flatExperiencesContent: (foundFlatExperiencesData?.flat_experience_groups
        ?.data?.[0]?.attributes?.flat_experiences?.data || []) as {
        attributes: FlatExperience;
      }[],
      groupId:
        foundFlatExperiencesData?.flat_experience_groups?.data?.[0]?.attributes
          ?.slug,
      flatExperiencesCarouselTitle:
        foundFlatExperiencesData?.flat_experience_groups?.data?.[0]?.attributes
          ?.homeCarouselTitle,
    };
  }, [foundFlatExperiencesData]);

  return {
    flatExperiencesContent,
    groupId,
    keywords,
    flatExperiencesCarouselTitle,
  };
}

export default useFlatExperiences;
