import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import GET_SCHEDULE_ITEM from '@queries/getScheduleItem';
import { toast } from 'react-toastify';
import { ScheduleItem } from '@app/utils/types';

function useScheduleItem(itemSlug: string, slug: string) {
  const { loading, data } = useQuery(GET_SCHEDULE_ITEM, {
    variables: { slug, itemSlug },
  });

  useEffect(() => {
    if (
      !itemSlug ||
      !slug ||
      (!loading &&
        (data?.schedules?.data?.[0]?.attributes?.scheduleItem || []).length ===
          0)
    ) {
      toast.error('Content not found!', { toastId: 'content-not-found' });
    }
  }, [itemSlug, slug, loading, data]);

  const itemData = useMemo(
    () =>
      data?.schedules?.data?.[0]?.attributes?.scheduleItem?.[0] as ScheduleItem,
    [loading, data]
  );

  return {
    description_ck: itemData?.description_ck,
    image: itemData?.image,
    loading,
  };
}

export default useScheduleItem;
