import { BaseContainer } from '@components/BaseContainer';
import styled from '@emotion/styled';

export const Container = styled(BaseContainer)`
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const Title = styled.div`
  color: #fff;
  font-size: 32px;
  font-family: NokiaPureHeadline-Bold;
`;
