import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
`;

export const SendBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding-top: 39px;
  width: 100%;
`;
