import { useEffect, useMemo } from 'react';
import routes from '@routes';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { REFRESH_INTERVALS } from '@app/utils';
import { toast } from 'react-toastify';
import GET_FLAT_EXPERIENCE from '@queries/getFlatExperience';
import { FlatExperience } from '@app/utils/types';

interface ParamsProps {
  groupId: string;
  slug: string;
  eventSlug: string;
}

function useFlatExperience(eventData: any) {
  const history = useHistory();
  const { groupId: groupSlug, slug, eventSlug }: ParamsProps = useParams();

  const flatExperienceContent = eventData?.homePage?.find(
    (el: any) =>
      el.__typename === 'ComponentFlexpsFlExpGr' &&
      el.flat_experience_groups?.data?.find(
        (newsItem: any) => newsItem.attributes.slug === groupSlug
      )
  );

  const groupId = flatExperienceContent?.flat_experience_groups?.data?.find(
    (item: any) => item.attributes.slug === groupSlug
  )?.id;

  const { loading, data, startPolling } = useQuery(GET_FLAT_EXPERIENCE, {
    variables: { id: groupId, slug },
  });

  useEffect(() => {
    if (
      !groupId ||
      !slug ||
      (!loading &&
        (
          data?.flatExperienceGroup?.data?.attributes?.flat_experiences?.data ||
          []
        ).length === 0)
    ) {
      history.push(routes.EVENT.FLAT_EXPERIENCES(eventSlug));
      toast.error('Supporting content not found!', {
        toastId: 'supporting-content-not-found',
      });
    } else {
      startPolling(REFRESH_INTERVALS.ARTICLE);
    }
  }, [groupId, slug, loading, data]);

  const flatExperienceData = useMemo(
    () =>
      data?.flatExperienceGroup?.data?.attributes?.flat_experiences?.data?.[0]
        ?.attributes as FlatExperience,
    [loading, data]
  );

  return { loading, flatExperienceData, groupSlug, eventSlug, groupId };
}

export default useFlatExperience;
