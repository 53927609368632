import { gql } from '@apollo/client';

export default gql`
  query GetScheduleItem($itemSlug: String! = "", $slug: String! = "") {
    schedules(filters: { slug: { eq: $slug } }) {
      data {
        attributes {
          scheduleItem(filters: { slug: { eq: $itemSlug } }) {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            description_ck
          }
        }
      }
    }
  }
`;
