import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useSearchParams() {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  return searchParams;
}

export default useSearchParams;
