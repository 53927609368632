import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import Carousel from 'react-elastic-carousel';
import BigExperienceItem from '@components/Experience/BigExperienceItem';
import { useExperiences } from '@app/hooks';
import { Container } from './styles';

interface ExperiencesGroupProps {
  experiencesData?: any;
}

const breakPoints = [{ width: 1, itemsToShow: 1 }];

const ExperienceGroup = ({ experiencesData }: ExperiencesGroupProps) => {
  const { experiencesContent } = useExperiences({ experiencesData });

  const showPagination = useMemo(() => (experiencesContent || []).length > 1, [
    experiencesContent,
  ]);

  if ((experiencesContent || []).length <= 0) return null;

  return (
    <Container>
      {/* @ts-ignore */}
      <Carousel
        breakPoints={breakPoints}
        itemsToShow={1.5}
        pagination={showPagination}
        showArrows={false}
        itemPadding={[0, 6, 0, 6]}
        outerSpacing={16}
      >
        {experiencesContent?.map((experience) => (
          <React.Fragment key={experience.title.replace(/\s/g, '')}>
            <BigExperienceItem item={experience} isCarousel />
          </React.Fragment>
        ))}
      </Carousel>
    </Container>
  );
};

export default observer(ExperienceGroup);
