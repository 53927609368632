import * as React from 'react';

const DefaultContactAvatarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={49}
    height={49}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={49} height={49} rx={4} fill="#EBEBEB" />
    <path
      d="M24.104 33.036a8.932 8.932 0 1 0 0-17.864 8.932 8.932 0 0 0 0 17.864Z"
      stroke="#333"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.74 27.266a5.394 5.394 0 0 1-3.636 1.393 5.438 5.438 0 0 1-3.483-1.26"
      stroke="#333"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.62 22.407a.447.447 0 1 0 0-.893.447.447 0 0 0 0 .893Z"
      fill="#333"
    />
    <path
      d="M20.62 22.407a.447.447 0 1 0 0-.893.447.447 0 0 0 0 .893Z"
      stroke="#333"
      strokeWidth={1.5}
    />
    <path
      d="M27.767 22.407a.447.447 0 1 0 0-.893.447.447 0 0 0 0 .893Z"
      fill="#333"
    />
    <path
      d="M27.767 22.407a.447.447 0 1 0 0-.893.447.447 0 0 0 0 .893Z"
      stroke="#333"
      strokeWidth={1.5}
    />
  </svg>
);

export default DefaultContactAvatarIcon;
