import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 17, 53, 0.3);
  z-index: 9999;
`;

export const ContentBox = styled.div`
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  height: calc(100% - 75px);
  width: 100%;
  margin-top: 75px;
  padding: 99px 80px 60px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  gap: 20px;
`;

export const CloseBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 24px;
  top: 99px;
  color: #49535F;
`;

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const Avatar = styled.div<AvatarProps>`
  border-radius: 4px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const DefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  background: #ebebeb;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.blueBlack};
  font-size: 28px;
  font-family: NokiaPureText-Bold;
  text-align: center;
`;

export const JobTitle = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey900};
  text-align: center;
`;

export const Description = styled.div`
  font-size: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey700};
`;

export const Email = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-align: center;
  font-size: 20px;
`;

export const Phone = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.grey900};
  text-align: center;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 27px;
  margin-bottom: auto;
`;

export const BtnsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
