import React from 'react';
import Carousel from 'react-elastic-carousel';
import { useFilters } from '@app/hooks';
import FilterItem from '@components/Filters/FilterItem';
import { Container, Title } from './styles';

interface FiltersCarouselProps {
  filtersData?: any;
}

const breakPoints = [{ width: 1, itemsToShow: 3 }];

const FiltersCarousel = ({ filtersData }: FiltersCarouselProps) => {
  const { filtersContent, filtersCarouselTitle } = useFilters({ filtersData });

  if (filtersContent.length < 1) return null;

  return (
    <Container>
      <Title>
        {filtersCarouselTitle ? filtersCarouselTitle : 'Photo Filters'}
      </Title>
      {/*
      // @ts-ignore */}
      <Carousel
        breakPoints={breakPoints}
        itemsToShow={1.5}
        pagination={false}
        showArrows={false}
        itemPadding={[0, 6, 0, 6]}
        outerSpacing={16}
      >
        {filtersContent?.map((filter) => (
          <React.Fragment key={filter.title}>
            <FilterItem filter={filter} />
          </React.Fragment>
        ))}
      </Carousel>
    </Container>
  );
};

export default FiltersCarousel;
