import React from 'react';
import { SkeletonWrapper, LineSkeleton } from './styles';

const skeletons = Array.from({ length: 10 }, (_, i) => i);

const DescriptionSkeleton = () => {
  return (
    <SkeletonWrapper>
      {skeletons.map((_, i) => (
        <LineSkeleton key={i} />
      ))}
    </SkeletonWrapper>
  );
};

export default DescriptionSkeleton;
