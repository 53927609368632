import { useMemo } from 'react';
import { useKeywords } from '@app/hooks';
import { UseArticlesProps, ArticleSimple } from '@app/utils/types';

function useArticles({ eventData, articleData }: UseArticlesProps) {
  let foundNews: any;
  if (eventData) {
    foundNews = eventData?.homePage?.find(
      (el: any) => el.__typename === 'ComponentNewsNewsGroup'
    );
  } else {
    foundNews = articleData;
  }

  const keywords = useKeywords(
    foundNews?.news_groups?.data?.[0]?.attributes?.keywords?.data || []
  );

  const { articleContent, groupId, articlesCarouselTitle } = useMemo(() => {
    return {
      articleContent: foundNews?.news_groups?.data?.[0]?.attributes?.news_items
        ?.data as { attributes: ArticleSimple }[],
      groupId: foundNews?.news_groups?.data?.[0]?.attributes?.slug,
      articlesCarouselTitle:
        foundNews?.news_groups?.data?.[0]?.attributes?.homeCarouselTitle,
    };
  }, [foundNews]);

  return { articleContent, groupId, keywords, articlesCarouselTitle };
}

export default useArticles;
