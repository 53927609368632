import { BaseContainer } from '@components/BaseContainer';
import styled from '@emotion/styled';

export const Container = styled(BaseContainer)`
  gap: 15px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-family: 'NokiaPureHeadline-Regular';
  font-size: 22px;
  color: #fff;
  text-align: center;
  padding: 0 20px;
`;

export const Date = styled.div`
  font-family: 'NokiaPureHeadline-Light';
  font-size: 20px;
  color: #fff;
  margin-bottom: 25px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
`;
