import styled from '@emotion/styled';

export const Title = styled.div`
  font-family: NokiaPureText-Bold;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.blueBlack};
  margin-bottom: 5px;
`;

export const ContentWrapper = styled.div`
  font-size: 14px;
  color: #000;

  a {
    text-decoration: none;
  }
`;

export const H2Title = styled.p`
  font-size: 24px;
`;

export const CustomList = styled.ul`
  a {
    line-break: anywhere;
    outline: 0;
  }
`;
