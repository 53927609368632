import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useMunchkinPageViewTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      (window as any)?.Munchkin?.munchkinFunction(
        (window as any)?.Munchkin?.VISIT_WEB_PAGE,
        { href: location.pathname }
      );
    }
  }, [location.pathname, (window as any)?.Munchkin]);
};

export default useMunchkinPageViewTracking;
