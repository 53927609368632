import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { HEADER_CONFIG } from '@constants';
import { matchPath } from 'react-router';
import routes from '@routes';

function useHeader(isEventPage: boolean) {
  const location = useLocation();

  const headerCfg = useMemo(() => {
    if (isEventPage) {
      return {
        route: routes.EVENT.HOME(),
        visible: true,
        logoColor: '#005AFF',
        transparent: false,
        isHomepage: true,
      };
    }

    return (
      HEADER_CONFIG.find((cfg) =>
        matchPath(location.pathname, {
          path: cfg.route,
          exact: true,
          strict: false,
        })
      ) || HEADER_CONFIG[0]
    );
  }, [location.pathname, isEventPage]);

  return headerCfg;
}

export default useHeader;
