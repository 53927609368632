import React from 'react';
import { useHistory } from 'react-router-dom';
import defaultLpBackground from '@app/assets/landingPageDefaultBg.png';
import ButtonOutlined from '@components/ButtonOutlined';
import routes from '@routes';
import { Container, Content, Title } from './styles';
import { useEventSlug } from '@app/hooks/useEventSlug';
import { useTrackMarketoLinkClick } from '@app/hooks';

const SomethingWentWrongPage = () => {
  const history = useHistory();
  const eventSlug = useEventSlug();
  const trackLink = useTrackMarketoLinkClick();

  const handleRedirect = () => {
    trackLink();
    history.push(routes.EVENT.HOME(eventSlug));
  };

  return (
    <Container bgImage={defaultLpBackground}>
      <Content>
        <Title>Oops... something went wrong</Title>
        <div>
          <ButtonOutlined
            title="Return Home"
            customColor="#fff"
            onClick={handleRedirect}
          />
        </div>
      </Content>
    </Container>
  );
};

export default SomethingWentWrongPage;
