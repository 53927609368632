import * as React from 'react';

const NokiaLogo = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={150}
    height={34}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M103.689 1.034v31.932h4.971V1.034h-4.971ZM50.579.489c-9.685 0-17.2 6.79-17.2 16.511 0 10.159 7.515 16.512 17.2 16.511 9.684 0 17.213-6.352 17.198-16.511C67.763 7.79 60.263.489 50.578.489ZM62.814 17c0 7.197-5.479 11.966-12.237 11.966S38.342 24.196 38.342 17c0-7.066 5.478-11.966 12.236-11.966 6.758 0 12.237 4.9 12.237 11.966ZM0 0v32.966h5.073V11.18L31.438 34v-6.835L0 0Zm71.516 17 18.45 15.966h7.397L78.886 17 97.363 1.034h-7.397L71.516 17ZM150 32.966h-5.468l-4-7.071h-18.113l-3.999 7.071h-5.469l6.623-11.786h18.357L128.778 4.8l2.698-4.8L150 32.966Z"
      fill={fill || '#005AFF'}
    />
  </svg>
);

export default NokiaLogo;
