import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useSignUpMarketo } from '@app/hooks';
import { useStores } from '@stores';
import FormSkeleton from '@components/MarketoForm/MarketoFormSkeleton';
import { FormWrapper } from './styles';
import '../MarketoForm/marketoFormStyle.css';

const MarketoSignUpForm = () => {
  const {
    authStore: { signUp },
    contentStore: { eventData },
  } = useStores();

  const { formId, munchkinId, baseUrl } = useMemo(() => {
    return {
      formId: eventData?.marketoFormId,
      munchkinId: eventData?.marketoMunchkinId,
      baseUrl: eventData?.marketoBaseUrl,
    };
  }, [eventData]);

  const { formReady } = useSignUpMarketo({
    baseUrl,
    munchkinId,
    formId,
    callback: () => {},
    signUp,
    eventData,
  });

  if (!formId || !munchkinId || !baseUrl) {
    return null;
  }

  return (
    <>
      {!formReady && <FormSkeleton />}
      <FormWrapper formReady={formReady}>
        <form id={`mktoForm_${formId}`} />
      </FormWrapper>
    </>
  );
};

export default observer(MarketoSignUpForm);
