import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useQuery } from '@apollo/client';
import API, { apiRoutes } from '@app/api';
import { getRating, Rating as TRating } from '@queries/getRating';
import Button from '@components/Button';
import ButtonOutlined from '@components/ButtonOutlined';
import Card from './Card';
import StarsRating from './StarRating';
import { ButtonsContainer, Description, Error, Title } from './styles';
import { REFRESH_INTERVALS } from '@app/utils';
import { useStores } from '@stores';
import { useKeywords } from '@app/hooks';

type Props = {
  ratingId: string;
  isLast?: boolean;
  interactWithUser: () => void;
  onClose?: () => void;
  onNotFound?: () => void;
  context?: boolean;
};

const Rating = ({
  ratingId,
  isLast,
  interactWithUser,
  onClose,
  onNotFound,
  context,
}: Props) => {
  const [stars, setStars] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: rating, loading, startPolling } = useQuery<TRating>(getRating, {
    variables: { ratingId },
  });

  const keywords = useKeywords(
    rating?.rating?.data?.attributes?.keywords?.data || []
  );

  const {
    authStore: { user },
  } = useStores();

  useEffect(() => {
    setStars(0);
    setIsSubmitting(false);
    setError(null);
    if (context) {
      startPolling(REFRESH_INTERVALS.INTERACTIONS);
    }
  }, [ratingId]);

  useEffect(() => {
    if (!rating && !loading) {
      // someone accessed this by url and there is no such a rating
      onNotFound?.();
    }
  }, [rating, loading]);

  const handleRatingSubmission = async () => {
    try {
      setIsSubmitting(true);
      const { email } = user || {};
      await API.post(apiRoutes.RATING_VOTE, {
        data: { email, nOfStars: stars, ratingId },
      });

      interactWithUser();
    } catch (err) {
      console.error(err);
      setError("We couldn't submit your rating. Please try again or skip.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Card
        border={context}
        lessPadding={context}
        showPlaceholder={loading || !rating}
        onClose={!isLast ? onClose : undefined}
        enableMinHeight={context}
      >
        <div>
          <Title>{rating?.rating.data.attributes.title}</Title>
          <Description>
            {rating?.rating.data.attributes.description}
          </Description>
        </div>
        <StarsRating nOfStars={5} onRate={setStars} />
        <ButtonsContainer>
          <Button
            disabled={isSubmitting || stars === 0}
            title="Submit"
            fontSize={14}
            onClick={handleRatingSubmission}
          />
          <ButtonOutlined
            title={isLast ? 'Skip and Close' : 'Skip'}
            onClick={interactWithUser}
          />
          {error && <Error>{error}</Error>}
        </ButtonsContainer>
      </Card>
    </>
  );
};

export default observer(Rating);
