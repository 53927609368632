import { useEffect, useMemo } from 'react';
import routes from '@routes';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import GET_ARTICLE from '@queries/getArticle';
import { NewsItemProps } from '@app/utils/types';
import { REFRESH_INTERVALS } from '@app/utils';
import { toast } from 'react-toastify';

interface ParamsProps {
  groupId: string;
  slug: string;
  eventSlug: string;
}

function useArticle(eventData: any) {
  const history = useHistory();
  const { groupId: groupSlug, slug, eventSlug }: ParamsProps = useParams();

  const newsData = eventData?.homePage?.find(
    (el: any) =>
      el.__typename === 'ComponentNewsNewsGroup' &&
      el.news_groups?.data?.find(
        (newsItem: any) => newsItem.attributes.slug === groupSlug
      )
  );

  const groupId = newsData?.news_groups?.data?.find(
    (item: any) => item.attributes.slug === groupSlug
  )?.id;

  const { loading, data, startPolling } = useQuery(GET_ARTICLE, {
    variables: { id: groupId, slug },
  });

  useEffect(() => {
    if (
      !groupId ||
      !slug ||
      (!loading &&
        (data?.newsGroup?.data?.attributes?.news_items?.data || []).length ===
          0)
    ) {
      history.push(routes.EVENT.ARTICLES(eventSlug));
      toast.error('Article not found!', { toastId: 'article-not-found' });
    } else {
      startPolling(REFRESH_INTERVALS.ARTICLE);
    }
  }, [groupId, slug, loading, data]);

  const articleData = useMemo(
    () =>
      data?.newsGroup?.data?.attributes?.news_items?.data?.[0] as NewsItemProps,
    [loading, data]
  );

  const { author, anyAuthorDetails } = useMemo(() => {
    const author = articleData?.attributes?.newsAuthor?.data?.attributes;

    const anyAuthorDetails =
      author?.company ||
      author?.email ||
      author?.linkedinUrl ||
      author?.twitterUrl ||
      author?.role;

    return { author, anyAuthorDetails };
  }, [articleData?.attributes?.newsAuthor]);

  return {
    loading,
    articleData,
    groupId,
    eventSlug,
    groupSlug,
    author,
    anyAuthorDetails,
  };
}

export default useArticle;
