import { makeAutoObservable, reaction } from 'mobx';
import API, { apiRoutes } from '@app/api';
import {
  BookmarkTypes,
  SetBookmarkInterface,
  Bookmark,
  StorageType,
  BookmarkDataProps,
} from '@app/utils/types';
import {
  BOOKMARKS_STORAGE_VERSION,
  USER_BOOKMARKS_STORAGE_KEY,
} from '@constants';
import contentStore from './contentStore';
import { isBefore, parseISO } from 'date-fns';

const getBookmarksStorage = () => {
  const str = localStorage.getItem(USER_BOOKMARKS_STORAGE_KEY);
  const storage = str ? (JSON.parse(str) as StorageType) : undefined;

  return storage;
};

export class BookmarksStore {
  bookmarksStorage: any = getBookmarksStorage();
  bookmarksData: BookmarkDataProps[] | null = null;
  eventSlug: string = '';

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => ({
        location: window.location,
        bookmarks: this.bookmarksStorage?.bookmarks,
        bookmarksLengthFlatExperiences:
          this.bookmarksStorage?.bookmarks?.['flatExperience']?.length,
        bookmarksLengthArticles:
          this.bookmarksStorage?.bookmarks?.['article']?.length,
        bookmarksLengthExperiences:
          this.bookmarksStorage?.bookmarks?.['experience']?.length,
        flatExperiences: contentStore?.eventData?.homePage?.filter(
          (el: any) => el.__typename === 'ComponentFlexpsFlExpGr'
        ),
        articles: contentStore?.eventData?.homePage.filter(
          (el: any) => el.__typename === 'ComponentNewsNewsGroup'
        ),
        experiences: contentStore?.eventData?.homePage.filter(
          (el: any) => el.__typename === 'ComponentExperienceExperienceGroup'
        ),
      }),
      this.setBookmarksData
    );
  }

  sendBookmarksEmail = async ({
    email,
    eventSlug,
  }: {
    email: string;
    eventSlug: string;
  }) => {
    const bookmarks = this.bookmarksStorage?.bookmarks;

    const bookmarksPayload = {
      article: bookmarks?.article?.filter(
        (item: BookmarkDataProps) => item.eventSlug === eventSlug
      ),
      flatExperience: bookmarks?.flatExperience?.filter(
        (item: BookmarkDataProps) => item.eventSlug === eventSlug
      ),
      experience: bookmarks?.experience?.filter(
        (item: BookmarkDataProps) => item.eventSlug === eventSlug
      ),
    };

    await API.post(apiRoutes.BOOKMARKS_EMAIL, {
      data: { email, bookmarks: bookmarksPayload, eventSlug },
    });
  };

  setBookmarksData = ({
    flatExperiences,
    articles,
    bookmarks,
    experiences,
  }: any) => {
    const flatExperienceBookmarks = this.handleFlatExperienceBookmarks({
      flatExperiences,
      bookmarks,
    });

    const articlesBookmarks = this.handleArticlesBookmarks({
      articles,
      bookmarks,
    });

    const experienceBookmarks = this.handleExperienceBookmarks({
      experiences,
      bookmarks,
    });

    let unsortedData = [
      ...flatExperienceBookmarks,
      ...articlesBookmarks,
      ...experienceBookmarks,
    ];

    this.bookmarksData = unsortedData.sort((a, b) =>
      isBefore(parseISO(`${a.bookmarkedAt}`), parseISO(`${b.bookmarkedAt}`))
        ? -1
        : 1
    );
  };

  setBookmark = ({
    flatExperience,
    article,
    experience,
    type,
  }: SetBookmarkInterface) => {
    let newItem: Bookmark | {} = {};
    const bookmarkedAt = new Date();
    if (type === 'flatExperience') {
      newItem = { ...flatExperience, bookmarkedAt, type };
    } else if (type === 'article') {
      newItem = { ...article, bookmarkedAt, type };
    } else if (type === 'experience') {
      newItem = { ...experience, bookmarkedAt, type };
    }

    if (
      !this.bookmarksStorage ||
      this.bookmarksStorage.version !== BOOKMARKS_STORAGE_VERSION
    ) {
      this.bookmarksStorage = {
        bookmarks: {
          [type]: [newItem],
        },
        version: BOOKMARKS_STORAGE_VERSION,
      };
    }

    if (this.bookmarksStorage && !this.bookmarkExists({ newItem, type })) {
      const typeExists = this.bookmarksStorage.bookmarks[type];
      if (typeExists) {
        this.bookmarksStorage.bookmarks[type].push(newItem);
      } else {
        this.bookmarksStorage.bookmarks = {
          ...this.bookmarksStorage.bookmarks,
          [type]: [newItem],
        };
      }
    }

    localStorage.setItem(
      USER_BOOKMARKS_STORAGE_KEY,
      JSON.stringify(this.bookmarksStorage)
    );
  };

  bookmarkExists = ({
    newItem,
    type,
  }: {
    newItem: any;
    type: BookmarkTypes;
  }) => {
    const { slug, eventSlug } = newItem;

    return !!this.bookmarksStorage?.bookmarks?.[type]?.find(
      (item: Bookmark) => item.slug === slug && item.eventSlug === eventSlug
    );
  };

  removeBookmark = (
    slug: string,
    eventSlug: string,
    type: BookmarkTypes,
    groupId?: string
  ) => {
    if (this.bookmarksStorage) {
      this.bookmarksStorage.bookmarks[type] = this.bookmarksStorage?.bookmarks[
        type
      ].filter(
        (item: Bookmark) =>
          !(
            item.slug === slug &&
            item.groupId === groupId &&
            item.eventSlug === eventSlug
          )
      );
    }

    localStorage.setItem(
      USER_BOOKMARKS_STORAGE_KEY,
      JSON.stringify(this.bookmarksStorage)
    );
  };

  setEventSlug = (slug: string) => {
    this.eventSlug = slug;
  };

  handleFlatExperienceBookmarks = ({ flatExperiences, bookmarks }: any) => {
    let allFlatExperiences: Array<any> = [];
    flatExperiences?.forEach((element: any) => {
      allFlatExperiences.push(
        ...(element.flat_experience_groups?.data?.[0]?.attributes
          ?.flat_experiences?.data || [])
      );
    });

    const flatExperienceBookmarks =
      this.filterDuplicatesBySlug(allFlatExperiences);

    const returnedContent = this.filterSameEventBookmarks(
      bookmarks?.flatExperience
    )?.map((item: Bookmark) => ({
      ...item,
      data: flatExperienceBookmarks.find(
        (elem) => elem.attributes.slug === item.slug
      ),
    }));

    return returnedContent || [];
  };

  handleArticlesBookmarks = ({ articles, bookmarks }: any) => {
    let allArticles: Array<any> = [];
    articles?.forEach((element: any) => {
      allArticles.push(
        ...(element.news_groups?.data?.[0]?.attributes?.news_items?.data || [])
      );
    });

    const articlesBookmarks = this.filterDuplicatesBySlug(allArticles);

    const returnedContent = this.filterSameEventBookmarks(
      bookmarks?.article
    )?.map((item: Bookmark) => ({
      ...item,
      data: articlesBookmarks.find(
        (elem) => elem.attributes.slug === item.slug
      ),
    }));

    return returnedContent || [];
  };

  handleExperienceBookmarks = ({ experiences, bookmarks }: any) => {
    let allExperiences: Array<any> = [];

    experiences?.forEach((element: any) => {
      allExperiences.push(
        ...(element.experience?.data?.attributes?.experience_items?.data || [])
      );
    });

    const experiencesBookmarks = this.filterDuplicatesBySlug(allExperiences);

    const returnedContent = this.filterSameEventBookmarks(
      bookmarks?.experience
    )?.map((item: Bookmark) => ({
      ...item,
      data: experiencesBookmarks.find(
        (elem) => elem.attributes.slug === item.slug
      ),
    }));

    return returnedContent || [];
  };

  filterSameEventBookmarks = (data: Array<any>) => {
    return data?.filter((item: any) => item.eventSlug === this.eventSlug) || [];
  };

  filterDuplicatesBySlug = (data: Array<any>) => {
    return data?.filter((currentObject, index, self) => {
      return (
        index ===
        self.findIndex(
          (obj) => obj.attributes.slug === currentObject.attributes.slug
        )
      );
    });
  };

  clearStore = () => {
    this.bookmarksStorage = [];
    this.bookmarksData = null;
    this.eventSlug = '';
  };
}

export default new BookmarksStore();
