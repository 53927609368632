import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
`;

export const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 400;
  font-family: 'NokiaPureText-Regular';
  color: #fff;
`;
