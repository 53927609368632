import React from 'react';
import { useTheme } from '@emotion/react';
import { BasePageContainer } from '@components/BasePageContainer';
import Header from '@components/Header';
import routes from '@routes';
import CookiePolicy from './components/CookiePolicy';
import { Title } from './styles';

const CookiesPage = () => {
  const theme = useTheme();
  const customCfg = {
    route: routes.COOKIES,
    visible: true,
    transparent: false,
    hideBurger: true,
    logoColor: theme.colors.primaryBlue,
  };

  return (
    <>
      <Header customCfg={customCfg} />
      <BasePageContainer min>
        <Title>Cookies & Similar Technologies Notice</Title>
        <CookiePolicy />
      </BasePageContainer>
    </>
  );
};

export default CookiesPage;
