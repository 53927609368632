import React from 'react';
import { useTheme } from '@emotion/react';
import { BasePageContainer } from '@components/BasePageContainer';
import Header from '@components/Header';
import routes from '@routes';
import { Title } from './styles';
import CookieList from './components/CookieList';

const CookiesListPage = () => {
  const theme = useTheme();
  const customCfg = {
    route: routes.COOKIES,
    visible: true,
    transparent: false,
    hideBurger: true,
    logoColor: theme.colors.primaryBlue,
  };

  return (
    <>
      <Header customCfg={customCfg} />
      <BasePageContainer min>
        <Title>Cookie List</Title>
        <CookieList />
      </BasePageContainer>
    </>
  );
};

export default CookiesListPage;
