import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  padding: 18px;
  background-color: #fff;
  border-radius: 4px;
  gap: 15px;
  justify-content: flex-end;
`;

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

export const ImageBox = styled.div<ImageBoxProps>`
  border-radius: 4px;
  min-width: 98px;
  height: 91px;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Title = styled.div`
  font-family: NokiaPureHeadline-Bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.blueBlack};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
