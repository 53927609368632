import React from 'react';

const StarIcon = ({
  fill,
  stroke,
  width,
  height,
  gradientOffset,
  ...props
}: any) => {
  return (
    <svg
      width={width || '51'}
      height={height || '48'}
      viewBox="0 0 51 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        {['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%'].map(
          (item: string, index: number) => (
            <linearGradient id={`star_grad_${item}`} key={`${index}_${item}`}>
              <stop offset="0%" stopColor="#F7B737" />
              <stop offset={item} stopColor="#F7B737" />
              <stop offset={item} stopColor="#EBEBEB" />
            </linearGradient>
          )
        )}
      </defs>
      <path
        d="M25.1743 1.60494L30.5893 18.1213L30.7022 18.4656H31.0644H48.5751L34.4125 28.6631L34.1156 28.8769L34.2296 29.2247L39.6416 45.732L25.4665 35.5254L25.1743 35.315L24.8822 35.5254L10.7071 45.732L16.1191 29.2247L16.2331 28.8769L15.9361 28.6631L1.77357 18.4656H19.2842H19.6465L19.7593 18.1213L25.1743 1.60494Z"
        fill={
          gradientOffset
            ? `url(#star_grad_${gradientOffset})`
            : fill || '#EBEBEB'
        }
        stroke={stroke || '#CCCCCC'}
      />
    </svg>
  );
};

export default StarIcon;
