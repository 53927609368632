import React, { useState, useEffect } from 'react';
import { useLockedBody } from 'usehooks-ts';
import { useTheme } from '@emotion/react';
import CloseIcon from '@app/assets/closeIcon';
import NokiaLogo from '@app/assets/nokiaLogoHeader';
import MarketoForm from '@components/MarketoForm';
import EmailSendSuccess from '@components/EmailSendSuccess';
import {
  Container,
  ContentBox,
  CloseBox,
  FormContainer,
  Title,
  Description,
  LogoBox,
} from './styles';

interface SendMailOverlayProps {
  setSendMailOverlayOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SendMailOverlay = ({ setSendMailOverlayOpen }: SendMailOverlayProps) => {
  // eslint-disable-next-line
  const [locked, setLocked] = useLockedBody(false, 'root');

  const theme = useTheme();
  const [emailSuccess, setEmailSuccess] = useState(false);

  useEffect(() => {
    setLocked(true);

    return () => {
      setLocked(false);
    };
  }, []);

  return (
    <Container>
      <ContentBox emailSuccess={emailSuccess}>
        <CloseBox onClick={() => setSendMailOverlayOpen(false)}>
          <CloseIcon fill="#000" />
        </CloseBox>
        {emailSuccess ? (
          <EmailSendSuccess />
        ) : (
          <FormContainer>
            <LogoBox>
              <NokiaLogo fill={theme.colors.primaryBlue} />
            </LogoBox>
            <Title>Enter your email</Title>
            <Description>
              Please enter your email address to send bookmarks to
            </Description>
            <MarketoForm
              setEmailSuccess={setEmailSuccess}
              setSendMailOverlayOpen={setSendMailOverlayOpen}
            />
          </FormContainer>
        )}
      </ContentBox>
    </Container>
  );
};

export default SendMailOverlay;
