import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { useMunchkinPageViewTracking } from '@app/hooks';

const Container = styled.div`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  animation: fadein 0.5s;
  min-height: calc(var(--vh) * 100);
`;

interface PageProps {
  children?: JSX.Element | JSX.Element[];
}

const Page = ({ children }: PageProps) => {
  useMunchkinPageViewTracking();

  return <Container>{children}</Container>;
};

export default observer(Page);
