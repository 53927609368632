import { useMemo } from 'react';
import type { Event } from 'react-big-calendar';
import { ScheduleItem, SelectorProps } from '@app/utils/types';
import { format, isBefore, isSameDay } from 'date-fns';
import { useKeywords } from '@app/hooks';

export type ScheduleData = {
  scheduleData: Event[];
  selectorData: SelectorProps;
  defaultDate: Date;
};

function useSchedule(eventData: any): {
  schedule: ScheduleData | null;
  keywords: string;
} {
  const keywords = useKeywords(
    eventData?.schedule?.data?.attributes?.keywords?.data || []
  );

  const scheduleContent = useMemo(() => {
    const data = (eventData?.schedule?.data?.attributes?.scheduleItem ||
      []) as ScheduleItem[];

    if (!data.length) {
      return null;
    }

    const scheduleGroupSlug = eventData?.schedule?.data?.attributes?.slug;

    const modifiedData = data.map((scheduleItem: ScheduleItem) => ({
      id: scheduleItem.id,
      slug: scheduleGroupSlug,
      itemSlug: scheduleItem.slug,
      presenters: scheduleItem.presenters,
      description_ck: scheduleItem.description_ck,
      image: scheduleItem.image,
      title: scheduleItem.title,
      start: new Date(scheduleItem.startTime),
      end: new Date(scheduleItem.endTime),
    }));

    const datesArr = modifiedData.map((item) => item.start.valueOf());

    const sortedDays = datesArr.sort((a, b) => {
      const isBeforeB = isBefore(new Date(a), new Date(b));
      if (isBeforeB) return -1;
      if (!isBeforeB) return 1;
      return 0;
    });

    const formattedDays = sortedDays.map((item) =>
      format(new Date(item), 'd MMMM yyyy')
    );

    const uniqueDays = Array.from(new Set(formattedDays));

    const selectValues = uniqueDays.map((day) => ({
      label: day,
      value: new Date(day),
    }));

    const anyFoundDate = selectValues.find((item) =>
      isSameDay(item.value, new Date())
    );

    const defaultDate = anyFoundDate
      ? anyFoundDate.value
      : selectValues[0]?.value;

    return {
      scheduleData: modifiedData || [],
      selectorData: selectValues,
      defaultDate,
    };
  }, [eventData?.schedule]);

  return {
    schedule: scheduleContent,
    keywords,
  };
}

export default useSchedule;
