import React, { useRef } from 'react';
import Carousel from 'react-elastic-carousel';
import ImageItem from './components/ImageItem';
import {
  Container,
  PaginationWrapper,
  PaginationText,
  PaginationArrow,
} from './styles';

export interface ImagesCarouselProps {
  images: Array<any>;
  outerSpacing?: number;
}

const breakPoints = [{ width: 1, itemsToShow: 1.1 }];

const ImagesCarousel = ({ images, outerSpacing }: ImagesCarouselProps) => {
  const imageCarouselRef = useRef<any>(null);

  if ((images || []).length <= 0) return null;

  return (
    <Container>
      {/*
      // @ts-ignore */}
      <Carousel
        breakPoints={breakPoints}
        itemsToShow={1.1}
        pagination={true}
        showArrows={false}
        itemPadding={[0, 6, 0, 6]}
        outerSpacing={outerSpacing ?? 0}
        ref={imageCarouselRef}
        renderPagination={({ activePage }) => {
          return (
            <PaginationWrapper>
              <PaginationArrow
                leftArrow
                onClick={() =>
                  activePage + 1 === 1
                    ? imageCarouselRef.current.goTo(images.length - 1)
                    : imageCarouselRef.current.slidePrev()
                }
              />
              <PaginationText>
                {activePage + 1}/{images.length}
              </PaginationText>
              <PaginationArrow
                onClick={() =>
                  activePage + 1 === images.length
                    ? imageCarouselRef.current.goTo(0)
                    : imageCarouselRef.current.slideNext()
                }
              />
            </PaginationWrapper>
          );
        }}
      >
        {images.map((image, idx) => (
          <React.Fragment key={idx}>
            <ImageItem imageData={image} />
          </React.Fragment>
        ))}
      </Carousel>
    </Container>
  );
};

export default ImagesCarousel;
