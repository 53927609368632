import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { matchPath } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { useStores } from '@stores';
import { useLazyQuery } from '@apollo/client';
import GET_EVENT_BY_SLUG from '@queries/getEventBySlug';
import { usePrevious } from '@app/hooks';
import routes from '@routes';
import { REFRESH_INTERVALS } from '@app/utils';
import { useEventSlug } from '@app/hooks/useEventSlug';

const ContentHandler = () => {
  const history = useHistory();
  const location = useLocation();

  const eventSlug = useEventSlug();

  const prevPathname = usePrevious(location?.pathname);

  const {
    contentStore: { eventData, setEventData },
  } = useStores();

  const [loadEvent, { called, loading, data }] = useLazyQuery(
    GET_EVENT_BY_SLUG,
    {
      variables: { slug: eventSlug },
      pollInterval: REFRESH_INTERVALS.EVENT,
      onError: () => {
        if (eventData) return;
        history.push(routes.EVENT.SOMETHING_WRONG(eventSlug));
      },
    }
  );

  useEffect(() => {
    loadEvent();
  }, []);

  useEffect(() => {
    if (data) {
      setEventData(data?.events?.data?.[0]?.attributes);
    }

    if (called && (data?.events?.data || []).length === 0) {
      history.push('/404');
    }
  }, [data]);

  useEffect(() => {
    if (
      matchPath(prevPathname || '', {
        path: routes.EVENT.SOMETHING_WRONG(),
        exact: true,
        strict: false,
      }) &&
      !matchPath(location.pathname, {
        path: routes.EVENT.SOMETHING_WRONG(),
        exact: true,
        strict: false,
      }) &&
      !loading &&
      !eventData
    ) {
      loadEvent();
    }
  }, [location?.pathname, eventData]);

  return null;
};

export default observer(ContentHandler);
