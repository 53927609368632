import React from 'react';
import UserInteractionLayout from '@components/UserInteractionLayout';
import Poll from '@components/Interactions/Poll';
import { useHistory, useParams } from 'react-router-dom';
import {
  MappedShortInteraction,
  Mode,
  useUserInteraction,
} from '@app/hooks/useUserInteraction';
import { useStores } from '@stores';
import { useExperience, useSearchParams } from '@app/hooks';
import routes from '@routes';
import { observer } from 'mobx-react-lite';
import { EventSlugParams } from '@app/hooks/useEventSlug';
import { useHidableNavOffset } from '@app/hooks/useHidableNav';

const PollPage = () => {
  const { pollId, eventSlug } = useParams<
    {
      pollId: string;
    } & EventSlugParams
  >();
  const history = useHistory();

  const params = useSearchParams();
  const {
    contentStore: { eventData },
  } = useStores();

  const { experienceData: experience } = useExperience(eventData);

  const mode: Mode = {
    mode: 'routing',
    experienceSlug: experience.slug,
    routingType: 'replace',
  };

  const currentInteraction: MappedShortInteraction = {
    __typename: 'ComponentIntersPollInteraction',
    id: pollId,
  };

  useHidableNavOffset(35);

  const { interactWithUser } = useUserInteraction();

  return (
    <UserInteractionLayout>
      <Poll
        pollId={pollId}
        isLast={params.has('lastInteraction')}
        interactWithUser={() =>
          interactWithUser(mode, experience.interactions, currentInteraction, {
            noInteractionFallback: () =>
              history.push(routes.EVENT.HOME(eventSlug)),
          })
        }
        onNotFound={() => history.replace(routes.EVENT.HOME(eventSlug))}
      />
    </UserInteractionLayout>
  );
};

export default observer(PollPage);
