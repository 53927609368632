import { gql } from '@apollo/client';

export default gql`
  query GetArticle($id: ID! = "", $slug: String! = "") {
    newsGroup(id: $id) {
      data {
        attributes {
          news_items(filters: { slug: { eq: $slug } }) {
            data {
              attributes {
                slug
                title
                author
                date
                newsContent_ck
                disableBookmarking
                newsAuthor {
                  data {
                    attributes {
                      name
                      email
                      role
                      company
                      linkedinUrl
                      twitterUrl
                    }
                  }
                }
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                carouselPosition
                image_carousel {
                  data {
                    attributes {
                      carouselItems(pagination: { limit: 30 }) {
                        image {
                          data {
                            attributes {
                              url
                            }
                          }
                        }
                      }
                    }
                  }
                }
                keywords {
                  data {
                    attributes {
                      keyword
                    }
                  }
                }
                bookmarkLinks {
                  label
                  externalUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;
