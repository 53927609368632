import React from 'react';
import { ContactItemProps } from '@app/utils/types';
import ChevronRight from '@app/assets/chevronRightIcon';
import DefaultAvatarIcon from '@app/assets/defaultContactAvatarIcon';
import {
  Container,
  TextsCol,
  Name,
  Email,
  IconBox,
  Avatar,
  DefaultAvatar,
} from './styles';
import { useContact } from '@app/hooks';
import { useTrackMarketoLinkClick } from '@app/hooks';

const ContactItem = (props: ContactItemProps) => {
  const { handleClick, avatar, name } = useContact(props);
  const trackLink = useTrackMarketoLinkClick();

  const { email, isFirst } = props;

  const clickFn = () => {
    trackLink();
    handleClick();
  };

  return (
    <Container onClick={clickFn} isFirst={isFirst}>
      {avatar ? (
        <Avatar image={avatar} />
      ) : (
        <DefaultAvatar>
          <DefaultAvatarIcon />
        </DefaultAvatar>
      )}
      <TextsCol>
        <Name>{name}</Name>
        <Email>{email}</Email>
      </TextsCol>
      <IconBox>
        <ChevronRight />
      </IconBox>
    </Container>
  );
};

export default ContactItem;
