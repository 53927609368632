import styled from '@emotion/styled';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 35px;
  animation: pulse 1.5s ease-in-out infinite;

  &:last-child {
    margin-bottom: 0;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const InputSkeleton = styled.div`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.grey300};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

export const ConsentSkeleton = styled.div`
  width: 100%;
  height: 15px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.grey300};
`;

export const SubmitSkeleton = styled.div`
  width: 190px;
  height: 44px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.grey300};
  margin: 20px auto 0;
`;
