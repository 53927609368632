import styled from '@emotion/styled';

export const Title = styled.div`
  font-size: 32px;
  font-family: 'NokiaPureHeadline-Regular';
  color: ${({ theme }) => theme.colors.grey900};
  text-align: center;
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.grey700};
  text-align: center;
`;
