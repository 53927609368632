import * as React from 'react';

const LinkedinIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#333"
      d="M3.32 1.667c0 .92-.74 1.666-1.653 1.666A1.66 1.66 0 0 1 .013 1.667C.013.747.753 0 1.667 0A1.66 1.66 0 0 1 3.32 1.667Zm.013 3H0v10.666h3.333V4.667Zm5.322 0H5.343v10.666h3.312V9.734c0-3.113 4.02-3.368 4.02 0v5.6H16V8.578c0-5.253-5.948-5.062-7.345-2.476V4.667Z"
    />
  </svg>
);

export default LinkedinIcon;
