import { useEffect } from 'react';
import { matchPath } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import routes from '@routes';
import { useStores } from '@stores';
import { useEventSlug } from '@app/hooks/useEventSlug';
import {
  REDIRECT_URL_STORAGE_KEY,
  NON_REDIRECT_ROUTES,
  SIGN_UP_REDIRECT_AUTH_TYPES,
} from '@app/constants';

const AuthCheck = () => {
  const location = useLocation();
  const history = useHistory();
  const eventSlug = useEventSlug();

  const {
    authStore: { verifyUser, clearStore, isAuthenticated, isUserChecked },
    contentStore: { eventData },
  } = useStores();

  useEffect(() => {
    verifyUser(eventSlug, eventData?.authType);

    return () => {
      clearStore();
    };
  }, []);

  useEffect(() => {
    if (SIGN_UP_REDIRECT_AUTH_TYPES.includes(eventData?.authType)) {
      if (
        !isAuthenticated &&
        isUserChecked &&
        !matchPath(location.pathname, {
          path: NON_REDIRECT_ROUTES,
          exact: true,
          strict: false,
        })
      ) {
        localStorage.setItem(REDIRECT_URL_STORAGE_KEY, location.pathname);
        history.push(routes.EVENT.SIGN_UP(eventSlug));
      }
    }
  }, [isAuthenticated, isUserChecked, location.pathname, eventData]);

  return null;
};

export default observer(AuthCheck);
