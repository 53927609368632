import React from 'react';
import NokiaLogo from '@app/assets/nokiaLogo';
import { Wrapper } from './styles';

const Loader = () => {
  return (
    <Wrapper>
      <NokiaLogo />
    </Wrapper>
  );
};

export default Loader;
