import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 17, 53, 0.3);
  z-index: 9999;
`;

interface ContentBoxProps extends React.HTMLAttributes<HTMLDivElement> {}

export const ContentBox = styled.div<ContentBoxProps>`
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  max-height: calc(100% - 75px);
  height: 250px;
  margin-top: auto;
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  gap: 0;
`;

export const CloseBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 28px;
  top: 28px;
`;

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-align: center;
  font-family: 'NokiaPureHeadline-Bold';
  font-size: 24px;
  font-style: normal;
`;

export const CompanyInfo = styled.div`
  color: ${({ theme }) => theme.colors.grey900};
  font-family: 'NokiaPureHeadline-Regular';
  font-size: 16px;
  font-style: normal;
`;

export const ReachText = styled(CompanyInfo)`
  font-family: 'NokiaPureHeadline-Bold';
`;

export const DetailsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const SocialsRow = styled.div`
  display: flex;
  gap: 11px;
`;

export const SocialItem = styled.a`
  padding: 11px;
  border-radius: 2px;
  width: 44px;
  height: 44px;
  padding: 11px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.grey100};
  text-decoration: none;
`;
