import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '@routes';
import { useEventSlug } from './useEventSlug';

function useAuthHomeRedirect(isAuthenticated: boolean) {
  const history = useHistory();
  const eventSlug = useEventSlug();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(routes.EVENT.HOME(eventSlug));
    }
  }, []);

  return;
}

export default useAuthHomeRedirect;
