import { useParams } from 'react-router-dom';

export type EventSlugParams = { eventSlug: string };

function shouldThrow(
  eventSlug: string | undefined,
  unsafe?: boolean
): eventSlug is undefined {
  return !eventSlug && !unsafe;
}

export function useEventSlug<Unsafe extends boolean = false>(
  unsafe?: Unsafe
): Unsafe extends true ? string | undefined : string {
  const { eventSlug } = useParams<Partial<EventSlugParams>>();

  if (shouldThrow(eventSlug, unsafe)) {
    throw new Error('Event slug is not defined');
  }

  return eventSlug;
}
