import React from 'react';
import CloseIcon from '@app/assets/closeIcon';
import LinkedinIcon from '@app/assets/linkedinIcon';
import TwitterIcon from '@app/assets/twitterIcon';
import EmailIcon from '@app/assets/emailIcon';
import { NewsAuthor } from '@app/utils/types';
import {
  Container,
  ContentBox,
  CloseBox,
  Name,
  DetailsWrapper,
  CompanyInfo,
  ReachText,
  SocialsRow,
  SocialItem,
} from './styles';

interface AuthorOverlayProps {
  author: NewsAuthor;
  setAuthorDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthorOverlay = ({
  author,
  setAuthorDetailsOpen,
}: AuthorOverlayProps) => {
  const { name, company, role, linkedinUrl, twitterUrl, email } = author;

  const anyLinks = linkedinUrl || twitterUrl || email;

  return (
    <Container>
      <ContentBox>
        <CloseBox onClick={() => setAuthorDetailsOpen(false)}>
          <CloseIcon fill="#333333" />
        </CloseBox>
        <Name>{name}</Name>
        <DetailsWrapper>
          {company && <CompanyInfo>{company}</CompanyInfo>}
          {role && <CompanyInfo>{role}</CompanyInfo>}
          {anyLinks && (
            <>
              <ReachText>Get in Touch</ReachText>
              <SocialsRow>
                {email && (
                  <SocialItem href={`mailto:${email}`}>
                    <EmailIcon />
                  </SocialItem>
                )}
                {linkedinUrl && (
                  <SocialItem href={linkedinUrl} target="_blank">
                    <LinkedinIcon />
                  </SocialItem>
                )}
                {twitterUrl && (
                  <SocialItem href={twitterUrl} target="_blank">
                    <TwitterIcon height="16px" width="16px" />
                  </SocialItem>
                )}
              </SocialsRow>
            </>
          )}
        </DetailsWrapper>
      </ContentBox>
    </Container>
  );
};

export default AuthorOverlay;
