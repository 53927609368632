import React, { useEffect } from 'react';
import { useLockedBody } from 'usehooks-ts';
import { observer } from 'mobx-react-lite';
import ArticleComponent from './components/Article';
import ExperienceComponent from './components/Experience';
import { Container, ContentBox } from './styles';

const BookmarkOverlay = ({ selectedBookmark, setSelectedBookmark }: any) => {
  // eslint-disable-next-line
  const [locked, setLocked] = useLockedBody(false, 'root');

  useEffect(() => {
    setLocked(true);

    return () => {
      setLocked(false);
    };
  }, []);

  const { type } = selectedBookmark;

  const ContentComponent =
    type !== 'experience' ? ArticleComponent : ExperienceComponent;

  return (
    <Container>
      <ContentBox id="bookmark-overlay-id" isExperience={type === 'experience'}>
        <ContentComponent
          setSelectedBookmark={setSelectedBookmark}
          selectedBookmark={selectedBookmark}
        />
      </ContentBox>
    </Container>
  );
};

export default observer(BookmarkOverlay);
