import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 31px 37px;
  margin: auto 20px 20px 20px;
  gap: 12px;
  border-radius: 4px;
  border: none;
  background-color: #fff;
`;

export const Title = styled.div`
  font-family: 'NokiaPureText-Bold';
  font-size: 20px;
  color: ${({ theme }) => theme.colors.blueBlack};
`;

export const Description = styled.div`
  font-size: 14px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey700};
`;
