import styled from '@emotion/styled';

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 35px;
  animation: pulse 1.5s ease-in-out infinite;

  &:last-child {
    margin-bottom: 0;
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LineSkeleton = styled.div`
  width: 100%;
  height: 16px;
  background: ${({ theme }) => theme.colors.grey300};
`;
