import React, { useState, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useArticle, useKeywords } from '@app/hooks';
import CkContent from '@components/CkContent';
import ImageCarousel from '@components/ImageCarousel';
import { resolveStrapiImgUrl, convertOembedToIframe } from '@app/utils';
import { useStores } from '@stores';
import {
  ImageContainer,
  Content,
  Title,
  Author,
  ClickableAuthor,
  AuthorLine,
  Date as DateComponent,
  NewsContent,
} from './styles';
import AuthorOverlay from '@components/AuthorOverlay';
import { useHidableNavElement } from '@app/hooks/useHidableNav';
import BookmarkLinks from '@components/BookmarkLinks';
import { formatDateTo0Timezone } from '@app/utils';

import Wrapper from '@components/Wrapper';

const ArticlePage = () => {
  const [bookmarkSaved, setBookmarkSaved] = useState(false);
  const [authorDetailsOpen, setAuthorDetailsOpen] = useState(false);
  const {
    contentStore: { eventData },
    bookmarksStore: { setBookmark },
  } = useStores();

  const {
    loading,
    articleData,
    groupSlug,
    eventSlug,
    groupId,
    author,
    anyAuthorDetails,
  } = useArticle(eventData);
  const [imgRef, setImgRef] = useState<HTMLDivElement | null>(null);
  useHidableNavElement(imgRef, { rootMargin: '-75px 0px 0px 0px' });

  useEffect(() => {
    if (articleData && groupSlug && eventSlug && !bookmarkSaved) {
      if (!articleData.attributes.disableBookmarking) {
        setBookmark({
          article: {
            slug: articleData?.attributes.slug,
            groupSlug,
            eventSlug,
            groupId,
          },
          type: 'article',
        });
      }

      setBookmarkSaved(true);
    }
  }, [articleData, groupSlug, eventSlug, bookmarkSaved]);

  const bgImage = resolveStrapiImgUrl(articleData?.attributes?.image);

  const keywords = useKeywords(articleData?.attributes?.keywords?.data || []);

  const { carouselPosition, carouselItems } = useMemo(
    () => ({
      carouselPosition: articleData?.attributes?.carouselPosition,
      carouselItems:
        articleData?.attributes?.image_carousel?.data?.attributes
          ?.carouselItems,
    }),
    [articleData?.attributes]
  );

  if (loading || !articleData) return null;

  return (
    <Wrapper>
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <ImageContainer ref={setImgRef} image={bgImage} />
      <Content>
        <Title>{articleData?.attributes?.title}</Title>
        <div>
          <AuthorLine>
            by{' '}
            {anyAuthorDetails ? (
              <ClickableAuthor onClick={() => setAuthorDetailsOpen(true)}>
                {author?.name}
              </ClickableAuthor>
            ) : (
              <Author>{author?.name || articleData?.attributes?.author}</Author>
            )}
          </AuthorLine>
          {articleData.attributes?.date && (
            <DateComponent>
              {formatDateTo0Timezone(
                articleData.attributes?.date,
                'd MMM yyyy'
              )}
            </DateComponent>
          )}
        </div>
        {carouselPosition === 'top' && <ImageCarousel images={carouselItems} />}
        <NewsContent>
          <CkContent
            text={convertOembedToIframe(
              articleData?.attributes?.newsContent_ck
            )}
          />
        </NewsContent>
        {carouselPosition === 'bottom' && (
          <ImageCarousel images={carouselItems} />
        )}
        <BookmarkLinks bookmarkLinks={articleData?.attributes?.bookmarkLinks} />
      </Content>
      {authorDetailsOpen && author && (
        <AuthorOverlay
          author={author}
          setAuthorDetailsOpen={setAuthorDetailsOpen}
        />
      )}
    </Wrapper>
  );
};

export default observer(ArticlePage);
