import React, { useMemo } from 'react';
import { useStores } from '@stores';
import { observer } from 'mobx-react-lite';
import BookmarkIcon from '@app/assets/bookmarkIcon';
import { IconWrapper, NumberBox } from './styles';

const HeaderBookmarks = () => {
  const {
    bookmarksStore: { bookmarksData },
  } = useStores();

  const bookmarksCount = useMemo(() => bookmarksData?.length, [bookmarksData]);

  return (
    <IconWrapper>
      <BookmarkIcon stroke="#000" />
      <NumberBox>{bookmarksCount}</NumberBox>
    </IconWrapper>
  );
};

export default observer(HeaderBookmarks);
