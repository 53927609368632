import { PollOption } from '@queries/getPoll';
import React from 'react';
import Button from '../Button';
import ButtonOutlined from '../ButtonOutlined';
import { PollOptionsContainer, Row } from './styles';

type Props = {
  pollOptions: PollOption[];
  onAnswerClick: (id: string) => void;
  activeOption: string | null;
};

const PollOptionsRenderer = ({
  pollOptions,
  activeOption,
  onAnswerClick,
}: Props) => {
  return (
    <PollOptionsContainer>
      {pollOptions.map(({ attributes, id }) => (
        <Row key={id}>
          {activeOption === id ? (
            <Button fontSize={14} title={attributes.option} />
          ) : (
            <ButtonOutlined
              title={attributes.option}
              onClick={() => onAnswerClick(id)}
            />
          )}
        </Row>
      ))}
    </PollOptionsContainer>
  );
};

export default PollOptionsRenderer;
