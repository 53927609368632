import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  max-width: 100vw;
  min-width: 0;
`;

interface ImageBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string | undefined;
}

// should be 16:9 images
export const ImageBox = styled.div<ImageBoxProps>`
  border-radius: 4px;
  width: 100%;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  /*
  // @ts-ignore */
  aspect-ratio: 16/9;
`;
