import { useMemo } from 'react';
import { Keyword } from '@app/utils/types';

function useKeywords(keywordsArr: Keyword[]) {
  const keywordsString = useMemo(
    () =>
      keywordsArr
        .map((keyword: Keyword) => keyword.attributes.keyword)
        .join(', '),
    [keywordsArr]
  );

  return keywordsString;
}

export default useKeywords;
