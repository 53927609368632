import StarIcon from '@app/assets/starIcon';
import { useTheme } from '@emotion/react';
import React, { useState } from 'react';
import { StarsContainer } from './styles';

type Props = {
  nOfStars: number;
  onRate: (nOfStars: number) => void;
};

const StarsRating = ({ onRate, nOfStars }: Props) => {
  const theme = useTheme();
  const [stars, setStars] = useState(0);
  return (
    <StarsContainer>
      {Array(nOfStars)
        .fill(0)
        .map((_, i) => {
          const isFilled = i < stars;
          return (
            <StarIcon
              key={i}
              onClick={() => {
                setStars(i + 1);
                onRate(i + 1);
              }}
              width="32px"
              height="32px"
              fill={isFilled ? theme.colors.orange500 : undefined}
              stroke={isFilled ? theme.colors.orange600 : undefined}
            />
          );
        })}
    </StarsContainer>
  );
};

export default StarsRating;
