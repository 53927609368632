import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useInterval } from 'usehooks-ts';
import { useQuery } from '@apollo/client';
import { Description, Title } from '@components/Interactions/styles';
import { getPoll, Poll as TPoll, PollResult } from '@queries/getPoll';
import API, { apiRoutes } from '@app/api';
import Card from '@components/Interactions/Card';
import PollResultsRenderer from '@components/Interactions/PollResultsRenderer';
import { randomTime } from '@app/utils';

const randomInterval = randomTime(7000, 10000);

type Props = {
  pollId: string;
  context?: boolean;
};

const PollResultItem = ({ pollId, context = true }: Props) => {
  const [results, setResults] = useState<PollResult[]>([]);
  const [hidden, setHidden] = useState(false);

  const getResults = async () => {
    const results = await API.get<PollResult[]>(apiRoutes.POLL_RESULTS(pollId));
    setResults(results.data);
  };

  useEffect(() => {
    getResults();
  }, []);

  useInterval(getResults, randomInterval);

  const { data: poll, loading } = useQuery<TPoll>(getPoll, {
    variables: { pollId },
  });

  useEffect(() => {
    if (!poll && !loading) {
      setHidden(true);
    }
  }, [poll, loading]);

  if (hidden) return null;

  return (
    <>
      <Card
        lessPadding={context}
        border={context}
        showPlaceholder={loading || !poll}
        enableMinHeight={context}
        justifyValue="start"
      >
        <div>
          <Title>{poll?.poll.data.attributes.title}</Title>
          <Description>{poll?.poll.data.attributes.question}</Description>
        </div>

        <PollResultsRenderer pollResults={results} />
      </Card>
    </>
  );
};

export default observer(PollResultItem);
