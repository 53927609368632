import styled from '@emotion/styled';

export const CalendarButton = styled.button`
  outline: none;
  background: transparent;
  padding: 10px 30px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 14px;
  font-family: 'NokiaPureHeadline-Regular';
  color: #fff;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
