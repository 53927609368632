import type { Event } from 'react-big-calendar';
import Cookies from 'js-cookie';
import { ExperienceItem } from '@app/utils/types';
import { StrapiImageProps } from '@app/utils/types';
import { isAfter, isBefore, format, addMinutes } from 'date-fns';

export const resolveStrapiImgUrl = (img: StrapiImageProps | undefined) => {
  const imgUrl = img?.data?.attributes?.url;

  if (!imgUrl) return undefined;

  return `${process.env.REACT_APP_API_URL}${imgUrl}`;
};

export const getDefaultExperienceSlug = (eventData: any) => {
  const experienceData = eventData?.homePage?.find(
    (el: any) => el.__typename === 'ComponentExperienceExperienceGroup'
  );
  const items =
    experienceData?.experience?.data?.attributes?.experience_items?.data;

  return (items || []).find(
    (experience: ExperienceItem) => experience.defaultExperience === true
  )?.slug;
};

interface FileProps {
  data: {
    attributes: {
      url: string;
      ext: string;
    };
  };
}

export const downloadExperienceDocument = async ({
  title,
  file,
}: {
  title: string;
  file: FileProps;
}) => {
  const url = resolveStrapiImgUrl(file);

  try {
    // @ts-ignore
    const response = await fetch(url);

    response.blob().then((blob: any) => {
      // Creating new object of PDF file
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = `${title}${file?.data?.attributes?.ext}`;
      alink.click();
    });
  } catch (error) {
    console.log(error);
  }
};

export const validateEmail = (email: string) => {
  return !!(email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email));
};

export const randomTime = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const REFRESH_INTERVALS = {
  INTERACTIONS: randomTime(1000 * 30, 1000 * 40),
  EVENT: randomTime(1000 * 60, 1000 * 60 * 2),
  ARTICLE: randomTime(1000 * 60, 1000 * 60 * 2),
};

export const isEventLive = (event: Event) => {
  if (!event.start || !event.end) return false;
  const end = new Date(event.end);
  const start = new Date(event.start);
  const now = new Date();

  return isBefore(now, end) && isAfter(now, start);
};

export const getMarketoUserIdFromCookie = () => {
  // example of cookie content: //id:937-WRZ-618&token:_mch-flipside-staging.com-1675786133004-76473
  return Cookies.get('_mkto_trk')
    ?.split('&')?.[1]
    ?.replace('token:', '')
    ?.split('.com-')?.[1];
};

export const getUserTimezoneShort = () => {
  try {
    const date = new Date();
    const string = date.toLocaleTimeString(undefined, {
      timeZoneName: 'short',
    });
    return string.split(' ').pop();
  } catch (e) {
    return '';
  }
};

export const hasMatchingProperty = (strings: string[], obj: any) =>
  Object.keys(obj).some(
    (key) => strings.includes(key) && obj[key] !== undefined
  );

export const convertOembedToIframe = (htmlString: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const oembedTags = doc.querySelectorAll('oembed');
  oembedTags.forEach((oembedTag) => {
    const url = oembedTag.getAttribute('url');
    if (url) {
      let videoId = '';
      if (url.includes('youtube.com')) {
        // Regular YouTube URL
        videoId = url.split('v=')[1];
      } else if (url.includes('youtu.be')) {
        // Shortened YouTube URL
        videoId = url.split('/').pop()!;
      } else if (url.includes('vimeo.com')) {
        // Vimeo URL
        videoId = url.split('/').pop()!;
      }

      if (videoId) {
        const videoDiv = document.createElement('div');
        videoDiv.setAttribute('class', 'video-container');

        const iframe = document.createElement('iframe');
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
          iframe.setAttribute(
            'src',
            `https://www.youtube.com/embed/${videoId}`
          );
        } else if (url.includes('vimeo.com')) {
          iframe.setAttribute(
            'src',
            `https://player.vimeo.com/video/${videoId}`
          );
        }
        iframe.setAttribute('width', '560');
        iframe.setAttribute('height', '315');
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allowfullscreen', 'true');
        iframe.setAttribute('class', 'video');
        if (url.includes('youtube.com') || url.includes('youtu.be')) {
          iframe.setAttribute(
            'allow',
            'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          );
        }

        videoDiv.appendChild(iframe);
        oembedTag.parentNode?.replaceChild(videoDiv, oembedTag);
      }
    }
  });

  return doc.body.innerHTML;
};

export const formatDateTo0Timezone = (
  date: string | Date,
  dateFormat: string
) => {
  if (date) {
    const jsDate = new Date(date);
    const parsedDate = format(
      addMinutes(jsDate, jsDate.getTimezoneOffset()),
      dateFormat
    );

    return parsedDate;
  }
  return '';
};
