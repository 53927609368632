import React from 'react';
import { LineSkeleton, SkeletonWrapper, TimeSkeleton } from './styles';

type Props = {};

const skeletons = Array.from({ length: 30 }, (_, i) => i);

const ScheduleSkeleton = (props: Props) => {
  return (
    <>
      {skeletons.map((_, i) => (
        <SkeletonWrapper key={i}>
          <TimeSkeleton />
          <LineSkeleton />
        </SkeletonWrapper>
      ))}
    </>
  );
};

export default ScheduleSkeleton;
