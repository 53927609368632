import React from 'react';
import { resolveStrapiImgUrl } from '@app/utils';
import routes from '@routes';
import { observer } from 'mobx-react-lite';
import {
  Container,
  ImageWrapper,
  ImageBox,
  CircleImg,
  Title,
  StyledLink,
} from './styles';
import { FilterItemProps } from '@app/utils/types';
import { useEventSlug } from '@app/hooks/useEventSlug';

export interface FilterProps {
  imgHeight?: number;
  circleSize?: number;
  marginBottom?: number;
  filter: FilterItemProps;
}

const FilterItem = ({
  filter,
  imgHeight,
  circleSize,
  marginBottom,
}: FilterProps) => {
  const { backgroundImage, circleImage, title, slug } = filter || {};
  const eventSlug = useEventSlug();

  const filterImage = resolveStrapiImgUrl(backgroundImage);
  const circleImageBg = resolveStrapiImgUrl(circleImage);

  return (
    <StyledLink to={routes.EVENT.FILTER(eventSlug, slug)}>
      <Container>
        <ImageWrapper marginBottom={marginBottom}>
          <ImageBox image={filterImage} imgHeight={imgHeight} />
          <CircleImg
            image={circleImageBg}
            imgHeight={imgHeight}
            circleSize={circleSize}
          />
        </ImageWrapper>

        <Title>{title}</Title>
      </Container>
    </StyledLink>
  );
};

export default observer(FilterItem);
