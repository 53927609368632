import styled from '@emotion/styled';

interface BaseContainerProps {
  bgImage?: string;
  min?: boolean;
}

export const BaseContainer = styled('div', {
  shouldForwardProp: (prop) => !['bgImage', 'min'].includes(prop),
})<BaseContainerProps>`
  display: flex;
  flex-direction: column;
  ${({ min }) => (min ? 'flex: 1' : 'height: calc(var(--vh) * 100)')};
  background-image: ${({ bgImage }) =>
    bgImage ? `url('${bgImage}')` : 'unset'};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;
