import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 17, 53, 0.3);
  z-index: 9999;
`;

export const ContentBox = styled.div`
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  height: calc(100% - 75px);
  width: 100%;
  margin-top: 75px;
  padding: ${({ isExperience }: { isExperience: boolean }) =>
    isExperience ? 'unset' : '0 0px 60px 0px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  gap: 20px;
`;
