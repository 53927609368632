import { useMemo } from 'react';
import { useKeywords } from '@app/hooks';
import { ArticleSimple } from '@app/utils/types';

function useArticlesPage(eventData: any) {
  const articlesDataGroups = eventData?.homePage?.filter(
    (el: any) => el.__typename === 'ComponentNewsNewsGroup'
  );

  const keywords = useKeywords(
    articlesDataGroups?.[0]?.news_groups?.data?.[0]?.attributes?.keywords
      ?.data || []
  );

  const { articleContent } = useMemo(() => {
    const articlesListingData: Array<{
      item: { attributes: ArticleSimple };
      groupId: string;
    }> = [];

    articlesDataGroups.forEach((group: any) => {
      (
        group?.news_groups?.data?.[0]?.attributes?.news_items?.data || []
      ).forEach((item: { attributes: ArticleSimple }) => {
        articlesListingData.push({
          item,
          groupId: group?.news_groups?.data?.[0]?.attributes?.slug,
        });
      });
    });

    const finalData = articlesListingData.filter(
      (currentObject, index, self) => {
        return (
          index ===
          self.findIndex(
            (obj) =>
              obj.item.attributes.slug === currentObject.item.attributes.slug
          )
        );
      }
    );

    return {
      articleContent: finalData,
    };
  }, [articlesDataGroups]);

  return { articleContent, keywords };
}

export default useArticlesPage;
