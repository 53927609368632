type HomeInteraction = {
  id: string;
  user_interactions: {
    data: Array<{
      attributes: {
        interactions: Interaction[];
      };
    }>;
  };
};

type Interaction = {
  rating?: ContentData;
  poll?: ContentData;
};

type ContentData = {
  data: { id: string; attributes: { hideOnResultsPage: boolean } };
};

function usePollResults(eventData: any) {
  let ratings: string[] = [];
  let polls: string[] = [];

  const handleInteraction = (interaction: Interaction) => {
    if (interaction?.poll) {
      if (
        !interaction.poll?.data ||
        interaction.poll?.data?.attributes.hideOnResultsPage
      ) {
        return;
      }
      polls.push(interaction.poll?.data?.id);
    }
    if (interaction?.rating) {
      if (
        !interaction.rating?.data ||
        interaction.rating?.data?.attributes.hideOnResultsPage
      ) {
        return;
      }
      ratings.push(interaction.rating.data.id);
    }
  };

  const homeInteractions =
    eventData?.homePage.filter(
      (el: any) => el.__typename === 'ComponentIntersIntersGrpHome'
    ) || [];

  const experiences = eventData?.homePage.filter(
    (el: any) => el.__typename === 'ComponentExperienceExperienceGroup'
  );

  homeInteractions.forEach((element: HomeInteraction) => {
    element.user_interactions?.data?.forEach((interactionGroup) => {
      interactionGroup?.attributes?.interactions.forEach((interaction) => {
        handleInteraction(interaction);
      });
    });
  });

  experiences.forEach((element: any) => {
    element?.experience?.data?.attributes?.experience_items?.data.forEach(
      (item: any) => {
        item.attributes?.interactions?.userInteraction?.data?.attributes?.interactions?.forEach(
          (interaction: any) => {
            handleInteraction(interaction);
          }
        );
      }
    );
  });

  const finalPolls = [...new Set(polls)];
  const finalRatings = [...new Set(ratings)];

  return { polls: finalPolls, ratings: finalRatings };
}

export default usePollResults;
