import { gql } from '@apollo/client';
import { Keyword } from '@app/utils/types';

export type Rating = {
  rating: {
    data: {
      id: string;
      attributes: {
        title: string;
        description: string;
        keywords?: {
          data: Keyword[];
        };
      };
    };
  };
};

export const getRating = gql`
  query GetRating($ratingId: ID!) {
    rating(id: $ratingId) {
      data {
        id
        attributes {
          title
          description
          keywords {
            data {
              attributes {
                keyword
              }
            }
          }
        }
      }
    }
  }
`;
