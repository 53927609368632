import React from 'react';
import ArticlesCarouselSmall from '../Articles/ArticlesCarouselSmall';
import defaultLpBackground from '@app/assets/landingPageDefaultBg.png';
import { observer } from 'mobx-react-lite';
import {
  Container,
  BottomSection,
  BottomSectionTitle,
  CarouselWrapper,
} from './styles';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const UserInteractionLayout = ({ children }: Props) => {
  return (
    <Container min bgImage={defaultLpBackground}>
      {children}
      <BottomSection>
        <BottomSectionTitle>More Articles</BottomSectionTitle>
        <CarouselWrapper>
          <ArticlesCarouselSmall />
        </CarouselWrapper>
      </BottomSection>
    </Container>
  );
};

export default observer(UserInteractionLayout);
