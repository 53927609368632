import React from 'react';
import { resolveStrapiImgUrl } from '@app/utils';
import { StrapiImageProps } from '@app/utils/types';
import { Container, ImageBox } from './styles';

const ImageItem = ({
  imageData,
}: {
  imageData: { image: StrapiImageProps };
}) => {
  const imgUrl = resolveStrapiImgUrl(imageData.image);
  return (
    <Container>
      <ImageBox image={imgUrl} />
    </Container>
  );
};

export default ImageItem;
