import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 17, 53, 0.3);
  z-index: 9999;
`;

interface ContentBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  emailSuccess: boolean;
}

export const ContentBox = styled.div<ContentBoxProps>`
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  max-height: calc(100% - 75px);
  height: ${({ emailSuccess }) => (emailSuccess ? '147px' : '550px')};
  margin-top: auto;
  width: 100%;
  padding: ${({ emailSuccess }) => (emailSuccess ? '0' : '0 0px 60px 0px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: scroll;
  gap: ${({ emailSuccess }) => (emailSuccess ? '0' : '20px')};
`;

export const CloseBox = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 28px;
  top: 28px;
`;

export const FormContainer = styled.div`
  margin-top: 31px;
  padding: 0 52px 24px 52px;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 32px;
  font-family: NokiaPureHeadline-Regular;
  color: ${({ theme }) => theme.colors.grey900};
  margin-bottom: 16px;
`;

export const Description = styled.div`
  font-family: NokiaPureText-Regular;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.grey700};
`;

export const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;
`;
