import { useNavContext } from '@app/contexts/NavContext';
import { useLayoutEffect } from 'react';
import { useEventListener, useIntersectionObserver } from 'usehooks-ts';
import useScrollDirection from './useScrollDirection';

export function useHidableNavOffset(offset: number) {
  const scrollDirection = useScrollDirection();
  const { setIsVisible } = useNavContext();

  const hideLogic = (currentScroll: number) => {
    if (currentScroll < offset) {
      setIsVisible(true);
      return;
    }

    setIsVisible(false);
  };

  useEventListener('scroll', () => {
    hideLogic(window.scrollY);
  });

  useLayoutEffect(() => {
    hideLogic(window.scrollY);
  }, [scrollDirection]);

  useLayoutEffect(() => () => setIsVisible(true), []);
}

export function useHidableNavElement(
  threshold: HTMLElement | null,
  options?: IntersectionObserverInit
) {
  const { setIsVisible } = useNavContext();

  const entry = useIntersectionObserver({ current: threshold }, options || {});

  const scrolledPastThreshold =
    !entry?.isIntersecting && (entry?.boundingClientRect.y || 0) < 0;

  useLayoutEffect(() => {
    if (!scrolledPastThreshold) {
      setIsVisible(true);
      return;
    }
    setIsVisible(false);
  }, [scrolledPastThreshold]);

  useLayoutEffect(() => () => setIsVisible(true), []);
}
