import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '@stores';
import { useFlatExperiencesPage } from '@app/hooks';
import ListingItem from '@components/FlatExperience/ListingItem';
import { Title, FlatExperienceWrapper } from './styles';
import Wrapper from '@components/Wrapper';
import routes from '@routes';

const FlatExperiencesPage = () => {
  const { push } = useHistory();
  const { groupId: groupSlug } = useParams<{
    groupId?: string;
    eventSlug: string;
  }>();

  const {
    contentStore: { eventData },
  } = useStores();

  const { flatExperiencesListingData, keywords } = useFlatExperiencesPage(
    eventData,
    groupSlug
  );

  if ((flatExperiencesListingData || []).length === 0) {
    if (groupSlug) {
      push(routes.EVENT.FLAT_EXPERIENCES(eventData?.slug));
      return null;
    }
    return null;
  }

  return (
    <Wrapper padding="75px 20px 20px">
      <Helmet>
        <meta name="Keywords" content={keywords} />
      </Helmet>
      <Title>Supporting Content</Title>
      {flatExperiencesListingData.map((elem, idx: number) => (
        <FlatExperienceWrapper
          key={`${elem.item.attributes.slug}-${idx}`}
          isLast={flatExperiencesListingData.length === idx + 1}
        >
          <ListingItem props={elem.item.attributes} groupId={elem.groupId} />
        </FlatExperienceWrapper>
      ))}
    </Wrapper>
  );
};

export default observer(FlatExperiencesPage);
