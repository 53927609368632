import React, { useMemo } from 'react';

const MarkdownContent = ({ text }: { text?: string }) => {
  const finalContent = useMemo(
    () =>
      //@ts-ignore
      text?.replaceAll('/uploads', `${process.env.REACT_APP_API_URL}/uploads`),
    [text]
  );

  if (!finalContent) return null;

  return (
    <div
      className="ck-content"
      dangerouslySetInnerHTML={{
        __html: finalContent,
      }}
    />
  );
};

export default MarkdownContent;
