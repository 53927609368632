import { makeAutoObservable, runInAction } from 'mobx';
import API, {
  setUserStorage,
  getUserFromStorage,
  apiRoutes,
  User,
} from '@app/api';
import ContentStore from '@app/stores/contentStore';
import { SignUpData } from '@app/utils/types';
import { SIGN_UP_REDIRECT_AUTH_TYPES } from '@app/constants';

export class AuthStore {
  isAuthenticated = false;
  isUserChecked = false;
  user: User | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  signUp = async (formValues: SignUpData, onUserSettelment?: () => void) => {
    this.isUserChecked = false;

    const manipulatedFormValues = {
      ...formValues,
      firstName: formValues?.firstName || '',
      lastName: formValues?.firstName || '',
    };

    const { data } = await API.post<User>(
      apiRoutes.SIGN_IN,
      manipulatedFormValues
    );

    setUserStorage({
      ...data,
      eventSlug: formValues.eventSlug,
      marketoInputEmail: formValues.marketoInputEmail,
    });

    runInAction(() => {
      this.user = data;
    });

    await ContentStore.resolveInteractionsAfterLogin();

    onUserSettelment?.();

    runInAction(() => {
      this.setIsAuthenticated(true);
      this.isUserChecked = true;
    });
  };

  verifyUser = async (eventSlug: string, authType: string) => {
    if (this.isUserChecked) return;
    const user = getUserFromStorage(eventSlug);

    if (!user) {
      this.isUserChecked = true;
      this.isAuthenticated = false;

      if (!SIGN_UP_REDIRECT_AUTH_TYPES.includes(authType)) {
        await this.signUpAnonymous(eventSlug);
      }

      return;
    }

    await this.signUp(user);
  };

  signUpAnonymous = async (eventSlug: string) => {
    const payload: SignUpData = {
      email: `${Date.now() + Math.random()}@anonymous.com`,
      firstName: '',
      lastName: '',
      eventSlug,
    };

    await this.signUp(payload);
  };

  basicSignIn = async ({
    password,
    onError,
    onSuccess,
    eventSlug,
  }: {
    password: string;
    onError: () => void;
    onSuccess: () => void;
    eventSlug: string;
  }) => {
    try {
      await API.post(apiRoutes.BASIC_AUTH, {
        password,
        eventSlug,
      });

      await this.signUpAnonymous(eventSlug);
      onSuccess();
    } catch (error) {
      console.log(error);
      onError();
    }
  };

  setIsAuthenticated = (value: boolean) => {
    this.isAuthenticated = value;
  };

  clearStore = () => {
    this.isAuthenticated = false;
    this.isUserChecked = false;
  };
}

export default new AuthStore();
