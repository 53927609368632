import React from 'react';
import { observer } from 'mobx-react-lite';
import defaultLpBackground from '@app/assets/landingPageDefaultBg.png';
import { Container, Content, Title } from './styles';
import Header from '@components/Header';
import { useHistory } from 'react-router-dom';
import routes from '@routes';
import { useSearchParams } from '@app/hooks';
import ButtonOutlined from '@components/ButtonOutlined';

const NotFoundPage = () => {
  const params = useSearchParams();

  const history = useHistory();

  const eventSlug = params.get('ev');

  const message = !eventSlug
    ? `We can't find this page`
    : `We can't find this event`;

  const handleRedirect = () => {
    if (!eventSlug) return;

    history.replace(routes.EVENT.HOME(eventSlug));
  };

  return (
    <>
      <Header />
      <Container bgImage={defaultLpBackground}>
        <Content>
          <Title>{message}</Title>
          {eventSlug && (
            <ButtonOutlined
              title="Return home"
              onClick={handleRedirect}
              customColor="#fff"
            />
          )}
        </Content>
      </Container>
    </>
  );
};

export default observer(NotFoundPage);
