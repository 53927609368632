import React from 'react';
import { ContentWrapper, Hug } from './styles';

interface WrapperProps {
  children: React.ReactNode;
  bgColor?: string;
  padding?: string;
  bgImage?: string;
}

const Wrapper = ({ children, bgColor, padding, bgImage }: WrapperProps) => {
  return (
    <ContentWrapper bgColor={bgColor} padding={padding} bgImage={bgImage}>
      <Hug>{children}</Hug>
    </ContentWrapper>
  );
};

export default Wrapper;
